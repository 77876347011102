<template>
    <LoadingDiv v-if="isLoading" />
  
    <template v-else>
      <div class="grey-bg-custom">
        <div class="container">
          <div class="row">
            <div class="col s12">
              <!-- <h1 class="teal-text">
                <i class="medium material-icons">filter_7</i> FINAL
              </h1> -->

              <div class="section">
            <div class="card">
              <div class="card-content center">
                <div class="row">
                  <div class="col s12 center"><h6>Setup completed <i class="tiny material-icons teal-text">check_circle</i></h6></div>
                </div>

                <div class="card-content center teal lighten-5">
                <div class="row">
                  <div class="col s2"></div>
                  <div class="col s4">
                    <p>Master Admin</p>
                    <h5 class="teal-text chip">admin</h5>
                  </div>
                  <div class="col s4">
                    <p>Password</p>
                    <h5 class="teal-text chip">hYudg5$4op</h5>
                  </div>
                  <div class="col s2"></div>
                </div>
              </div>
                
              </div>

             
            </div>
            </div>
              
              <!-- Next -->
              <div class="section center">
                <p>
                  <br />
                  <button
                    class="waves-effect waves-light btn-large teal"
                    @click="handleNext"
                  >
                    <i class="material-icons right">call_made</i>Login
                  </button>
                  <br /><br /><br />
                </p>
              </div>

            </div>
  
           
          </div>
        </div>
     
<!--   
      <footer class="page-footer grey lighten-3">
        <div class="footer-copyright">
          <div class="container black-text">© 2024 easyHMO.com.ng</div>
        </div>
      </footer> -->
  </div>
    </template>
  </template>
  
  <script>
  import { ref } from "vue";
  import LoadingDiv from "@/components/LoadingDiv.vue";
  
  export default {
    name: "FinalSetupComponent",
    components: { LoadingDiv },
    emits: ["next"],
    setup(props, context) {
      const isLoading = ref(false);
      const error = ref(null);
  
      // handle submit
      const handleNext = () => {
        // next
        // emit new parameters
        context.emit("next", {
          step: 7,
          isComplete: true,
        });
      };
  
      return {
        isLoading,
        handleNext,
        error,
      };
    },
  };
  </script>
      
  <style>
  .grey-bg-custom {
    background-color: #f2f2f2;
  }
  .hover-text {
    cursor: pointer;
  }
  .clickable {
    cursor: pointer;
  }
  </style>