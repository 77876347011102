<template>
  <!-- Spouse -->
  <div class="card-panel light-green lighten-4" v-if="enrollee?.spouse">
    <div class="row">
      <div class="col s2">
        <img
          :src="enrollee.spouse.profile_img"
          width="96"
          class="circle responsive-img right"
          v-if="enrollee.spouse.profile_img"
        />
        <img
          :src="`https://ui-avatars.com/api/?name=${enrollee.spouse.name}&background=FFFFFF&size=128`"
          width="96"
          class="circle responsive-img right"
          v-else
        />
      </div>

      <div class="col s9">
        <h6>{{ enrollee.spouse.enrollee_number }}</h6>
        <h5>
          {{ enrollee.spouse.name }}
          <sup>
          <small
            ><span class="black-text">{{
              showAgeSex(enrollee.spouse.dob, enrollee.spouse.sex)
            }}</span></small
          ></sup>
        </h5>
        <label class="active teal-text" v-if="!action">(Spouse)</label>
      </div>
    
      <div class="col s1">
        <span class="right" v-if="authLevel >= 3">
          <a
            href="#"
            class="secondary-content teal-text text-darken-1 dropdown-trigger"
            :data-target="`dropmenu_spouse_${enrollee.spouse.id}`"
            ><i class="material-icons" title="menu">more_vert</i></a
          >

          <!-- DropMenu -->
          <ul
            :id="`dropmenu_spouse_${enrollee.spouse.id}`"
            class="dropdown-content"
          >
            <li @click.prevent="handleMenu({ type: 'close' })" v-if="action">
              <a href="#">Close Update</a>
            </li>

            <li
              @click.prevent="
                handleMenu({
                  type: 'profile',
                })
              "
              v-else
            >
              <a href="#">Update Record</a>
            </li>

            <li class="divider" tabindex="-1"></li>

            <li
              @click.prevent="
                handleMenu({
                  type: 'provider',
                })
              "
            >
              <a href="#">Review Provider</a>
            </li>

            <li class="divider" tabindex="-1"></li>

            <li
              @click.prevent="
                handleMenu({
                  type: 'status',
                })
              "
              v-if="enrollee.spouse.status == 'd'"
            >
              <a href="#">Restore Spouse</a>
            </li>
            <li
              @click.prevent="
                handleMenu({
                  type: 'status',
                })
              "
              v-else
            >
              <a href="#">Delete Spouse</a>
            </li>
          </ul>
        </span>
      </div>
    </div>

    <!-- Edit Profile -->
    <SpouseEditDiv
      :spouse="enrollee.spouse"
      :principal="enrollee"
      @reset="handleReset"
      v-if="action == 'profile'"
    />

    <!-- Review Provider -->
    <SpouseProviderDiv
      :user="userMerger(enrollee.spouse, enrollee)"
      :principal="enrollee"
      @reset="handleReset"
      v-if="action == 'provider'"
    />

    <!-- Review Status -->
    <SpouseStatusDiv
      :enrollee="enrollee.spouse"
      :principal="enrollee"
      @reset="handleReset"
      v-if="action == 'status'"
    />
  </div>
</template>
      
        
<script>
import { ref, onMounted, onUpdated, watch } from "vue";
import { ageSex, changeToMoney } from "@/composables/fxLibrary/coreFx";
import SpouseEditDiv from "@/components/client/onboard/EnrolleeEditSpouseOnboardComponent.vue";
import SpouseProviderDiv from "@/components/client/onboard/EnrolleeProviderSpouseOnboardComponent.vue";
import SpouseStatusDiv from "@/components/client/onboard/EnrolleeStatusEditSpouseOnboardComponent.vue";
import M from "materialize-css";

export default {
  name: "PrincipalSpouseClientComponent",
  components: {
    SpouseEditDiv,
    SpouseProviderDiv,
    SpouseStatusDiv,
  },
  props: ["authLevel", "enrollee", "close"],
  emits: ["reset", "close"],
  setup(props, context) {
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const action = ref(null);

    // dropdown
    onMounted(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // dropdown
    onUpdated(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // to naira
    const changeToNaira = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // to naira
    const changeToNairaTotal = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // merge dependant with
    const userMerger = (user, principal) => {
      // load up
      let newVal = {
        ...user,
        plan: { ...principal.plan },
      };

      return newVal;
    };

    // clear
    const clearAll = () => {
      action.value = null;
    };

    // menu
    const handleMenu = (val) => {
      // close any opened dependant
      context.emit("close", true);
      // proceed
      if (val.type == "close") {
        // clear first
        clearAll();
      }

      if (val.type == "profile") {
        // reset
        action.value = "profile";
      }

      if (val.type == "provider") {
        // reset
        action.value = "provider";
      }

      if (val.type == "status") {
        // reset
        action.value = "status";
      }
    };

    // handle
    const handleReset = (val) => {
      // send back
      if (val) {
        context.emit("reset", val);
      }
      // clear first
      clearAll();
    };

    // check for close Message
    // watch for changes
    watch(props, (newVal) => {
      if (newVal.close) {
        // clear first
        clearAll();
      }
    });

    return {
      handleReset,
      handleMenu,
      changeToNaira,
      changeToNairaTotal,
      showAgeSex,
      userMerger,
      isReady,
      isSubmitting,
      isLoading,
      action,
    };
  },
};
</script>  
      
<style scoped>
h5 {
  color: #2e4053;
}

.progress {
  margin-top: 0px; /* Adjust the value as needed to move the progress bar upwards */
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chip-more:hover {
  font-size: 150%;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>