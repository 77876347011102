<template>
  <form @submit.prevent="handleSubmit">
    <div class="card-panel">
      <div class="input-field">
        <input type="text" v-model="name" class="validate" required />
        <label class="active teal-text" for="name">Principal Name</label>
      </div>
    </div>

    <div class="card-panel">
      <div class="row">
        <div class="col s10">
          <div class="file-field input-field">
            <div class="btn grey">
              <span>Change</span>
              <input type="file" @change="handleFile" />
            </div>
            <div class="file-path-wrapper">
              <input class="file-path" v-model="inputFile" type="text" />
              <p v-if="fileError">{{ fileError }}</p>
            </div>
          </div>
        </div>
        <div class="col s2">
          <LoadingSmallDiv v-if="isLoading" />
          <span v-else>
            <img
              :src="img"
              width="64"
              class="circle responsive-img"
              v-if="img"
            />
            <span v-else>
              <img
                :src="enrollee.profile_img"
                width="64"
                class="circle responsive-img"
                v-if="enrollee.profile_img"
              />
              <img
                :src="`https://ui-avatars.com/api/?name=${enrollee.name}&size=128`"
                width="64"
                class="circle responsive-img"
                v-else
              />
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="card-panel">
      <div class="input-field">
        <label class="active teal-text" for="sex">Sex</label>
        <select id="sex" v-model="sex" class="validate" required>
          <option value="m">Male</option>
          <option value="f">Female</option>
        </select>
      </div>
    </div>

    <div class="card-panel">
      <div class="input-field">
        <input type="text" v-model="dob" class="datepicker validate" required />
        <label class="active teal-text" for="dob">Date of Birth</label>
      </div>
    </div>

    <div class="card-panel">
      <div class="input-field">
        <input
          type="email"
          v-model="email"
          class="validate"
          required
          v-if="allowEmailChange"
        />
        <input type="email" v-model="email" disabled v-else />
        <label class="active teal-text" for="email">Email</label>
      </div>
    </div>

    <div class="card-panel">
      <div class="input-field">
        <input type="text" v-model="phone" class="validate" required />
        <label class="active teal-text" for="phone">Phone</label>
      </div>
    </div>

    <div class="card-panel">
      <div class="input-field">
        <input type="text" v-model="address" class="validate" required />
        <label class="active teal-text" for="address">Address</label>
      </div>
    </div>

    <div class="card-content">
      <p v-if="uploadError"><br />{{ uploadError }}</p>

      <p>
        <br />
        <a
          class="waves-effect waves-light btn-large black"
          :class="{
            disabled: isSubmitting,
          }"
          @click="handleBack"
        >
          <i class="material-icons left">chevron_left</i>Back
        </a>
        <button
          class="waves-effect waves-light btn-large"
          :class="{
            disabled: isSubmitting,
          }"
        >
          <i class="material-icons right">check_circle</i
          >{{ isSubmitting ? "Updating" : "Update" }}
        </button>
      </p>
    </div>
  </form>
</template>
    
<script>
import { onMounted, onUpdated, ref, watchEffect } from "vue";
import putItem from "@/composables/putItem";
import useStorage from "@/composables/useStorage";
import LoadingSmallDiv from "@/components/LoadingSmallDiv.vue";
import M from "materialize-css";

export default {
  name: "EnrolleeEditOnboardComponent",
  components: { LoadingSmallDiv },
  props: ["enrollee"],
  emits: ["reset"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const name = ref(null);
    const sex = ref(null);
    const dob = ref(null);
    const email = ref(null);
    const allowEmailChange = ref(null);
    const phone = ref(null);
    const address = ref(null);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const error = ref(null);
    const img = ref(null);
    const types = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // loader
    onMounted(() => {
      name.value = props.enrollee.name;
      sex.value = props.enrollee.sex;

      dob.value = new Date(props.enrollee.dob).toLocaleString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      email.value = props.enrollee.user?.email;
      allowEmailChange.value =
        props.enrollee.user?.status == "a" ? false : true; // user has logged in

      phone.value = props.enrollee.phone;
      address.value = props.enrollee.address;
    });

    // select
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});

      // activate date
      const elemsDate = document.querySelectorAll(".datepicker");
      M.Datepicker.init(elemsDate, {
        onSelect: function (date) {
          dob.value = date;
        },
      });
    });

    // select
    onUpdated(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // clear
    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      isLoading.value = false;
      uploadProgress.value = "0%";
    };

    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Image (.JPG, .PNG, .GIF)";
      }
    };

    const handleData = () => {
      // console.log("data");
    };

    // handle back
    const handleBack = () => {
      context.emit("reset", null);
    };

    //hand
    watchEffect(() => {
      if (file.value) {
        // start ploading
        isLoading.value = true;
        uploadError.value = null;

        // upload image
        uploadFile(file.value);
      }
    });

    //handle submit
    const handleSubmit = async () => {
      // Materialize CSS handled all custome errors \\

      // start
      isSubmitting.value = true;
      error.value = false;
      uploadProgress.value = false;

      // re-format date
      let newDate = new Date(dob.value);
      newDate = newDate
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");

      // variable
      const newVal = {
        name: name.value,
        sex: sex.value,
        dob: newDate,
        email: email.value,
        phone: phone.value,
        address: address.value,
      };
      // check new image
      if (img.value) {
        newVal.profile_img = img.value;
      }

      // post item
      const { res, err } = await putItem(
        "principal",
        props.enrollee.id,
        newVal
      );

      if (res.value) {
        // start
        let principal;
        // next
        principal = {
          ...props.enrollee,
          ...newVal,
        };

        // add principal email
        if (principal.user === null) {
          principal.user = {};
        }
        principal.user.email = email.value;

        // go back
        context.emit("reset", principal);
      } else {
        error.value = err.value;
      }

      // end here
      // clear
      clearForm();
    };

    // watch tariff upload
    watchEffect(() => {
      if (completed.value) {
        // set image
        img.value = data.value;
        // clear
        clearForm();
        // data.succ.Success, data.succ.inserted_rows, data.succ.Error
        // console.log(data); // might not be necessary
      }
      if (uploadError.value) {
        // clear
        clearForm();
      }
    });

    return {
      handleFile,
      handleData,
      handleBack,
      handleSubmit,
      isSubmitting,
      isLoading,
      error,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      img,
      name,
      sex,
      dob,
      email,
      allowEmailChange,
      phone,
      address,
    };
  },
};
</script>
    
<style>
</style>