<template>
  <!-- Use general search from parent component -->
  <!-- In-active (Out) Principals -->
  <span v-for="(enrollee, index) in enrollees" :key="enrollee.id">
    <!-- Deleted Principals only -->
    <div class="card" v-if="enrollee.status == 'd'">
      <div class="card-panel grey lighten-5">
        <div class="row">
          <div class="col s1">
            <span class="chip grey white-text center">{{ index + 1 }}</span>
          </div>
          <div class="col s10">
            <h5>
              <s>{{ enrollee.name }}</s>
              <small> ({{ enrollee.enrollee_number }})</small>
            </h5>
          </div>
          <div class="col s1">
            <span class="right">
              <a
                href="#"
                class="secondary-content grey-text dropdown-trigger"
                :data-target="`dropmenu_${enrollee.id}`"
                ><i class="small material-icons" title="menu">more_vert</i></a
              >

              <!-- DropMenu -->
              <ul :id="`dropmenu_${enrollee.id}`" class="dropdown-content">
                <li
                  @click.prevent="
                    handleMenu({ type: 'status', value: enrollee })
                  "
                  v-if="enrollee.status == 'd'"
                >
                  <a href="#">Restore Account</a>
                </li>
                <li class="divider" tabindex="-1"></li>
                <li
                  @click.prevent="handleMenu({ type: 'kill', value: enrollee })"
                  v-if="enrollee.status == 'd'"
                >
                  <a href="#">Delete Account</a>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </div>

      <!-- Restore -->
      <EnrolleeStatusDiv
        :enrollee="enrollee"
        @reset="handleReset"
        v-if="action == 'status' && currId == enrollee.id"
      />

      <!-- Soft-delete -->
      <KillAccountDiv
        :enrollee="enrollee"
        @reset="handleReset"
        v-if="action == 'kill' && currId == enrollee.id"
      />
    </div>
  </span>
</template>
  

<script>
import { ref } from "vue";
import { ageSex, changeToMoney } from "@/composables/fxLibrary/coreFx";
import EnrolleeStatusDiv from "@/components/client/onboard/EnrolleeStatusEditOnboardComponent.vue";
import KillAccountDiv from "@/components/client/onboard/EnrolleeKillAccountOnboardComponent.vue";

export default {
  name: "PrincipalOutClientComponent",
  components: {
    EnrolleeStatusDiv,
    KillAccountDiv,
  },
  props: ["cat", "enrollees"],
  emits: ["reset"],
  setup(props, context) {
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const error = ref(null);
    const action = ref(null);
    const currId = ref(null);

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // to naira
    const changeToNaira = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // to naira
    const changeToNairaTotal = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // clear all
    const clearAll = () => {
      // clear
      action.value = null;
      currId.value = null;
    };

    // handle menu
    const handleMenu = (val) => {
      if (val.type == "status") {
        // clear
        clearAll();
        // reset
        action.value = "status";
        currId.value = val.value.id;
      }
      if (val.type == "kill") {
        // clear
        clearAll();
        // reset
        action.value = "status";
        currId.value = val.value.id;
      }
    };

    // handle
    const handleReset = (val) => {
      // go back
      context.emit("reset", val);
      // clear
      clearAll();
    };

    return {
      handleReset,
      handleMenu,
      changeToNaira,
      changeToNairaTotal,
      showAgeSex,
      isReady,
      isSubmitting,
      isLoading,
      error,
      action,
      currId,
    };
  },
};
</script>
    
  
  <style scoped>
.progress {
  margin-top: 0px; /* Adjust the value as needed to move the progress bar upwards */
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chip-more:hover {
  font-size: 150%;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>