<template>
  <!-- Loader -->
  <div class="progress" v-if="!isReady">
    <div class="indeterminate"></div>
  </div>

  <form @submit.prevent="handleSubmit">
    <div class="card-content">
      <p>
        <a
          class="waves-effect waves-light btn-large black"
          :class="{
            disabled: isSubmitting,
          }"
          @click="handleBack"
        >
          <i class="material-icons left">chevron_left</i>Back
        </a>
        <button
          class="waves-effect waves-light btn-large"
          :class="{
            disabled: !isReady || isSubmitting,
            'red ligthen-2': newStatus.toLowerCase().includes('remove'),
            teal: !newStatus.toLowerCase().includes('remove'),
          }"
          v-if="newStatus"
        >
          <i class="material-icons right">check_circle</i
          >{{ isSubmitting ? "Updating" : newStatus }}
        </button>
      </p>
    </div>
  </form>
</template>
        
  <script>
import { onMounted, ref } from "vue";
import putItem from "@/composables/putItem";

export default {
  name: "EnrolleeStatusEditOnboardComponent",
  props: ["cat", "enrollee"],
  emits: ["reset"],
  setup(props, context) {
    const newStatus = ref(null);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const error = ref(null);

    // starter
    onMounted(() => {
      // start from the top
      if (props.enrollee.status == "d") {
        newStatus.value = `Re-activate ${props.enrollee.name}`;
      } else {
        newStatus.value = `Remove ${props.enrollee.name}`;
      }

      // setup geez
      setTimeout(() => {
        isReady.value = true;
      }, 3000);
    });

    // handle back
    const handleBack = () => {
      context.emit("reset", null);
    };

    //handle submit
    const handleSubmit = async () => {
      // Materialize CSS handled all custome errors \\

      // start
      isSubmitting.value = true;
      error.value = null;

      // set updated status
      let updatedStatus;
      if (props.enrollee.status == "d") {
        updatedStatus = "a";
      } else {
        updatedStatus = "d";
      }

      // round up
      let actionUrl;
      const newVal = {
        type: "p", // principal
        status: updatedStatus,
      };

      // kill for TPA straightaway
      if (props.cat == 'tpa') {
       actionUrl = "operator/client/enrollee/status/kill";
      }else{
        actionUrl = "operator/client/enrollee/status/update";
      }

      // post item
      const { res, err } = await putItem(
        actionUrl,
        props.enrollee.id,
        newVal
      );

      if (res.value) {
        // start
        let principal;
        // next
        principal = {
          ...props.enrollee,
          ...res.value.data,
        };

        // go back
        context.emit("reset", principal);
      } else {
        error.value = err.value;
      }

      // show button back
      isSubmitting.value = false;
    };

    return {
      handleBack,
      handleSubmit,
      error,
      newStatus,
      isReady,
      isSubmitting,
      isLoading,
    };
  },
};
</script>
        
<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>