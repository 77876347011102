<template>
  <div class="card-panel grey-text text-darken-1" v-if="currPlan">
    <span class="tab-custom-active" @click="handleClear()">
      <i class="tiny material-icons">chevron_left</i>
      All Plans
    </span>
    <span class="tab-custom">
      {{ currPlan.name }}
      <i class="tiny material-icons teal-text">check_circle</i>
    </span>
  </div>

  <!-- Selected Others (HMO, Retail) [0 = suspended] -->
  <div v-if="cat != 'tpa'">
    <div v-for="plan in plans" :key="plan.id">
      <div class="card-panel" v-if="!currPlan || plan.id == currPlan.id">
        <span v-if="plan.premiums[0].amount > 0">
          <div class="row">
            <div class="col s3">
              <h5>
                {{ plan.name }}
                <span
                  class="grey-text text-darken-1"
                  v-if="
                    plan.premiums.filter((x) => x.scope == client.id).length
                  "
                  ><small>(Customized)</small></span
                >
              </h5>
            </div>

            <div class="col s2">
              <span
                class="black-text text-darken-1"
                v-if="plan.premiums.filter((x) => x.scope == client.id).length"
                >Customized Plan</span
              >
              <span class="black-text" v-else>{{ plan.description }} </span
              ><br />
              <label class="active">Policy Description</label>
            </div>

            <div class="col s3">
              <span class="black-text"
                ><b>₦{{ changeToNaira(plan.premiums, "i") }}</b></span
              ><br />
              <label class="active">(Individual) Premium</label>
            </div>

            <div class="col s3">
              <span class="black-text"
                ><b>₦{{ changeToNaira(plan.premiums, "f") }}</b></span
              ><br />
              <label class="active">(Family) Premium</label>
            </div>

            <div class="col s1">
              <span class="right">
                <a
                  href="#"
                  class="secondary-content teal-text text-darken-1 dropdown-trigger"
                  :data-target="`dropmenu_${plan.id}`"
                  ><i class="small material-icons" title="review"
                    >more_vert</i
                  ></a
                >

                <!-- DropMenu -->
                <ul :id="`dropmenu_${plan.id}`" class="dropdown-content">
                  <li
                    @click.prevent="handleMenu({ type: 'view', value: plan })"
                  >
                    <a href="#">View Details</a>
                  </li>
                  <li class="divider" tabindex="-1"></li>
                  <li
                    @click.prevent="handleMenu({ type: 'update', value: plan })"
                  >
                    <a href="#">Update Policy</a>
                  </li>
                  <li class="divider" tabindex="-1"></li>
                  <li
                    @click.prevent="handleMenu({ type: 'status', value: plan })"
                  >
                    <a href="#">Suspend Policy</a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col s3"></div>
            <div class="col s2"></div>

            <div class="col s3">
              <span class="black-text"
                ><b>{{ plan.individuals }}</b> <em>of</em>
                {{ client.principals_count }}</span
              ><br />
              <label class="green-text active"
                ><strong>Active Individuals</strong></label
              >
            </div>

            <div class="col s3">
              <span class="black-text"
                ><b>{{ plan.familys }}</b> <em>of</em>
                {{ client.principals_count }}</span
              ><br />
              <label class="green-text active"
                ><strong>Active Families</strong></label
              >
            </div>

            <div class="col s1"></div>
          </div>
        </span>

        <span v-else>
          <div class="row grey lighten-4">
            <div class="col s3 spacer10">
              <h5 class="grey-text text-darken-1">
                {{ plan.name }}
              </h5>
            </div>

            <div class="col s2 spacer10">
              <span class="black-text">{{ plan.description }} </span><br />
              <label class="active">Policy Description</label>
            </div>

            <div class="col s3 spacer10">
              <span class="black-text"
                ><b>{{ plan.individuals }}</b> <em>of</em>
                {{ client.principals_count }}</span
              ><br />
              <label class="green-text active"
                ><strong>Active Individuals</strong></label
              >
            </div>

            <div class="col s3 spacer10">
              <span class="black-text"
                ><b>{{ plan.familys }}</b> <em>of</em>
                {{ client.principals_count }}</span
              ><br />
              <label class="green-text active"
                ><strong>Active Families</strong></label
              >
            </div>

            <div class="col s1 spacer10">
              <span class="right">
                <a
                  href="#"
                  class="secondary-content teal-text text-darken-1"
                  @click.prevent="handleMenu({ type: 'status', value: plan })"
                  ><i class="small material-icons" title="restore plan"
                    >settings_power</i
                  ></a
                >
              </span>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>

  <!-- Selected TPA [0 = active]-->
  <div v-else>
    <div v-for="plan in plans" :key="plan.id">
      <div class="card-panel" v-if="!currPlan || plan.id == currPlan.id">
        <span v-if="plan.premiums[0].amount > 0">
          <div class="row grey lighten-4">
            <div class="col s3 spacer10">
              <h5 class="grey-text text-darken-1">
                {{ plan.name }}
              </h5>
            </div>

            <div class="col s2 spacer10">
              <span class="black-text">Reference Plan </span><br />
              <label class="active">Policy Description</label>
            </div>

            <div class="col s3 spacer10">
              <span class="black-text"
                ><b>{{ plan.individuals }}</b> <em>of</em>
                {{ client.principals_count }}</span
              ><br />
              <label class="green-text active"
                ><strong>Active Individuals</strong></label
              >
            </div>

            <div class="col s3 spacer10">
              <span class="black-text"
                ><b>{{ plan.familys }}</b> <em>of</em>
                {{ client.principals_count }}</span
              ><br />
              <label class="green-text active"
                ><strong>Active Families</strong></label
              >
            </div>

            <div class="col s1 spacer10">
              <span class="right">
                <a
                  href="#"
                  class="secondary-content teal-text text-darken-1"
                  @click.prevent="handleMenu({ type: 'status', value: plan })"
                  ><i class="small material-icons" title="restore plan"
                    >settings_power</i
                  ></a
                >
              </span>
            </div>
          </div>
        </span>

        <span v-else>
          <div class="row">
            <div class="col s3">
              <h5>
                {{ plan.name }}
              </h5>
            </div>

            <div class="col s2">
              <span class="black-text">Reference Plan </span><br />
              <label class="active">Policy Description</label>
            </div>

            <div class="col s3">
              <span class="black-text"><b>N/A</b></span
              ><br />
              <label class="active">(Individual) Premium</label>
            </div>

            <div class="col s3">
              <span class="black-text"><b>N/A</b></span
              ><br />
              <label class="active">(Family) Premium</label>
            </div>

            <div class="col s1">
              <span class="right">
                <a
                  href="#"
                  class="secondary-content teal-text text-darken-1 dropdown-trigger"
                  :data-target="`dropmenu_${plan.id}`"
                  ><i class="small material-icons" title="review"
                    >more_vert</i
                  ></a
                >

                <!-- DropMenu -->
                <ul :id="`dropmenu_${plan.id}`" class="dropdown-content">
                  <li
                    @click.prevent="handleMenu({ type: 'view', value: plan })"
                  >
                    <a href="#">View Details</a>
                  </li>
                  <li class="divider" tabindex="-1"></li>
                  <li
                    @click.prevent="handleMenu({ type: 'update', value: plan })"
                  >
                    <a href="#">Update Policy</a>
                  </li>
                  <li class="divider" tabindex="-1"></li>
                  <li
                    @click.prevent="handleMenu({ type: 'status', value: plan })"
                  >
                    <a href="#">Suspend Policy</a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col s3"></div>
            <div class="col s2"></div>

            <div class="col s3">
              <span class="black-text"
                ><b>{{ plan.individuals }}</b> <em>of</em>
                {{ client.principals_count }}</span
              ><br />
              <label class="green-text active"
                ><strong>Active Individuals</strong></label
              >
            </div>

            <div class="col s3">
              <span class="black-text"
                ><b>{{ plan.familys }}</b> <em>of</em>
                {{ client.principals_count }}</span
              ><br />
              <label class="green-text active"
                ><strong>Active Families</strong></label
              >
            </div>

            <div class="col s1"></div>
          </div>
        </span>
      </div>
    </div>
  </div>

  <!-- Edit Plan-->
  <div v-if="currPlan && editPolicy">
    <PolicyEditDiv
      :cat="cat"
      :policy="currPlan"
      :client="client"
      @reset="handleUpdateComplete"
      v-if="editPolicy"
    />
  </div>

  <!-- Status Plan-->
  <div v-if="currPlan && statusPolicy">
    <PolicyStatusDiv
      :cat="cat"
      :policy="currPlan"
      :client="client"
      @reset="handleUpdateStatus"
      v-if="statusPolicy"
    />
  </div>

  <!-- Benefits -->
  <div v-if="currPlan && !editPolicy && !statusPolicy">
    <!-- Mini loader For Benefits -->
    <div class="progress" v-if="isLoading">
      <div class="indeterminate"></div>
    </div>

    <template v-else>
      <div class="card-panel" v-for="benefit in benefits" :key="benefit.id">
        <div class="row">
          <div class="col s12">
            <h6>
              {{ benefit.item }}
              <span class="secondary-content chip">{{
                benefit.description
              }}</span>
            </h6>
          </div>
        </div>
      </div>

      <div class="section center" v-if="!benefits.length">
        <h6>No Benefits <em>found</em>...</h6>
      </div>

      <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
    </template>
  </div>
</template>

  
  
<script>
import { ref, onMounted, onUpdated, computed } from "vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import getItemsOfItemsOfItems from "@/composables/getItemsOfItemsOfItems";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import PolicyEditDiv from "@/components/client/onboard/PolicyReviewEditOnboardComponent.vue";
import PolicyStatusDiv from "@/components/client/onboard/PolicyReviewStatusOnboardComponent.vue";
import M from "materialize-css";

export default {
  name: "PolicyClientComponent",
  components: { PaginationDiv, PolicyEditDiv, PolicyStatusDiv },
  props: ["cat", "client"],
  emits: ["reset"],
  setup(props, context) {
    const isLoading = ref(false);
    const response = ref(null);
    const responsePlans = ref(props.client.plans);
    const currUrl = ref(null);
    const currHook = ref(null);
    const error = ref(null);
    const currPlan = ref(null);
    const addPolicy = ref(false);
    const editPolicy = ref(false);
    const statusPolicy = ref(false);

    // get function
    const getBenefitsFromApi = (table, planId, clientId) => {
      (async () => {
        const { res, err } = await getItemsOfItemsOfItems(
          table,
          planId,
          clientId
        );

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // close
        isLoading.value = false;
      })();
    };

    // get benefits
    const getBenefits = () => {
      // start laoding
      isLoading.value = true;
      // set current Url
      currUrl.value = "benefits/plan/search";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getBenefitsFromApi(currUrl.value, currPlan.value.id, props.client.id);
    };

    // to naira
    const changeToNaira = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // starter
    onMounted(() => {
      dropDownMaterialize();
    });

    // more
    onUpdated(() => {
      dropDownMaterialize();
    });

    const dropDownMaterialize = () => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getBenefitsFromApi(
        currUrl.value,
        currPlan.value.id,
        `${props.client.id}${currHook.value}page=${page}`
      );
    };

    // handle curr status
    const handleClear = () => {
      // view All plans
      addPolicy.value = false;
      editPolicy.value = false;
      currPlan.value = null;
    };

    // handle add
    const handleAdd = () => {
      handleClear();
    };

    // handle update complete
    const handleUpdateStatus = (val) => {
      // update main
      if (val) {
        // load now
        responsePlans.value = val.plans;
        // let parent have the update
        context.emit("reset", val);
      }

      // clear all
      currPlan.value = null;
      addPolicy.value = false;
      editPolicy.value = false;
      statusPolicy.value = false;
    };

    // handle update complete
    const handleUpdateComplete = (val) => {
      // update main
      if (val) {
        // load now
        responsePlans.value = val.plans;
        // reload benefits
        getBenefits();
        // let parent have the update
        context.emit("reset", val);
      }

      // clear all
      addPolicy.value = false;
      editPolicy.value = false;
    };

    // handle menu
    const handleMenu = (val) => {
      // set current plan
      currPlan.value = val.value;

      // reset view
      if (val.type == "view") {
        editPolicy.value = false;
        statusPolicy.value = false;
      }

      // set edit
      if (val.type == "update") {
        statusPolicy.value = false;
        editPolicy.value = true;
      }

      // set status
      if (val.type == "status") {
        editPolicy.value = false;
        statusPolicy.value = true;
      }

      // get benfits
      getBenefits();
    };

    // output results (plans)
    const plans = computed(() => {
      if (!responsePlans.value.length) {
        return [];
      } else {
        return responsePlans.value;
      }
    });

    // output results
    const benefits = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handleClear,
      handleAdd,
      handleUpdateComplete,
      handleUpdateStatus,
      handleMenu,
      handlePage,
      changeToNaira,
      error,
      plans,
      addPolicy,
      editPolicy,
      statusPolicy,
      isLoading,
      currPlan,
      benefits,
      allPages,
    };
  },
};
</script>
  
  
<style scoped>
.progress {
  margin-top: -14px; /* Adjust the value as needed to move the progress bar upwards */
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>