<template>
  <input
    type="text"
    :id="searchId"
    class="autocomplete"
    v-model="searchItem"
    @focus="handleClear"
  />
  <label :for="searchId" v-if="fieldName">{{ fieldName }}</label>

  <div class="progress" v-if="isSearching">
  <div class="indeterminate"></div>
  </div>

</template>
    
        
<script>
import { ref, onMounted, watch, watchEffect } from "vue";
import M from "materialize-css";

export default {
  emits: ["selected", "searching", "clear"],
  props: ["fieldName", "inputId", "search", "data", "clear", "placeHolder"],
  setup(props, context) {
    const selected = ref(false);
    const isSearching = ref(false);
    const searchItem = ref(null);
    const searchId = ref(props.inputId);


    // clear field
    const handleClear = () => {
      searchItem.value = null;
      // end progress bar
      isSearching.value = false;
      // clear select
      selected.value = false;
      // emit selected (cleared)
      context.emit("selected", { id: searchId.value, value: null });
    };

    // special case 
    // where there is a default data value
    // if you don't want to search the default value
    // turn off the search bolean
    // till the clear button sends null value back to parent user
    watchEffect(() => {
      if (props.placeHolder) {
        searchItem.value = props.placeHolder;
      }
    });

    // searching feedbacks
    watchEffect(() => {
      if (searchItem.value && props.search) {
        context.emit("searching", {
          id: searchId.value,
          value: searchItem.value,
        });
      // start progress bar (if not selected)
      if (!selected.value) {
      isSearching.value = true;
      }  
      }else{
       // end progress bar
      isSearching.value = false;  
      }
    });

    // clear searching
    watchEffect(() => {
     if (props.data) {
      // end progress bar
      isSearching.value = false;
     }    
    });

    watchEffect(() => {
      if (props.clear) {
        searchItem.value = null;
        context.emit("clear", {
          id: searchId.value,
          value: false,
        });
      }
    });

    // autocomlete function
    const initializeAutocomplete = () => {
      const elem = document.getElementById(searchId.value);
      M.Autocomplete.init(elem, {
        data: props.data,
        onAutocomplete: function (val) {
          searchItem.value = val;
          context.emit("selected", { id: searchId.value, value: val });
          // set selected
          selected.value = true;
        },
        minLength: 0,
      });
    };

    // update autocomplete data function
    const updateAutocompleteData = (newVal) => {

      const elem = document.getElementById(searchId.value);
      const instance = M.Autocomplete.getInstance(elem);

      // if empty (fail quietly)
      if (instance) {
        instance.updateData(newVal);
        if (props.search && !selected.value) {
          instance.open(); // Open the dropdown for new searches only
        } 
      }
      
    };

    // watch for changes
    watch(props, (newVal) => {
      updateAutocompleteData(newVal.data);
    });

    // initiate autocomplete
    onMounted(() => {
      initializeAutocomplete();
    });

    return {
      isSearching,
      handleClear,
      searchItem,
      searchId,
    };
  },
};
</script>
<style scoped>
.progress {
  margin-top: -10px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>
        