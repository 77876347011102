<template>
  <div class="">
    <br />
    <br />
    <br />
    <br />

    <div class="container valign-wrapper">
      <div class="card-panel">
        <div class="progress" v-if="isSubmitting">
          <div class="indeterminate"></div>
        </div>

        <div class="col s12" v-if="!firstTimer">
          <!-- Error -->
          <span class="chip yellow lighten-4" v-if="error">{{ error }}</span>

          <h2>Login to <br />your account</h2>
          <!-- </div>
        <div class="col s2"><br /></div>
        <div class="col s4"> -->
          <span class="right">
            <form @submit.prevent="handleSignIn">
              <input
                type="text"
                title="username"
                placeholder="Username"
                v-model="username"
              />
              <input
                type="password"
                title="password"
                placeholder="Password"
                v-model="password"
              />
              <br /><br />
              <button
                type="submit"
                class="btn"
                :class="{ disabled: isSubmitting }"
              >
                Login
              </button>
            </form>
          </span>
        </div>

        <div class="col s12" v-else>
          <!-- Error -->
          <span class="chip yellow lighten-4" v-if="error">{{ error }}</span>

          <h2>Update <br />your account</h2>
          <!-- </div>
        <div class="col s2"><br /></div>
        <div class="col s4"> -->

          <span class="right">
            <form @submit.prevent="handleRecordUpdate">
              <input
                type="text"
                title="name"
                placeholder="Your Name"
                v-model="name"
              />

              <div class="card-panel">
                <div class="row">
                  <div class="col s10">
                    <div class="file-field input-field">
                      <div class="btn grey">
                        <span>Profile</span>
                        <input type="file" @change="handleFile" />
                      </div>
                      <div class="file-path-wrapper">
                        <input
                          class="file-path"
                          v-model="inputFile"
                          type="text"
                        />
                        <p v-if="fileError">{{ fileError }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col s2">
                    <LoadingSmallDiv v-if="isLoading" />
                    <span v-else>
                      <img
                        :src="img"
                        width="64"
                        class="circle responsive-img"
                        v-if="img"
                      />
                      <span v-else>
                        <img
                          :src="firstTimer?.profile_img"
                          width="64"
                          class="circle responsive-img"
                          v-if="firstTimer?.profile_img"
                        />
                        <img
                          src="https://ui-avatars.com/api/?name=img&size=128"
                          width="64"
                          class="circle responsive-img"
                          v-else
                        />
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <input
                type="text"
                title="department"
                placeholder="Department"
                v-model="department"
              />
              <input
                type="text"
                title="designation"
                placeholder="Designation"
                v-model="designation"
              />
              <input
                type="text"
                title="office line"
                placeholder="Official Line"
                v-model="officialLine"
              />
              <input
                type="text"
                title="staff_id"
                placeholder="Staff ID"
                v-model="staffId"
              />

              <br /><br />

              <label for="newPassword" class="blue-text">Change Password</label>
              <input
                type="text"
                title="password"
                placeholder="Change Password"
                v-model="newPassword"
                class="validate"
                required
              />

              <br /><br /><br />
              <button
                type="submit"
                class="btn"
                :class="{ disabled: isSubmitting }"
              >
                Login
              </button>
            </form>
          </span>
        </div>
      </div>
    </div>
  </div>
  <p><br /><br /><br /><br /><br /></p>
</template>
  
<script>
import { onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import putItem from "@/composables/putItem";
import getItems from "@/composables/getItems";
import useStorage from "@/composables/useStorage";
import LoadingSmallDiv from "@/components/LoadingSmallDiv.vue";
import { logout } from "@/composables/logout/logout.js";

export default {
  name: "LoginHome",
  components: { LoadingSmallDiv },
  setup() {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const firstTimer = ref(null);
    const username = ref(null);
    const password = ref(null);
    const newPassword = ref(null);
    const name = ref(null);
    const staffId = ref(null);
    const officialLine = ref(null);
    const designation = ref(null);
    const department = ref(null);
    const error = ref(null);
    const img = ref(null);
    const types = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

    // vue router
    const router = useRouter();

    // store
    const store = useStore();

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // start
    onMounted(() => {
      // page name
      document.title = "Admin Login | easyHMO";
    });

    onMounted(() => {
      // clean up
      logout();
    });  

    // clear
    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      isLoading.value = false;
      uploadProgress.value = "0%";
    };

    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Image (.JPG, .PNG, .GIF)";
      }
    };

    //hand
    watchEffect(() => {
      if (file.value) {
        // start ploading
        isLoading.value = true;
        uploadError.value = null;

        // upload image
        uploadFile(file.value);
      }
    });

    watchEffect(() => {
      if (completed.value) {
        // set image
        img.value = data.value;

        // clear
        clearForm();
        // data.succ.Success, data.succ.inserted_rows, data.succ.Error
        // console.log(data); // might not be necessary
      }
      if (uploadError.value) {
        // clear
        clearForm();
      }
    });

    // loader
    const loadRecord = (val) => {
      store.commit("loadUser", val.name);
      store.commit("loadProfile", {
        id: val.id,
        user_id: val.user_id,
        name: val.name,
        slug: val.slug,
        profile_img: val.profile_img,
        staff_id: val.staff_id,
        department: val.department,
        official_line: val.official_line,
      });
      store.commit("loadRole", val.designation);
      store.commit("loadSchemes", val.schemes);
      store.commit("loadAuthLevel", val.level);
      store.commit("loadModules", val.modules);
    };

    const handleRecordUpdate = () => {
      // start
      isSubmitting.value = true;
      error.value = null;

      (async () => {
        let newVal = {
          password: newPassword.value,
          name: name.value,
          profile_img: img.value,
          staff_id: staffId.value,
          official_line: officialLine.value,
          designation: designation.value,
          department: department.value,
        };

        const { res } = await putItem(
          "authority/hmo/update",
          firstTimer.value.id,
          newVal
        );

        if (res.value) {
          // load
          loadRecord(res.value.data);
          // refer back home
          router.push({
            name: "loading",
          });
        } else {
          error.value = "Sorry, Error Updating records";
        }

        // page is ready, loading is complete
        isSubmitting.value = false;
      })();
    };

    const handleSignIn = () => {
      // start
      isSubmitting.value = true;
      error.value = null;

      (async () => {
        const { res } = await getItems(
          `authority/hmo/signin?username=${username.value}&password=${password.value}`
        );

        if (res.value && res.value?.success === true) {
          if (res.value.status === "p") {
            firstTimer.value = res.value.data;
          } else if (res.value.status === "a") {
            // load
            loadRecord(res.value.data);
            // refer back home
            router.push({
              name: "loading",
            });
          }
        } else {
          error.value = "Sorry, wrong credentials";
        }

        // page is ready, loading is complete
        isSubmitting.value = false;
      })();
    };

    return {
      firstTimer,
      handleFile,
      handleRecordUpdate,
      handleSignIn,
      isSubmitting,
      isLoading,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      img,
      username,
      password,
      newPassword,
      name,
      staffId,
      officialLine,
      designation,
      department,
      error,
    };
  },
};
</script>
  
  <style>
.spacer {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 25px; /* Add padding to the left */
  padding-right: 25px; /* Add padding to the right */
}
</style>