<template>
  <LoadingDiv v-if="isLoading" />

  <template v-else>
    <div class="grey-bg-custom">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <!-- <h1 class="teal-text">
              <i class="medium material-icons">filter_4</i> Base Tariffs
            </h1> -->

            <div class="section">
              <h5 class="header">Added Tariffs</h5>
            </div>

            <!-- All -->
            <div class="section">
              <div
                class="card-panel"
                v-for="(plan, id) in plans"
                :key="plan.id"
              >
                <template v-if="editPolicy === plan">
                  <form @submit.prevent="handleUpload(plan)">
                    <div class="row">
                      <div class="col s1">
                        <span class="chip red darken-2 white-text">{{
                          id + 1
                        }}</span>
                      </div>
                      <div class="col s9">
                        <h5 class="black-text">
                          {{ plan.name }}
                          <span class="teal-text"
                            ><small>{{
                              plan.scheme.type === "p"
                                ? "Private Scheme"
                                : "Social Scheme"
                            }}</small></span
                          >
                        </h5>
                      </div>
                      <div class="col s2">
                      <div class="right">
                        <a
                          class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2"
                          ><i
                            class="material-icons indigo"
                            title="return"
                            @click="handleEdit(null)"
                            >chevron_left</i
                          ></a
                        >
                      </div>
                    </div>
                    </div>
                    <div class="row">
                      <div class="col s1"><br /></div>
                      <div class="col s9">
                        <div class="file-field input-field">
                          <div class="btn black">
                            <span v-if="plan.schedules.length">New Tariff</span>
                            <span v-else>Add Tariff</span>
                            <input type="file" @change="handleFile" />
                          </div>
                          <div class="file-path-wrapper">
                            <input
                              class="file-path"
                              v-model="inputFile"
                              type="text"
                            />
                            <p class="red-text" v-if="fileError">
                              {{ fileError }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col 2">
                        <button
                          class="btn-floating btn-medium waves-effect waves-light black right"
                        >
                          <i class="material-icons" title="Upload tariff"
                            >upload</i
                          >
                        </button>
                      </div>
                    </div>
                  </form>
                </template>

                <template v-else>
                  <div class="row">
                    <div class="col s1">
                      <span class="chip grey darken-2 white-text">{{
                        id + 1
                      }}</span>
                    </div>
                    <div class="col s9">
                      <h5>
                        {{ plan.name }}
                      </h5>
                      <h6 v-if="plan.scheme.type === 'p'">Private Scheme</h6>
                      <h6 v-if="plan.scheme.type === 's'">Social Scheme</h6>
                      <p
                        class="chip teal lighten-4"
                        v-if="plan.schedules.length"
                      >
                        Active Standard Schedule
                      </p>
                      <p class="chip" v-else>No Standard Schedule</p>
                    </div>
                    <div class="col s2">
                      <div class="right">
                        <a
                          class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2"
                          ><i
                            class="material-icons"
                            title="edit plan"
                            @click="handleEdit(plan)"
                            >edit</i
                          ></a
                        >
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <!-- Submit -->
              <div class="section center">
                <p>
                  <br /><br />
                  <button
                    class="waves-effect waves-light btn-large"
                    @click="handleNext"
                  >
                    <i class="material-icons right">call_made</i>Next
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- <footer class="page-footer grey lighten-3">
        <div class="footer-copyright">
          <div class="container black-text">© 2024 easyHMO.com.ng</div>
        </div>
      </footer> -->
      </div>
    </div>
  </template>
</template>

<script>
import { ref, computed, onMounted, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import useStorage from "@/composables/useStorage";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "TariffSetupComponent",
  components: { LoadingDiv },
  emits: ["next"],
  setup(props, context) {
    const response = ref(null);
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const error = ref(null);
    const next = ref(false);
    const editPolicy = ref(null);
    const planId = ref(null);
    const success = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // search function
    const getItemsFromApi = (table) => {
      (async () => {
        // start
        isLoading.value = true;

        const { res } = await getItems(table);
        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = "Plans couldn't load!";
        }

        // end
        isLoading.value = false;
      })();
    };

    // start
    onMounted(() => {
      // page name
      getItemsFromApi("plan/setup");
    });

    // toMoney
    const toMoney = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // edit policy
    const handleEdit = (val) => {
      editPolicy.value = val;
    };

    // handle add
    const handleReset = () => {
      // clear files
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      uploadProgress.value = "0%";
      uploadError.value = null;
      data.value = null;
      completed.value = null;
      // plan id
      planId.value = null;
      // next
      next.value = false;
      // clear error
      error.value = false;
      // clear loading
      isLoading.value = false;
      // clear submitting
      isSubmitting.value = false;
    };

    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
        error.value = false;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
        error.value = false;
      }
    };

    const handleUpload = (val) => {
      if (file.value) {
        // Start Uploading
        isSubmitting.value = true;
        isLoading.value = true;
        uploadError.value = null;

        // Cloud Function
        const fx = "tariffLoader";

        // Start
        let schedule = {
          plan_id: val.id,
          type: "s", // c=customized, s=standard (there can/should be only one standard)
        };

        // Round up
        const cloudInput = {
          schedule: schedule,
          provider: null,
          newProvider: null,
        };

        // upload
        uploadFile(file.value, fx, cloudInput);

        // load planId
        planId.value = val.id;
      } else {
        // error
        error.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        // reload
        getItemsFromApi("plan/setup");
        // reset
        handleReset();
      }
      if (uploadError.value) {
        // restart
        handleReset();
      }
    });

    // handle submit
    const handleNext = () => {
      // next
      // emit new parameters
      context.emit("next", {
        step: 4,
        isComplete: true,
      });
    };

    // load plans
    const plans = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleEdit,
      handleNext,
      handleUpload,
      handleFile,
      toMoney,
      isLoading,
      isSubmitting,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      error,
      editPolicy,
      success,
      next,
      plans,
    };
  },
};
</script>
    
<style>
.grey-bg-custom {
  background-color: #f2f2f2;
}
.hover-text {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
</style>