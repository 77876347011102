<template>
  <div class="container">
        <div class="card-content">
          <router-link :to="{ name: 'private' }">
            <i class="medium material-icons blue-grey-text text-darken-2 right">business</i>
          </router-link>
          <h3 class="header blue-grey-text text-darken-2">
            <router-link :to="{ name: 'private' }"
              ><i class="small material-icons" title="Go Back"
                >subdirectory_arrow_left</i
              ></router-link
            >
            <small>
           <span
              class="tooltipped pointer"
              data-position="bottom"
              :data-tooltip="newClient.name"
              v-if="newClient"
              >{{ maxRule(newClient.name) }}</span
            >
            <span v-else> Add Client</span>
          </small>
          </h3>
        </div>
  

    <ProfileDiv :cat="cat" @next="handleNext" v-if="next == 'start'" />
    <PolicyDiv
      :cat="cat"
      :newClient="newClient"
      @next="handleNext"
      v-if="next == 'policy'"
    />
    <StatusDiv
      :cat="cat"
      :newClient="newClient"
      @next="handleNext"
      v-if="next == 'status'"
    />
    <SettingsDiv
      :cat="cat"
      :newClient="newClient"
      @next="handleNext"
      v-if="next == 'settings'"
    />
  </div>
</template>
  
<script>
import { ref, onMounted, watchEffect } from "vue";
import { useRouter } from "vue-router";
import ProfileDiv from "@/components/client/onboard/ProfileOnboardComponent.vue";
import PolicyDiv from "@/components/client/onboard/PolicyOnboardComponent.vue";
import StatusDiv from "@/components/client/onboard/StatusOnboardComponent.vue";
import SettingsDiv from "@/components/client/onboard/SettingsOnboardComponent.vue";
import M from "materialize-css";

export default {
  name: "privateClientAdd",
  components: { ProfileDiv, PolicyDiv, StatusDiv, SettingsDiv },
  setup() {
    const cat = ref("hmo");
    const next = ref("start");
    const newClient = ref(null);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Add Client | EasyHMO";
    });

    // Title
    watchEffect(() => {
      if (newClient.value) {
        // load title
        setTimeout(() => {
          var elems = document.querySelectorAll(".tooltipped");
          M.Tooltip.init(elems, {});
        }, 2000);
      }
    });

    // UI modification
    const maxRule = (text) => {
      let maxLength = 15;
      if (text.length > maxLength) {
        return ` ${text.substring(0, maxLength)} ... `;
      } else {
        return ` ${text} `;
      }
    };

    // handle next
    const handleNext = (val) => {
      if (val.id == "profile") {
        // new client name
        newClient.value = val.value;
        // next
        next.value = "policy";
      } else if (val.id == "policy") {
        next.value = "status";
      } else if (val.id == "status") {
        next.value = "settings";
      } else if (val.id == "settings") {
        //submit here
        router.push({ name: "privateView", params: { id: newClient.value.id } });
      }
    };

    return {
      cat,
      next,
      maxRule,
      handleNext,
      newClient,
    };
  },
};
</script>
  
<style>
.pointer {
  cursor: default;
}
</style>