<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <!-- Client detail -->
    <ClientDetailInsight
      :clientId="currClient.id"
      @reset="handleReset"
      v-if="currClient"
    />

    <!-- All -->
    <template v-else>
      <div class="row">
        <div
          class="col s12 m12 l6 yellow lighten-5 clickable"
          v-for="client in clients"
          :key="client.id"
          @click.prevent="handleDetail(client)"
        >
          <ul id="projects-collection" class="collection z-depth-4">
            <li class="collection-item avatar">
              <i class="material-icons cyan circle" v-if="mLr(client) < 50"
                >check_circle</i
              >
              <i class="material-icons red accent-2 circle" v-else>flag</i>
              <h6 class="collection-header m-0">
                {{ client.name }}
              </h6>
              <p class="chip grey lighten-4">{{ client.cat.toUpperCase() }}</p>
            </li>
            <li class="collection-item">
              <div class="row">
                <div class="col s9">
                  <p class="collections-title">
                    <strong>₦{{ invoicesTotal(client) }}</strong> Premium
                    <br />
                    <strong>₦{{ claimsTotal(client) }}</strong> Claims
                  </p>
                </div>
                <div class="col s3">
                  <span
                    class="spacer pointer blue-grey lighten-3 white-text"
                    title="Policy ends"
                    >{{
                      new Date(client?.invoice_groups[0].end).toLocaleString(
                        undefined,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )
                    }}</span
                  >
                  <div class="progress pointer">
                    <div
                      class="determinate"
                      :class="{
                        'blue darken-1': mLr(client) == 0,
                        'green darken-1': mLr(client) > 0 && mLr(client) < 50,
                        'purple darken-1':
                          mLr(client) >= 50 && mLr(client) < 75,
                        'orange darken-1':
                          mLr(client) >= 75 && mLr(client) < 100,
                        'red darken-1': mLr(client) >= 100,
                      }"
                      :style="`width: ${mLr(client)}%`"
                      :title="`Utilization marker (${mLr(client)}%)`"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section center" v-if="!clients.length">
        <h6>No Client Report <em>found</em>...</h6>
      </div>

      <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
    </template>
  </template>
</template>
    
<script>
import { onMounted, ref, computed } from "vue";
import getItems from "@/composables/getItems";
import ClientDetailInsight from "@/components/insight/ClientDetailInsightComponent.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";

export default {
  name: "clientsInsightComponent",
  components: {
    ClientDetailInsight,
    LoadingDiv,
    PaginationDiv,
  },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currClient = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);

    // MLR
    const mLr = (client) => {
      let invoices = 0;
      let claims = 0;
      // sort
      client.invoice_groups.forEach((z) => {
        z.invoices.forEach((x) => {
          if (x.bill && x.bill_type === "i") {
            invoices = Number(invoices) + Number(x.bill);
          }
        });
      });
      client.invoice_groups.forEach((z) => {
        z.invoices.forEach((x) => {
          if (x.claims) {
            claims = Number(claims) + Number(x.claims);
          }
        });
      });

      // return
      if (!claims && invoices) {
        return 0;
      } else if (claims && !invoices) {
        return Math.round(claims);
      } else if (!claims && !invoices) {
        return 0;
      } else {
        return Math.round((claims / invoices) * 100);
      }
    };

    // total invoices
    const invoicesTotal = (client) => {
      let total = 0;
      client.invoice_groups.forEach((z) => {
        z.invoices.forEach((x) => {
          if (x.bill && x.bill_type === "i") {
            total = Number(total) + Number(x.bill);
          }
        });
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // total claims
    const claimsTotal = (client) => {
      let total = 0;
      client.invoice_groups.forEach((z) => {
        z.invoices.forEach((x) => {
          if (x.claims) {
            total = Number(total) + Number(x.claims);
          }
        });
      });
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "operator/insight/clients";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    });

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`); //
    };

    // handle detail
    const handleDetail = (val) => {
      // send back
      currClient.value = val;
    };

    // handle reset
    const handleReset = () => {
      // send back
      currClient.value = null;
    };

    // output results
    const clients = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handlePage,
      claimsTotal,
      invoicesTotal,
      mLr,
      handleDetail,
      handleReset,
      isReady,
      isLoading,
      error,
      currClient,
      clients,
      allPages,
    };
  },
};
</script>
    
<style scoped>
.spacer {
  padding: 2px;
}

.top-spacer {
  padding: 10px;
}

.pointer {
  cursor: default;
}

.clickable {
  cursor: pointer;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
    color: #000000;
    border: 1px solid #ddd;
    background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>