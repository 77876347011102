<template>
  <div class="card-panel yellow lighten-4">
    <div class="input-field">
      <label
        class="active"
        :class="{
          'orange-text': Number(points) > Number(userPlanPoints),
          'teal-text': !(Number(points) > Number(userPlanPoints)),
        }"
        for="provider"
        v-if="plan"
        >{{ plan }} Provider</label
      >
      <label class="active teal-text" for="provider" v-else
        >Select Provider</label
      >
      <FilterSimpleDiv
        inputId="filterProvider"
        fieldName=""
        :data="filterProviderData"
        :search="true"
        @selected="handlePick"
        @searching="handleSearch"
      />
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, computed } from "vue";
import getItems from "@/composables/getItems";
import FilterSimpleDiv from "@/components/search/FilterSimpleDiv.vue";

export default {
  name: "ProviderSelectTPAComponent",
  components: { FilterSimpleDiv },
  emits: ["update"],
  props: ["userPlanPoints"],
  setup(props, context) {
    const responseSearch = ref(null);
    const selectedItem = ref(null);
    const selectedProvider = ref(null);
    const plan = ref(null);
    const points = ref(null);
    const error = ref(null);

    // Provider
    const filterProviderData = computed(() => {
      // start
      let val = null;

      // continue
      let valPlus;

      if (responseSearch.value) {
        if (responseSearch.value.key == "filterProvider") {
          valPlus = responseSearch.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // search function
    const getSearchItemsFromApi = (table, key) => {
      (async () => {
        const { res } = await getItems(table);
        if (res.value) {
          // search
          responseSearch.value = { key: key, value: res.value.data };
        }
      })();
    };

    // filter components where search is set to "true"
    const handleSearch = (val) => {
      if (val.id == "filterProvider") {
        // get new serach items
        getSearchItemsFromApi(`provider/filter?q=${val.value}`, val.id);
      }
    };

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;

      // clear provider plan && points
      plan.value = null;
      points.value = null;

      // clear choice
      context.emit("update", null);

      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterProvider") {
          //set
          if (!selectedItem.value.value) {
            selectedProvider.value = null;
          } else {
            responseSearch.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                selectedProvider.value = { name: x.name, search: x.id };

                // send provider choice
                context.emit("update", x.id);

                // big protector (in case responseSearch is from dummy)
                if ("schedule" in x) {
                  plan.value = x.schedule.plan.name;
                  if (x.schedule.plan.points.length) {
                    points.value = x.schedule.plan.points[0].amount;
                  } else {
                    //set to an impossible number
                    points.value = 1000000000000000;
                  }
                }
              }
            });
          }
        }
      }
    });

    return {
      filterProviderData,
      handleSearch,
      handlePick,
      error,
      points,
      plan,
    };
  },
};
</script>

<style>
</style>