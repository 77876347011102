import { ref } from 'vue';
import { adminAPI } from '@/laravel/config';

const getItemsOfItemsOfItems = async (table, id1, id2) => {
    const res = ref(null);
    const err = ref(null);

    // clean ups
    // table = encodeURIComponent(table);
    // id1 = encodeURIComponent(id1);
    // id2 = encodeURIComponent(id2);

    // start
    try {
        const response = await fetch(adminAPI + `/${table}` + `/${id1}` + `/${id2}`);
        if (!response.ok) {
            throw new Error(`Network response was not OK: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        res.value = data;
    } catch (error) {
        err.value = error;
    }

    return { res, err };
};

export default getItemsOfItemsOfItems;