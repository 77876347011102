<template>
  <div class="container">
    <LoadingDiv v-if="!isReady" />

    <template v-else>
      <div class="card-content">
        <a href="" @click.prevent="handleBack">
          <i class="medium material-icons blue-grey-text text-darken-2 right"
            >check_circle</i
          >
        </a>
        <h3 class="header blue-grey-text text-darken-2" v-if="!currTab">
          <small>Supervisor</small>
        </h3>
        <span v-else>
          <h3 class="header blue-grey-text text-darken-2">
            <a href="" @click.prevent="handleBack"
              ><i class="small material-icons" title="Go Back"
                >subdirectory_arrow_left</i
              ></a
            >
            <small>
              {{ handleSplit(currTab, "head") }}
            </small>
          </h3>
        </span>

        <h5 class="center" v-if="currTab">
          <span
            class="chip green lighten-4 black-text"
            v-if="handleSplit(currTab, 'head')"
          >
            {{ handleSplit(currTab, "head") }}
            <i class="tiny material-icons">check_circle</i></span
          >
          <span
            class="chip black white-text"
            v-if="handleSplit(currTab, 'body')"
          >
            {{ handleSplit(currTab, "body") }}</span
          >
        </h5>
      </div>

      <div class="section">
        <div class="card-panel" v-if="!currTab">
          <div
            v-for="tab in tabs"
            :key="tab.id"
            @click="handleActive(tab.title)"
          >
            <div class="card-dashboard grey-text text-darken-2">
              <i
                class="medium material-icons blue-text text-lighten-3 secondary-content"
                >chevron_right</i
              >
              <h5>{{ tab.title }}</h5>
            </div>
          </div>
        </div>

        <!-- Claims -->
        <ClaimsDiv v-if="currTab == 'Claims -> Pending Unit Review'" />
      </div>
    </template>
  </div>
</template>
      
<script>
import { onMounted, ref } from "vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import ClaimsDiv from "@/components/supervisor/SupervisorClaimsComponent.vue";

export default {
  name: "SupervisorHome",
  components: {
    LoadingDiv,
    ClaimsDiv,
  },
  setup() {
    const isReady = ref(true);
    const error = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // start
    onMounted(() => {
      // page name
      document.title = "Supervisor | EasyHMO";
    });

    // handle back
    const handleBack = () => {
      // restart menu
      currTab.value = null;
    };

    // handle split
    const handleSplit = (val, type) => {
      let newVal;
      let parts;
      //set
      if (val.includes("->")) {
        parts = val.split("->").map((s) => s.trim());
      } else {
        parts = [val, null];
      }
      // close
      if (type == "head") {
        newVal = parts[0];
      } else if (type == "body") {
        newVal = parts[1];
      }
      // return
      return ` ${newVal} `;
    };

    // Initiate
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, title: "Claims -> Pending Unit Review" });
      // set default
      currTab.value = null;
    });

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currTab.value = tab;
    };

    return {
      handleSplit,
      handleActive,
      handleBack,
      isReady,
      error,
      currTab,
      tabs,
    };
  },
};
</script>
      
      <style scoped>
/* from general class */
.card-dashboard:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  background-color: #ebf5fb;
  color: #000000 !important;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
      color: #000000;
      border: 1px solid #ddd;
      background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>