<template class="grey lighten-3">
  <template v-if="showFooter">
    <p><br /><br /><br /><br /></p>

    <footer class="page-footer grey lighten-3">
      <!-- <div class="container">
        <div class="row grey-text text-darken-1">
          <div class="col l6 s12">
            <h5 class="">About Us</h5>
            <ul>
              <li>
                One common flaw we've seen in many frameworks is a lack of
                support for truly responsive text. While elements on the page
                resize fluidly, text still resizes on a fixed basis.
              </li>
            </ul>
          </div>
          <div class="col l4 offset-l2 s12">
            <h5>Contacts Us</h5>
            <ul>
              <li><i class="material-icons">local_phone</i> 08078731191</li>
              <li>
                <i class="material-icons">email</i>
                customercare@lifeworthhmo.com
              </li>
              <li><i class="material-icons">web</i> www.lifeworthHMO.com</li>
              <li>
                <i class="material-icons">place</i> 175 Ikorodu Road,
                Palmgroove, Lagos
              </li>
            </ul>
          </div>
        </div>
      </div> -->
      <div class="footer-copyright">
        <div class="container black-text">
          © 2024 <a class="black-text" href="https://easyhmo.com.ng/" target="_blank">easyHMO.com.ng</a>
          <!-- <a class="grey-text text-lighten-4 right" href="#!">More Links</a> -->
        </div>
      </div>
    </footer>
  </template>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "FooterComponent",
  setup() {
    // route
    const route = useRoute();

    // display
    const showFooter = computed(() => {
      if (route.path.includes("setup")) {
        return false;
      } else if (route.path.includes("login")) {
        return false;
      } else if (route.path.includes("utility")) {
        return false;
      } else {
        return true;
      }
    });

    return {
      showFooter,
    };
  },
};
</script>

<style>
</style>