<template>
  <!-- Loader -->
  <div class="progress" v-if="isLoading">
    <div class="indeterminate"></div>
  </div>

  <!-- Display -->
  <div class="card">
    <div class="card-panel" v-for="benefit in benefits" :key="benefit.id">
      <div class="row">
        <div class="col s12">
          <h6>
            {{ benefit.item }}
            <span class="secondary-content chip">{{
              benefit.description
            }}</span>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";

export default {
  name: "ShowBenefitComponent",
  props: ["id"],
  setup(props) {
    const isLoading = ref(true);
    const response = ref(null);
    const error = ref(null);

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // end loading
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      getItemsFromApi("plan", props.id);
    });

    // output results
    const benefits = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.benefits;
      }
    });

    return {
      isLoading,
      error,
      benefits,
    };
  },
};
</script>
  
<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>