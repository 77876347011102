<template>
  <div class="container">
    <div class="container">
      <!-- Loading -->
      <LoadingDiv v-if="!isReady" />

      <!-- Edit -->
      <EnrolleeEditDiv
        :enrollee="enrollee"
        @reset="handleReset"
        v-if="enrollee"
        class="up"
      />

      <!-- List -->
      <template v-else>
        <ul class="collection with-header up">
          <li class="collection-header" v-if="principals.length">
            <h4>{{ principals[0]?.client.name }}</h4>
          </li>
          <li
            class="collection-item avatar"
            v-for="principal in principals"
            :key="principal.id"
          >
            <img
              :src="`https://ui-avatars.com/api/?name=${principal.name}&background=random&color=FFFFFF&size=128`"
              width="64"
              class="circle responsive-img left"
            />
            <span class="title">{{ principal.name }}</span>
            <p>{{ principal.type == "i" ? "Individual" : "Family" }}</p>
            <a
              href=""
              class="secondary-content"
              @click.prevent="handleFillForm(principal)"
              ><i class="material-icons">edit</i></a
            >
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";
import LoadingDiv from "@/components/LoadingDiv.vue";
import EnrolleeEditDiv from "@/components/client/onboard/EnrolleeEditOnboardComponent.vue";
export default {
  name: "utilityHome",
  components: {
    LoadingDiv,
    EnrolleeEditDiv,
  },
  props: ["clientId"],
  setup(props) {
    const response = ref(null);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const enrollee = ref(null);
    const error = ref(null);

    // title
    onMounted(() => {
      // page name
      document.title = "New Principals | EasyHMO";
    });

    // get function
    const getSearchItemsFromApi = (table, match) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, match);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // dropdown
    onMounted(() => {
      // get items from function
      getList();
    });

    const getList = () => {
      // reset
      isReady.value = false;
      // get list
      getSearchItemsFromApi(
        "principal/client/new/registration",
        props.clientId
      );
    };

    // handle form
    const handleFillForm = (val) => {
      enrollee.value = val;
    };

    // handle
    const handleReset = () => {
      // clear first
      enrollee.value = null;
      // get items from function
      getList();
    };

    // all principals
    const principals = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleFillForm,
      handleReset,
      isReady,
      isLoading,
      isSubmitting,
      enrollee,
      principals,
    };
  },
};
</script>

<style scoped>
.up {
  margin-top: -15px;
}
</style>