<template>
  <div class="row">
    <div class="col s12">
    <p class="center"><img src="@/assets/chart/pie-chart.png" /></p>
    </div>
  </div>
</template>
            
<script>
export default {
  name: "profitHome",
  setup() {},
};
</script>
            
<style>
</style>
    