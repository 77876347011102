<template>
  <!-- Header -->
  <nav class="grey-bg-custom">
    <div class="nav-wrapper container teal">
      <div class="row">
        <div class="col s2">
          <span class="breadcrumb chip teal-text"><b>easyHMO Setup</b></span>
        </div>
        <div class="col s10">
          <span
            v-for="(step, index) in steps"
            :key="step.id"
            @click.prevent="handleActive(step.id)"
          >
            <a
              href="#"
              class="grey-text text-darken-1"
              :class="{
                'chip white': step.id != currStep && !step.isComplete,
                'chip yellow lighten-2':
                  step.id == currStep && !step.isComplete,
                'chip green lighten-4': step.isComplete,
              }"
              >{{ index + 1 }}. {{ step.title }}</a
            >
          </span>
        </div>
      </div>
    </div>
  </nav>
  <!-- End Header -->

  <!-- Setups pages -->
  <HmoSetup v-if="currStep == 1" @next="handleNext" />
  <SchemeSetup v-if="currStep == 2" @next="handleNext" />
  <PlanSetup v-if="currStep == 3" @next="handleNext" />
  <TariffSetup v-if="currStep == 4" @next="handleNext" />
  <ProviderSetup v-if="currStep == 5" @next="handleNext" />
  <SettingSetup v-if="currStep == 6" @next="handleNext" />
  <FinalSetup v-if="currStep == 7" @next="handleNext" />
</template>

<script>
import { onMounted, ref } from "vue";
// import { useRouter } from "vue-router";
import HmoSetup from "@/components/setup/HmoSetupComponent.vue";
import SchemeSetup from "@/components/setup/SchemeSetupComponent.vue";
import PlanSetup from "@/components/setup/PlanSetupComponent.vue";
import TariffSetup from "@/components/setup/TariffSetupComponent.vue";
import ProviderSetup from "@/components/setup/ProviderSetupComponent.vue";
import SettingSetup from "@/components/setup/SettingSetupComponent.vue";
import FinalSetup from "@/components/setup/FinalSetupComponent.vue";


export default {
  name: "setupHome",
  components: { HmoSetup, SchemeSetup, PlanSetup, TariffSetup, ProviderSetup, SettingSetup, FinalSetup },
  setup() {
    // Workmen
    const error = ref(null);
    const isSubmitting = ref(false);
    const isReady = ref(false);
    const steps = ref([]);
    const currStep = ref(null);

    // router
    // const router = useRouter();

    onMounted(() => {
      // page name
      document.title = "Get Started (Setup) | EasyHMO";
    });

    // Initiate
    onMounted(() => {
      // load tab
      steps.value.push({ id: 1, title: "HMO", isComplete: false });
      steps.value.push({ id: 2, title: "Schemes", isComplete: false });
      steps.value.push({ id: 3, title: "Plans", isComplete: false });
      steps.value.push({ id: 4, title: "Tariffs", isComplete: false });
      steps.value.push({ id: 5, title: "Providers", isComplete: false });
      steps.value.push({ id: 6, title: "Settings", isComplete: false });
      steps.value.push({ id: 7, title: "Finish", isComplete: false });

      // set default
      currStep.value = 1;
    });

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currStep.value = tab;
    };

    // handle next // emitted parameters
    const handleNext = (tab) => {
      // update steps
      steps.value.forEach((x) => {
        // sort and update
        if (x.id == tab.step) {
          x.isComplete = tab.isComplete;
        }
      });
      // next available stap
      let next = null;
      steps.value.forEach((x) => {
        // sort and update
        if (!x.isComplete && !next) {
          next = x.id;
        }
      });
      // update show and components
      currStep.value = next;
    };


    return {
      handleActive,
      handleNext,
      error,
      isReady,
      isSubmitting,
      steps,
      currStep,
    };
  },
};
</script>

<style scoped>
.grey-bg-custom {
  background-color: #f2f2f2;
}
.hover-text {
  cursor: pointer;
}
</style>