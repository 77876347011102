<template>
  <div class="section">
    <div class="card">
      <!-- main  -->
      <div class="card">
        <div class="card-content white">
          <table class="highlight responsive-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name<br /><small>(Enrollee ID)</small></th>
                <th>Diagnosis</th>
                <th>Sent Amount</th>
                <th>Encounter</th>
                <th></th>
              </tr>
            </thead>
            <tbody>

              <!-- Encounters -->
              <tr
                class="clickable"
                v-for="(encounter, index) in encounters"
                :key="encounter.id"
                @click.prevent="handleStart(encounter)"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{ encounter.name }}<br />
                  ({{ encounter.enrollee_number }})
                </td>
                <td>{{ encounter.diagnosis }}</td>
                <td>
                  <b>₦{{ totalSent(encounter.claims) }}</b>
                </td>
                <td>
                  {{
                    new Date(encounter.encounter_date).toLocaleString(
                      undefined,
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )
                  }}
                </td>
                <td>
                  <i
                    class="material-icons pointable"
                    :class="{
                      'yellow-text text-darken-2': encounter.status == 'p',
                    }"
                    >{{
                      encounter.status == "p" ? "watch_later" : "check_circle"
                    }}
                  </i>
                </td>
              </tr>

              <!-- No encounters yet -->
              <tr v-if="!encounters.length">
                <td colspan="6" class="center">No Claims Added Yet!</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "SummaryClaimComponent",
  props: ["encounters"],
  emits: ["reload"],
  setup(props, context) {
    // total calculations
    const totalSent = (claims) => {
      if (claims) {
        let total = 0;
        claims.forEach((x) => {
          if (x.amt) {
            total = Number(total) + Number(x.amt);
          }
        });
        return total
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    };

    // switch page
    const handleStart = (encounter) => {
      // reload
      context.emit("reload", {
            encounter: encounter,
          });
    };

    return {
      handleStart,
      totalSent,
    };
  },
};
</script>
  
<style scoped>
.custom-hr {
  width: 50%; /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc; /* Adjust the color and style as needed */
  margin: 20px 20px; /* Adjust margin as needed */
}

.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}

.styled-text {
  background-color: #fafad2; /* Light Golden Yellow */
  /* border: 2px solid #000; Black border */
  padding: 2px; /* Adequate padding */
}

.capsule {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #f0f0f0; /* Adjust the background color as needed */
}

.clickable {
  cursor: pointer;
}

.pointable {
  cursor: default;
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8; /* Slightly reduced opacity on hover */
}
</style>