<template>
  <div class="container">
    <LoadingDiv v-if="!isReady" />

    <template v-else>
      <div class="card-content">
        <router-link :to="{ name: 'private' }">
          <i class="medium material-icons blue-grey-text text-darken-2 right"
            >business</i
          >
        </router-link>
        <h3 class="header blue-grey-text text-darken-2">
          <a href="" @click.prevent="handleBack"
            ><i class="small material-icons" title="Go Back"
              >subdirectory_arrow_left</i
            ></a
          >

          <small>
            <span
              class="tooltipped pointer"
              data-position="bottom"
              :data-tooltip="client.name"
              v-if="client && 'name' in client"
              >{{ maxRule(client.name) }}</span
            >
            <span v-else> Client... </span>
          </small>
        </h3>

        <h5 class="center" v-if="currTab">
          <span
            class="chip green lighten-4 black-text"
            v-if="handleSplit(currTab, 'head')"
          >
            {{ handleSplit(currTab, "head") }}
            <i class="tiny material-icons">check_circle</i></span
          >
          <span
            class="chip black white-text"
            v-if="handleSplit(currTab, 'body')"
          >
            {{ handleSplit(currTab, "body") }}</span
          >
        </h5>
      </div>

      <div class="section">
        <div class="card-panel" v-if="!currTab">
          <div
            v-for="tab in tabs"
            :key="tab.id"
            @click="handleActive(tab.title)"
          >
            <div class="card-dashboard grey-text text-darken-2">
              <i
                class="medium material-icons blue-text text-lighten-3 secondary-content"
                >chevron_right</i
              >
              <h5>{{ tab.title }}</h5>
            </div>
          </div>
        </div>

      <!-- Contract -->
      <ContractDiv :clientId="clientId" v-if="currTab == 'Client Contract'" />

      <!-- Status -->
      <StatusDiv :clientId="clientId" v-if="currTab == 'Status'" />

      <!-- Settings -->
      <SettingsDiv :clientId="clientId" v-if="currTab == 'Settings'" />

      <!-- Profile -->
      <ProfileDiv
        :client="client"
        @update="handleUpdate"
        v-if="currTab == 'Profile'"
      />

      <!-- Policy -->
      <PolicyDiv
        :client="client"
        @reset="handlePolicyReset"
        v-if="currTab == 'Plans -> All'"
      />

      <!-- Policy Add -->
      <PolicyAddDiv
        :client="client"
        @reset="handlePolicyAdd"
        v-if="currTab == 'Plans -> Add'"
      />

      <!-- Enrollees Lobby -->
      <EnrolleesDiv
        :clientId="clientId"
        :displayType="handleSplitForDisplay(currTab)"
        v-if="currTab == 'Principals -> Lobby'"
      />

      <!-- Enrollees Active -->
      <EnrolleesDiv
        :clientId="clientId"
        :displayType="handleSplitForDisplay(currTab)"
        v-if="currTab == 'Principals & Dependants -> Active'"
      />

      <!-- Enrollees On-Hold -->
      <EnrolleesDiv
        :clientId="clientId"
        :displayType="handleSplitForDisplay(currTab)"
        v-if="currTab == 'Principals & Dependants -> (On-hold)'"
      />

      <!-- Enrollees Inactive -->
      <EnrolleesDiv
        :clientId="clientId"
        :displayType="handleSplitForDisplay(currTab)"
        v-if="currTab == 'Principals & Dependants -> Bin'"
      />

      <!-- Enrollees Add -->
      <EnrolleeAddDiv
        :clientId="clientId"
        v-if="currTab == 'Principals -> Add'"
      />

      <!-- Enrollees Remove -->
      <EnrolleeRemoveDiv v-if="currTab == 'Principals -> Remove'" />

      <!-- Enrollees Download -->
      <EnrolleeDownloadDiv
        :clientId="clientId"
        v-if="currTab == 'Downloader'"
      />

      <!-- Payment -->
      <PaymentDiv :clientId="clientId" v-if="currTab == 'Invoices -> All'" />
      
      </div>
    </template>
  </div>
</template>

<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getItem from "@/composables/getItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import ContractDiv from "@/components/client/ContractClientComponent.vue";
import StatusDiv from "@/components/client/StatusClientComponent.vue";
import SettingsDiv from "@/components/client/SettingsClientComponent.vue";
import EnrolleesDiv from "@/components/client/EnrolleeClientComponent.vue";

import EnrolleeAddDiv from "@/components/client/onboard/EnrolleeOnboardComponent.vue";
import EnrolleeRemoveDiv from "@/components/client/onboard/EnrolleeRemoveOnboardComponent.vue";
import EnrolleeDownloadDiv from "@/components/client/download/EnrolleeDownloadComponent.vue";

import ProfileDiv from "@/components/client/ProfileClientComponent.vue";
import PolicyDiv from "@/components/client/PolicyClientComponent.vue";
import PolicyAddDiv from "@/components/client/onboard/PolicyReviewAddOnboardComponent.vue";
import PaymentDiv from "@/components/client/PaymentClientComponent.vue";
import M from "materialize-css";

export default {
  name: "clientView",
  components: {
    LoadingDiv,
    ContractDiv,
    SettingsDiv,
    StatusDiv,
    ProfileDiv,
    PolicyDiv,
    PolicyAddDiv,
    EnrolleesDiv,
    EnrolleeAddDiv,
    EnrolleeRemoveDiv,
    EnrolleeDownloadDiv,
    PaymentDiv,
  },
  props: ["id"],
  setup(props) {
    const isReady = ref(false);
    const response = ref(null);
    const error = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);
    const clientId = ref(props.id);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "View Single Client | EasyHMO";
    });

    // Get client
    onMounted(() => {
      // get client details
      getClient();
    });

    // get client
    const getClient = () => {
      (async () => {
        const { res, err } = await getItem("client", props.id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // UI modification
    const maxRule = (text) => {
      let maxLength = 15;
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      } else {
        return `${text} `;
      }
    };

    // handle add
    const handlePolicyAdd = (val) => {
      if (val) {
        // reload
        response.value = val;
        currTab.value = "Plans -> All"; // go back to Plans
      } else {
        currTab.value = null; // restart menu
      }
    };

    // handle reet
    const handlePolicyReset = (val) => {
      if (val) {
        // reload
        response.value = val;
      }
    };

    // handle back
    const handleBack = () => {
      if (!currTab.value) {
        router.push({ name: "client" }); // final back
      } else {
        currTab.value = null; // restart menu
      }
    };

    // handle split
    const handleSplit = (val, type) => {
      let newVal;
      let parts;
      //set
      if (val.includes("->")) {
        parts = val.split("->").map((s) => s.trim());
      } else {
        parts = [val, null];
      }
      // close
      if (type == "head") {
        newVal = parts[0];
      } else if (type == "body") {
        newVal = parts[1];
      }
      // return
      return newVal;
    };

    // handle split
    const handleSplitForDisplay = (val) => {
      let newVal;
      let parts;
      //set
      if (val.includes("->")) {
        parts = val.split("->").map((s) => s.trim());
        // close
        if (parts[1].toLowerCase() == "active") {
          newVal = "a";
        } else if (parts[1].toLowerCase() == "(on-hold)") {
          newVal = "h";
        } else if (parts[1].toLowerCase() == "bin") {
          newVal = "d";
        } else if (parts[1].toLowerCase() == "lobby") {
          newVal = "p";
        }
      } else {
        newVal = "a";
      }
      // return
      return newVal;
    };

    // Title
    watchEffect(() => {
      if (response.value) {
        // load title
        setTimeout(() => {
          var elems = document.querySelectorAll(".tooltipped");
          M.Tooltip.init(elems, {});
        }, 2000);
      }
    });

    // Initiate
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, title: "Status" });
      tabs.value.push({ id: 2, title: "Client Contract" });
      // tabs.value.push({ id: 3, title: "Principals -> Lobby" });
      tabs.value.push({ id: 4, title: "Principals & Dependants -> Active" });
      tabs.value.push({ id: 5, title: "Principals & Dependants -> (On-hold)" });
      tabs.value.push({ id: 6, title: "Principals & Dependants -> Bin" });
      tabs.value.push({ id: 7, title: "Principals -> Add" });
      tabs.value.push({ id: 8, title: "Principals -> Remove" });
      tabs.value.push({ id: 9, title: "Plans -> All" });
      // tabs.value.push({ id: 10, title: "Plans -> Add" });
      // tabs.value.push({ id: 11, title: "Invoices -> All" });
      tabs.value.push({ id: 12, title: "Downloader" });
      // tabs.value.push({ id: 13, title: "Profile" });
      // tabs.value.push({ id: 14, title: "Settings" });
      // set default
      currTab.value = null;
    });

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currTab.value = tab;
    };

    // handle emitted update
    const handleUpdate = (val) => {
      response.value = val;
    };

    // output
    const client = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      maxRule,
      handleSplit,
      handleSplitForDisplay,
      handleActive,
      handleUpdate,
      handleBack,
      handlePolicyAdd,
      handlePolicyReset,
      isReady,
      error,
      currTab,
      tabs,
      clientId,
      client,
    };
  },
};
</script>

<style scoped>
/* from general class */
.card-dashboard:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  background-color: #ebf5fb;
  color: #000000 !important;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
color: #000000;
border: 1px solid #ddd;
background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>