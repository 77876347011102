<template>
  <form @submit.prevent="handleSubmit">
    <div class="card-panel teal-text">
      <div class="input-field">
        <label
          class="active"
          :class="{
            'orange-text':
              'plan' in user &&
              user.plan.points.length &&
              Number(points) > Number(user.plan.points[0].amount),
            'teal-text': !(
              'plan' in user &&
              user.plan.points.length &&
              Number(points) > Number(user.plan.points[0].amount)
            ),
          }"
          for="provider"
          v-if="plan"
          >{{ plan }} Provider</label
        >
        <label class="active" for="provider" v-else>Select Provider</label>
        <FilterSimpleDiv
          inputId="filterProvider"
          fieldName=""
          :data="filterProviderData"
          :search="isSearchOn"
          :placeHolder="placeHolder"
          @selected="handlePick"
          @searching="handleSearch"
        />
      </div>
    </div>

    <div class="card-content">
      <p>
        <br />
        <a
          class="waves-effect waves-light btn-large black"
          :class="{
            disabled: isSubmitting,
          }"
          @click="handleBack"
        >
          <i class="material-icons left">chevron_left</i>Back
        </a>
        <button
          class="waves-effect waves-light btn-large"
          :class="{
            disabled: isSubmitting || !plan,
          }"
        >
          <i class="material-icons right">check_circle</i
          ><span
            v-if="
              'plan' in user &&
              user.plan.points.length &&
              points > user.plan.points[0].amount
            "
          >
            {{ isSubmitting ? "Updating" : "Approval by (Stella)" }}
          </span>
          <span v-else>
            {{ isSubmitting ? "Updating" : "Update" }}
          </span>
        </button>
      </p>
    </div>
  </form>
</template>
          
<script>
import { onMounted, onUpdated, ref, computed, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import putItem from "@/composables/putItem";
import FilterSimpleDiv from "@/components/search/FilterSimpleDiv.vue";
import M from "materialize-css";

export default {
  name: "EnrolleeProviderSpouseOnboardComponent",
  components: { FilterSimpleDiv },
  props: ["user", "principal"],
  emits: ["reset"],
  setup(props, context) {
    const isSubmitting = ref(false);
    const error = ref(null);
    const placeHolder = ref(null);
    const isSearchOn = ref(true);
    const responseSearch = ref(null);
    const selectedItem = ref(null);
    const selectedProvider = ref(null);
    const plan = ref(null);
    const points = ref(null);

    // Provider
    const filterProviderData = computed(() => {
      // start
      let val = null;

      // continue
      let valPlus;

      if (responseSearch.value) {
        if (responseSearch.value.key == "filterProvider") {
          valPlus = responseSearch.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // set default
    onMounted(() => {
      if ("provider" in props.user && props.user.provider) {
        // kill search
        isSearchOn.value = false;
        // load dummy (default) responseSearch
        placeHolder.value = props.user.provider.name;
      }
    });

    // select
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // select
    onUpdated(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // search function
    const getSearchItemsFromApi = (table, key) => {
      (async () => {
        const { res } = await getItems(table);
        if (res.value) {
          // search
          responseSearch.value = { key: key, value: res.value.data };
        }
      })();
    };

    // handle back
    const handleBack = () => {
      context.emit("reset", null);
    };

    // filter components where search is set to "true"
    const handleSearch = (val) => {
      if (val.id == "filterProvider") {
        // get new serach items
        getSearchItemsFromApi(`provider/filter?q=${val.value}`, val.id);
      }
    };

    // handle submit
    const handleSubmit = async () => {
      // selected provider must not be null
      if (selectedProvider.value) {
        // start
        isSubmitting.value = true;

        // round up
        const newVal = {
          scope: "spouse",
          provider_id: selectedProvider.value.search, // dependant will use this id
          principal_id: props.principal.id,
        };

        // post item
        const { res, err } = await putItem(
          "operator/client/enrollee/provider/update",
          props.user.id,
          newVal
        );

        if (res.value) {
          // start
          let spouse;
          let principal = props.principal;
          // next
          spouse = {
            ...props.user,
            ...res.value.data,
          };

          // merge with principal
          principal.spouse = spouse;

          // go back
          context.emit("reset", principal);
        } else {
          error.value = err.value;
        }

        // show button back
        isSubmitting.value = false;
      }
    };

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;

      // clear provider plan && points
      plan.value = null;
      points.value = null;

      // turn search back on
      isSearchOn.value = true;

      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterProvider") {
          //set
          if (!selectedItem.value.value) {
            selectedProvider.value = null;
          } else {
            responseSearch.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                selectedProvider.value = { name: x.name, search: x.id };
                plan.value = x.schedule.plan.name;
                if (x.schedule.plan.points.length) {
                  points.value = x.schedule.plan.points[0].amount;
                } else {
                  //set to an impossible number
                  points.value = 1000000000000000;
                }
              }
            });
          }
        }
      }
    });

    return {
      isSubmitting,
      error,
      handleSubmit,
      handleSearch,
      handlePick,
      handleBack,
      isSearchOn,
      placeHolder,
      plan,
      points,
      selectedItem,
      filterProviderData,
      selectedProvider,
    };
  },
};
</script>
            
<style>
</style>