import { ref } from 'vue';

/**
 * Grand function to get formatted date details
 * for the current, next, and previous 11 months.
 */

// Function to get full month name
function getFullMonthName(monthIndex) {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthIndex];
}

// Function to format a date object to "MM/DD/YYYY"
function formatDateTime(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}


/**
 * Exportable functions
 * starts here!
 */

// active
const endDate = (dateString) => {
    const fx = ref(null);

    // Parse the input date string
    const [year, month, day] = dateString.split('-').map(Number);

    // Create a Date object for the given date
    const date = new Date(year, month - 1, day);

    // Get the end date of the month (last day of the month)
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // Format the end date as "YYY-MM-DD"
    const formattedEndDate = [
        endDate.getFullYear(),
        String(endDate.getMonth() + 1).padStart(2, '0'),
        String(endDate.getDate()).padStart(2, '0'),
    ].join('-');

    // final
    fx.value = formattedEndDate;

    // return
    return { fx };
};

// active
const activeMonths = () => {
    const fx = ref(null);

    // Get the current date from day 1
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    // Get the current month and year
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Calculate the next month and handle the year transition
    const nextMonthIndex = (currentMonthIndex + 1) % 12;
    const nextYear = currentMonthIndex === 11 ? currentYear + 1 : currentYear;

    // Get the full month names
    const currentMonthName = getFullMonthName(currentMonthIndex);
    const nextMonthName = getFullMonthName(nextMonthIndex);

    // Format the output for this month and next month
    const thisMonth = `${currentMonthName}, ${currentYear}`;
    const nextMonth = `${nextMonthName}, ${nextYear}`;

    // Create Date objects for the first day of the current and next months
    const nextMonthDate = new Date(nextYear, nextMonthIndex, 1);

    // Format datetime values
    const thisMonthDateStart = formatDateTime(currentDate);
    const nextMonthDateStart = formatDateTime(nextMonthDate);

    // final
    fx.value = { thisMonth: thisMonth, nextMonth: nextMonth, thisMonthDate: thisMonthDateStart, nextMonthDate: nextMonthDateStart };

    // return
    return { fx };
};

// active
const pastMonths = () => {
    const fx = ref(null);

    // Get the current date from day 1
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    // Get the current month and year
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Generate array for the previous 11 months
    const pastMonths = [];
    for (let i = 1; i <= 11; i++) {
        const pastDate = new Date(currentYear, currentMonthIndex - i, 1);
        const pastMonthIndex = pastDate.getMonth();
        const pastMonthYear = pastDate.getFullYear();
        const pastMonthName = getFullMonthName(pastMonthIndex);
        const pastMonthStartDate = formatDateTime(new Date(pastMonthYear, pastMonthIndex, 1));

        pastMonths.push({
            month: `${pastMonthName}, ${pastMonthYear}`,
            date: pastMonthStartDate,
        });
    }

    // final
    fx.value = pastMonths;

    // return
    return { fx };
};

// end months
function generateEndMonths(pastMonthStartDate) {
    const fx = ref(null);

    // start function
    const startDate = new Date(pastMonthStartDate);
    const startYear = startDate.getFullYear();
    const startMonthIndex = startDate.getMonth();

    // Loop for 12 months (including the starting month)
    const durationMonths = [];
    for (let i = 0; i < 12; i++) {
        // Create a new date for each month
        const currentDate = new Date(startYear, startMonthIndex + i, 1);
        const currentMonthIndex = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        const pastMonthName = getFullMonthName(currentMonthIndex);
        const lastDayOfTheMonth = new Date(currentYear, currentMonthIndex + 1, 0).getDate();
        const durationMonthEndDate = formatDateTime(new Date(currentYear, currentMonthIndex, lastDayOfTheMonth));

        durationMonths.push({
            month: `${pastMonthName} ${lastDayOfTheMonth}, ${currentYear}`,
            date: durationMonthEndDate,
        });
    }

    // final
    fx.value = durationMonths;

    // return
    return { fx };
}

// search
const searchMonths = (nextMonth) => {
    const fx = ref(null);

    // Get the current date from day 1
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    // Get the current month and year
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Generate array for the previous 12 months including the current month
    const searchMonths = [];
    for (let i = 0; i <= 11; i++) {
        const pastDate = new Date(currentYear, currentMonthIndex - i, 1);
        const pastMonthIndex = pastDate.getMonth();
        const pastMonthYear = pastDate.getFullYear();
        const pastMonthName = getFullMonthName(pastMonthIndex);
        const pastMonthStartDate = formatDateTime(new Date(pastMonthYear, pastMonthIndex, 1));

        searchMonths.push({
            period: `${pastMonthName}, ${pastMonthYear}`,
            date: pastMonthStartDate,
        });
    }

    // Add the next month
    if (nextMonth) {
        const nextDate = new Date(currentYear, currentMonthIndex + 1, 1);
        const nextMonthIndex = nextDate.getMonth();
        const nextMonthYear = nextDate.getFullYear();
        const nextMonthName = getFullMonthName(nextMonthIndex);
        const nextMonthStartDate = formatDateTime(new Date(nextMonthYear, nextMonthIndex, 1));

        searchMonths.unshift({
            period: `${nextMonthName}, ${nextMonthYear}`,
            date: nextMonthStartDate,
        });
    }

    // final
    fx.value = searchMonths;

    // return
    return { fx };
};


export { endDate, activeMonths, pastMonths, searchMonths, generateEndMonths }