// import { ref } from 'vue';
import store from '@/store';

// Get Cache
const cacheGet = (url) => {
    let fx = null;
    let cache = store.state.cache;
    // find
    cache.forEach((x) => {
        if (x.url === url) {
            fx = x.payload;
        }
    })
    // return
    return fx;
};

// Push Cache
const cachePush = (url, payload) => {
    let cache = store.state.cache;
    let ok = false;
    // find
    cache.forEach((x) => {
        if (x.url === url) {
            x.payload = payload;
            ok = true;
        }
    })
    // add
    if (!ok) {
        cache.push({ url: url, payload: payload });
    }
    // update
    store.commit("loadCache", cache);
};


export {
    cacheGet,
    cachePush
}