import { ref } from 'vue';

// get title
const getTitleFX = (val) => {
    const fx = ref(null);
    // function
    if (val.status == "p") {
        fx.value = "Un-Vetted Claim";
    } else if (val.status == "d") {
        fx.value = "Completed (Rejected)";
    } else {
        fx.value = "Completed";
    }

    // final
    return { fx };
};

// total calculations (gross sent)
const grossTotalSentFX = (encounters) => {
    const fx = ref(null);
    // function
    let total = 0;
    encounters.forEach((z) => {
        z.claims.forEach((x) => {
            if (x.sent_price) {
                total = Number(total) + Number(x.sent_price);
            }
        });
    });
    fx.value = total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // final
    return { fx };
};

// total calculations (gross approved)
const grossTotalApprovedFX = (encounters, db) => {
    const fx = ref(null);
    // function
    let total = 0;
    encounters.forEach((z) => {
        z.claims.forEach((x) => {
            if (x.approved_price && x.status == "a") {
                // status has to be taken into acount for gross approved
                total = Number(total) + Number(x.approved_price);
            }
        });
    });

    if (!db) {
        fx.value = total
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        fx.value = total.toFixed(2);
    }

    // final
    return { fx };
};

// total calculations (sent)
const totalSentFX = (claims) => {
    const fx = ref(null);
    // function
    let total = 0;
    claims.forEach((x) => {
        if (x.sent_price) {
            total = Number(total) + Number(x.sent_price);
        }
    });
    fx.value = total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // final
    return { fx };
};

// total calculations (approved)
const totalApprovedFX = (claims) => {
    const fx = ref(null);
    // function
    let total = 0;
    claims.forEach((x) => {
        if (x.approved_price) {
            total = Number(total) + Number(x.approved_price);
        }
    });
    fx.value = total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // final
    return { fx };
};

// payment status
const checkPaidFX = (payment, repayment) => {
    const fx = ref(null);
    // function
    let val = false;

    if (payment) {
      if (payment.stage == "c") {
        val = true;
      }
    }

    if (repayment) {
      if (repayment.stage == "c") {
        val = true;
      }
    }

    fx.value = val;
    
    // final
    return { fx };
  };

// payment status
const paymentStatusFX = (status, payment, repayment, level) => {
    const fx = ref(null);
    // function
    let val = "N/A";
    if (payment) {
        if (payment.stage === "p") {
            val = "Compliance";
        } else if (payment.stage == "q") {
            val = "Finance";
        } else if (payment.stage == "c") {
            val = `(Paid #${payment.id})`;
        } else if (payment.stage == "z") {
            val = "Completed (Gov)";
        }
    } else {
        if (status === "v" && level < 5) {
            val = "Supervisor";
        } else if (status === "v" && level === 5) {
            val = "HOD";
        }
    }

    if (repayment) {
        if (repayment.stage == "p") {
            val = `(Batched #${repayment.id})`;
        } else if (repayment.stage == "q") {
            val = `(Queued #${repayment.id})`;
        } else if (repayment.stage == "c") {
            val = `(Paid #${repayment.id})`;
        }
    } else {
        if (status === "r" && level < 5) {
            val = "Supervisor";
        } else if (status === "r" && level === 5) {
            val = "HOD";
        }
    }

    fx.value = val;

    // final
    return { fx };
};

// total calculations (clustered approved)
const batchTotalApprovedFX = (batch) => {
    const fx = ref(null);
    // function
    let total = 0;
    batch.clusters.forEach((b) => {
      b.encounters.forEach((e) => {
        e.claims.forEach((c) => {
          if (c.approved_price && c.status == "a" && e.status != "d") {
            total = Number(total) + Number(c.approved_price);
          }
        });
      });
    });
    fx.value = total
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // final
    return { fx };
  };

  // total calculations (clustered approved)
  const clustersTotalApprovedFX = (clusters) => {
    const fx = ref(null);
    // function
    let total = 0;
    clusters.forEach((b) => {
      b.encounters.forEach((e) => {
        e.claims.forEach((c) => {
          if (c.approved_price && c.status == "a" && e.status != "d") {
            total = Number(total) + Number(c.approved_price);
          }
        });
      });
    });
    fx.value = total
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // final
    return { fx };
  };

export {
    getTitleFX,
    grossTotalSentFX,
    grossTotalApprovedFX,
    totalSentFX,
    totalApprovedFX,
    checkPaidFX,
    paymentStatusFX,
    batchTotalApprovedFX,
    clustersTotalApprovedFX,
}



