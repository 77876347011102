<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div class="row">
      <div class="col s12">
        <form @submit.prevent="handleSubmit">
          <div class="section">
            <div class="card grey lighten-4">
              <div class="card-content">
                <span class="card-title teal-text"
                  ><span class="chip">6/6</span> Settings<i
                    class="material-icons right"
                    >check_circle</i
                  ></span
                >
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="familySize"
                    type="number"
                    v-model="familySize"
                    class="validate"
                    required
                  />
                  <label class="active" for="familySize"
                    >Family Size (total)</label
                  >
                </div>
                <span class="chip"
                  >Default: Principal, Spouse and 4 Dependants: Total (6)
                  members</span
                >
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="prinipalAge"
                    type="number"
                    v-model="principalAge"
                    class="validate"
                    required
                  />
                  <label class="active" for="prinipalAge"
                    >Principal Age (Max)</label
                  >
                </div>
                <span class="chip"
                  >Default: Principal and Spouse: Maximum (60) years</span
                >
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="dependantAge"
                    type="number"
                    v-model="dependantAge"
                    class="validate"
                    required
                  />
                  <label class="active" for="dependantAge"
                    >Dependant Age (Max)</label
                  >
                </div>
                <span class="chip">Default: Dependant: Maximum (18) years</span>
              </div>
            </div>
          </div>

          <br />

          <div class="section center">
            <button
              class="waves-effect waves-light btn-large"
              :class="{
                disabled: isSubmitting,
              }"
            >
              <i class="material-icons white-text right">check_circle</i
              >{{ isSubmitting ? `Finalizing...` : "Submit" }}
            </button>
          </div>
        </form>

        <p><br /><br /></p>
      </div>
    </div>
  </template>
</template>
  
<script>
import { onMounted, ref } from "vue";
import getItems from "@/composables/getItems";
import postItem from "@/composables/postItem";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "HmoComponent",
  components: { LoadingDiv },
  props: ["cat", "newClient"],
  emits: ["next"],
  setup(props, context) {
    const response = ref([]);
    const error = ref(null);
    const isSubmitting = ref(false);
    const isReady = ref(false);

    // Rules table
    const premiumCycle = ref(null);
    const familySize = ref(null);
    const principalAge = ref(null);
    const dependantAge = ref(null);

    // start here
    onMounted(() => {
      // get items
      (async () => {
        const { res, err } = await getItems(`rules?q=${props.newClient.id}`);

        if (res.value) {
          // get
          response.value = res.value.data;

          // set
          response.value.forEach((x) => {
            if (x.setting == "familySize" && familySize.value == null) {
              // set
              familySize.value = x.value;
            }

            if (x.setting == "principalAge" && principalAge.value == null) {
              // set
              principalAge.value = x.value;
            }

            if (x.setting == "dependantAge" && dependantAge.value == null) {
              // set
              dependantAge.value = x.value;
            }
          });

          // is now Ready
          isReady.value = true;
        } else {
          error.value = err.value;
        }
      })();
    });

    // submit
    const handleSubmit = async () => {
      // start
      isSubmitting.value = true;
      error.value = false;

      // Materialize CSS handled all custome errors \\
      const newVal = [
        {
          setting: "familySize",
          value: familySize.value,
          scope: props.newClient.id, // get client id from props
        },
        {
          setting: "principalAge",
          value: principalAge.value,
          scope: props.newClient.id, // get client id from props
        },
        {
          setting: "dependantAge",
          value: dependantAge.value,
          scope: props.newClient.id, // get client id from props
        },
      ];

      // post item
      const { res, err } = await postItem("rules", newVal);

      if (res.value) {
        // next
        // emit new parameters
        context.emit("next", {
          id: "settings",
          value: true,
        });
      } else {
        error.value = err.value;
      }

      // end here
      isSubmitting.value = false;
    };

    return {
      error,
      isReady,
      isSubmitting,
      handleSubmit,
      premiumCycle,
      familySize,
      principalAge,
      dependantAge,
    };
  },
};
</script>
  
  <style scoped>
.grey-bg-custom {
  background-color: #f2f2f2;
}
.clickable {
  cursor: pointer;
}
</style>