<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div class="grey-bg-custom">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <!-- <h1 class="teal-text">
              <i class="medium material-icons">filter_6</i> Settings
            </h1> -->

            <form @submit.prevent="handleSubmit">
              <div class="section">
                <h5 class="grey-text text-darken-1">Premium Settings</h5>
                <br />
                <div class="card-panel">
                  <div class="input-field">
                    <input
                      id="premiumCycle"
                      type="number"
                      v-model="premiumCycle"
                      class="validate"
                      required
                    />
                    <label
                      class="active teal-text text-darken-2"
                      for="premiumCycle"
                      >Payment Cycle (Months)</label
                    >
                  </div>
                </div>
              </div>

              <div class="section">
                <h5 class="grey-text text-darken-1">Principals & Dependants</h5>
                <br />
                <div class="card-panel">
                  <div class="input-field">
                    <input
                      id="familySize"
                      type="number"
                      v-model="familySize"
                      class="validate"
                      required
                    />
                    <label
                      class="active teal-text text-darken-2"
                      for="familySize"
                      >Family Size (total)</label
                    >
                  </div>
                </div>

                <div class="card-panel">
                  <div class="input-field">
                    <input
                      id="prinipalAge"
                      type="number"
                      v-model="principalAge"
                      class="validate"
                      required
                    />
                    <label
                      class="active teal-text text-darken-4"
                      for="prinipalAge"
                      >Principal Age (Max)</label
                    >
                  </div>
                </div>

                <div class="card-panel">
                  <div class="input-field">
                    <input
                      id="dependantAge"
                      type="number"
                      v-model="dependantAge"
                      class="validate"
                      required
                    />
                    <label
                      class="active teal-text text-darken-4"
                      for="dependantAge"
                      >Dependant Age (Max)</label
                    >
                  </div>
                </div>
              </div>

              <br />
              <div class="section">
                <button
                  class="waves-effect waves-light btn-large right"
                  :class="{
                    disabled: isSubmitting,
                  }"
                >
                  <i class="material-icons white-text right">call_made</i
                  >{{ isSubmitting ? `Updating...` : "Submit" }}
                </button>
              </div>
            </form>

            <p><br /><br /></p>
          </div>
        </div>
      </div>

      <!-- <footer class="page-footer grey lighten-3">
        <div class="footer-copyright">
          <div class="container black-text">© 2024 easyHMO.com.ng</div>
        </div>
      </footer> -->

    </div>
  </template>
</template>
  
<script>
import { onMounted, ref } from "vue";
import getItems from "@/composables/getItems";
import postItem from "@/composables/postItem";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "HmoComponent",
  components: { LoadingDiv },
  emits: ["next"],
  setup(props, context) {
    // Workmen
    const response = ref([]);
    const error = ref(null);
    const isSubmitting = ref(false);
    const isReady = ref(false);

    // Rules table
    const premiumCycle = ref(null);
    const familySize = ref(null);
    const principalAge = ref(null);
    const dependantAge = ref(null);

    // start here
    onMounted(() => {
      // get items
      (async () => {
        const { res, err } = await getItems("rules");

        if (res.value) {
          // get
          response.value = res.value.data;

          // set
          response.value.forEach((x) => {
            if (x.setting == "premiumCycle" && premiumCycle.value == null) {
              // set
              premiumCycle.value = x.value;
            }

            if (x.setting == "familySize" && familySize.value == null) {
              // set
              familySize.value = x.value;
            }

            if (x.setting == "principalAge" && principalAge.value == null) {
              // set
              principalAge.value = x.value;
            }

            if (x.setting == "dependantAge" && dependantAge.value == null) {
              // set
              dependantAge.value = x.value;
            }
          });

          // is now Ready
          isReady.value = true;
        } else {
          error.value = err.value;
        }
      })();
    });

    // submit
    const handleSubmit = async () => {
      // start
      isSubmitting.value = true;
      error.value = false;

      // Materialize CSS handled all custome errors \\
      const newVal = [
        {
          setting: "premiumCycle",
          value: premiumCycle.value,
          scope: 0,
        },
        {
          setting: "familySize",
          value: familySize.value,
          scope: 0,
        },
        {
          setting: "principalAge",
          value: principalAge.value,
          scope: 0,
        },
        {
          setting: "dependantAge",
          value: dependantAge.value,
          scope: 0,
        },
      ];

      // post item
      const { res, err } = await postItem("rules", newVal);

      if (res.value) {
        // next
        // emit new parameters
        context.emit("next", {
          step: 6,
          isComplete: true,
        });
      } else {
        error.value = err.value;
      }

      // end here
      isSubmitting.value = false;
    };

    return {
      error,
      isReady,
      isSubmitting,
      handleSubmit,
      premiumCycle,
      familySize,
      principalAge,
      dependantAge,
    };
  },
};
</script>
  
  <style scoped>
.grey-bg-custom {
  background-color: #f2f2f2;
}
.clickable {
  cursor: pointer;
}
</style>