<template>
  <div class="center" v-if="success">
    <h6 class="teal-text darken-5 chip">
      {{ success }} <i class="material-icons">check</i>
    </h6>
  </div>


  <div class="row">
    <div class="col s12">
      <div class="section">
        <div class="card grey lighten-4">
          <!-- Select Policy -->
          <div class="card-panel teal-text">
            <div class="row">
              <div class="col s12">
                <span class="black-text">Policy Type</span>
              </div>
            </div>
            <div class="row">
              <div
                class="col s12"
                :class="{ 'red lighten-5': error && !selectedPlan }"
              >
                <div class="input-field">
                  <FilterSimpleDiv
                    inputId="filterPlan"
                    fieldName="Select Policy"
                    :clear="resetPlan"
                    :data="filterPlanData"
                    :search="false"
                    @selected="handlePick"
                    @clear="handleClear"
                  />
                  <!-- Loader -->
                  <div class="progress" v-if="isLoading">
                    <div class="indeterminate"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="selectedPlan">
            <!-- Premium -->
            <div class="card-panel teal-text" v-if="cat != 'tpa'">
              <div class="row">
                <div class="col s12">
                  <span class="black-text">Premiums</span>
                </div>
              </div>
              <div class="row">
                <div class="col s1"><span class="chip">₦</span></div>
                <div
                  class="col s4"
                  :class="{
                    'red lighten-5': error && !premiumIndividual,
                  }"
                >
                  <div class="input-field">
                    <input
                      type="number"
                      id="premiumIndividual"
                      v-model="premiumIndividual"
                      class="validate"
                      required
                    />
                    <label class="active" for="premiumIndividual"
                      >Premium (Individual)</label
                    >
                  </div>
                </div>

                <div class="col s1"></div>

                <div class="col s1"><span class="chip">₦</span></div>
                <div
                  class="col s4"
                  :class="{ 'red lighten-5': error && !premiumFamily }"
                >
                  <div class="input-field">
                    <input
                      type="number"
                      id="premiumFamily"
                      v-model="premiumFamily"
                      class="validate"
                      required
                    />
                    <label class="active" for="premiumFamily"
                      >Premium (Family)</label
                    >
                  </div>
                </div>

                <div class="col s1"></div>
              </div>
            </div>

            <!-- Custom Benefits -->
            <template
              v-if="
                premiumIndividual != checkIndividual ||
                premiumFamily != checkFamily || cat == 'tpa'
              "
            >
              <!-- Benefits -->
              <div class="card-panel teal-text">
                <div class="row">
                  <!-- <div class="col s12">
                    <span class="black-text"
                      >{{ selectedPlan.name }} (Customized) Plan</span
                    >
                  </div> -->
                </div>
                <div class="row">
                  <div class="col s12">
                    <div class="file-field input-field">
                      <div class="btn grey darken-5">
                        <span>Add Benefits</span>
                        <input type="file" @change="handleFile" />
                      </div>
                      <div class="file-path-wrapper">
                        <input class="file-path validate" type="text" />
                        <p class="red-text" v-if="fileError">
                          {{ fileError }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>

      <div class="section center">
        <p>
          <button
            class="waves-effect waves-light btn-large black"
            :class="{
              disabled: isSubmitting,
            }"
            @click="handleBack"
          >
            <i class="material-icons left">chevron_left</i>Back
          </button>

          <button
            class="waves-effect waves-light btn-large teal"
            :class="{
              disabled: isSubmitting || !selectedPlan,
            }"
            @click="handleAdd"
          >
            <i class="material-icons right">check_circle</i>
            {{
              isSubmitting && selectedPlan
                ? `Adding Plan (${uploadProgress})`
                : "Add New"
            }}
          </button>
        </p>
      </div>
    </div>
  </div>

</template>
    
<script>
import { ref, onMounted, computed, watchEffect } from "vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import getItemsOfItems from "@/composables/getItemsOfItems";
import useStorage from "@/composables/useStorage";
import postItem from "@/composables/postItem";
import FilterSimpleDiv from "@/components/search/FilterSimpleDiv.vue";

export default {
  name: "PolicyReviewAddOnboardComponent",
  components: { FilterSimpleDiv },
  props: ["cat", "client"],
  emits: ["reset"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isLoading = ref(true);
    const isSubmitting = ref(false);
    const success = ref(null);
    const error = ref(null);
    const responsePlanDefault = ref(null);
    const selectedItem = ref(null);
    const selectedPlan = ref(null);
    const resetPlan = ref(false);
    const premiumIndividual = ref(null);
    const premiumFamily = ref(null);
    const checkIndividual = ref(null);
    const checkFamily = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // toMoney
    const toMoney = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // Plan
    const filterPlanData = computed(() => {
      // start
      let val = {
        "Plans Loading...": null,
      };

      let valPlus;

      if (responsePlanDefault.value) {
        if (responsePlanDefault.value.key == "filterPlan") {
          valPlus = responsePlanDefault.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...valPlus };
        }
      }

      // return final
      return val;
    });

    // search function
    const getSearchItemsFromApi = (table, id, key) => {
      (async () => {
        const { res } = await getItemsOfItems(table, id);
        if (res.value) {
          // search
          responsePlanDefault.value = { key: key, value: res.value.data };
        }
        // end
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // get unselected plans
      unSelctedPlans();
    });

    const unSelctedPlans = () => {
      // get policies
      isLoading.value = true;
      // get unselected Plans
      getSearchItemsFromApi(
        "plan/client/plans/unselected",
        props.client.id,
        "filterPlan"
      );
    };

    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    // handle add
    const clear = () => {
      // clear files
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      uploadProgress.value = "0%";
      uploadError.value = null;
      data.value = null;
      completed.value = null;
      // null all
      selectedItem.value = null;
      selectedPlan.value = null;
      premiumIndividual.value = null;
      premiumFamily.value = null;
      // clear component
      resetPlan.value = true;
      // clear error
      error.value = false;
      // clear loading
      isLoading.value = false;
      // clear submitting
      isSubmitting.value = false;
    };

    // handle back
    const handleBack = () => {
      context.emit("reset", null);
    };

    // handle add
    const handleAdd = async () => {
      if (
        selectedPlan.value &&
        premiumIndividual.value &&
        premiumFamily.value
      ) {
        // Start Uploading
        isSubmitting.value = true;

        // zero premiums for tpa cat
        if (props.cat == "tpa") {
          premiumIndividual.value = 0;
          premiumFamily.value = 0;
        }

        // initiate for update
        let plan = null;
        let individual = null;
        let family = null;
        let scope = props.client.id; // get client id from props

        // new plan
        plan = {
          id: selectedPlan.value.plan_id,
        };

        // update individual premium
        if (checkIndividual.value != premiumIndividual.value) {
          individual = {
            plan_id: selectedPlan.value.plan_id, // (confirmation on server)
            amount: premiumIndividual.value,
            scope: scope,
            type: "i",
          };
        }

        // update family premium
        if (checkFamily.value != premiumFamily.value) {
          family = {
            plan_id: selectedPlan.value.plan_id, // (confirmation on server)
            amount: premiumFamily.value,
            scope: scope,
            type: "f",
          };
        }

        // File (Benefits)
        if (file.value) {
          // Start Uploading
          uploadError.value = null;

          // Cloud Function
          const fx = "planLoader";

          // round up
          const cloudInput = {
            plan: plan,
            individual: individual,
            family: family,
            scope: scope,
            action: "client", // new, update, client
          };

          // upload
          uploadFile(file.value, fx, cloudInput);
        } else {
          // update without File
          // (without File)

          // Start Uploading
          uploadProgress.value = "100%";

          // round up
          const newVal = {
            plan: plan,
            individual: individual,
            family: family,
            scope: scope,
            action: "client", // new, update, client
            benefits: [], // empty array (no Benefit change required)
          };

          // post item
          const { res, err } = await postItem("plan/benefits/bulk", newVal);

          if (res.value) {
            // load
            unSelctedPlans();
            // success
            success.value = `${selectedPlan.value.name} has been added!`;
            // reset client main
            // handle reset
            context.emit("reset", res.value.data);
            // restart
            clear();
          } else {
            error.value = err.value;
            isSubmitting.value = false;
          }
        }
      } else {
        error.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        // load
        unSelctedPlans();
        // success
        success.value = `${selectedPlan.value.name} has been added! ${data.value.succ.inserted_rows} Benefits items also added.`;
        // reset client main
        // handle reset
        context.emit("reset", data.value.succ.data);
        // restart
        clear();
      }
      if (uploadError.value) {
        // restart
        // clear();
        // stop
        error.value = uploadError.value;
        isSubmitting.value = false;
      }
    });

    // clear (null reset)
    const handleClear = (val) => {
      if (val.id == "filterPlan") {
        resetPlan.value = val.value;
      }
    };

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;

      // chek for reset condition
      if (selectedItem.value.id == "filterPlan" && val.value == null) {
        // clear every here
        clear();

        // reload if Plan is empty
        if (!responsePlanDefault.value) {
          unSelctedPlans();
        }
      }

      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterPlan") {
          //set
          if (!selectedItem.value.value) {
            selectedPlan.value = null;
          } else {
            responsePlanDefault.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                premiumIndividual.value = x.premiums[0].amount;
                premiumFamily.value = x.premiums[1].amount;
                checkIndividual.value = x.premiums[0].amount;
                checkFamily.value = x.premiums[1].amount;
                // load
                selectedPlan.value = {
                  id: Date.now(),
                  star: x.id,
                  plan_id: x.id,
                  scheme_id: x.scheme_id,
                  name: x.name,
                  desc: x.description,
                };
              }
            });
          }
        }
      }
    });

    return {
      isLoading,
      isSubmitting,
      error,
      success,
      handleBack,
      handleAdd,
      handleClear,
      handlePick,
      handleFile,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      toMoney,
      selectedItem,
      filterPlanData,
      selectedPlan,
      resetPlan,
      premiumIndividual,
      premiumFamily,
      checkIndividual,
      checkFamily,
    };
  },
};
</script>
    
<style scoped>
.progress {
  margin-top: -10px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>