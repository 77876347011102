<template>
  <LoadingDiv v-if="isLoading" />

  <template v-else>
    <div class="grey-bg-custom">
      <div class="container">
        <div class="row">
          <div class="col s12">
            <!-- <h1 class="teal-text">
              <i class="medium material-icons">filter_3</i> PLANs
            </h1> -->

            <div class="section">
              <h5 class="header">
                HMO <b>Plans </b>
                <span @click="next = !next">
                  <span
                    class="btn-floating btn-large waves-effect waves-light"
                    :class="{ red: !next, teal: next }"
                    ><i class="material-icons" title="Add Scheme (New)">{{
                      next ? "cancel" : "add"
                    }}</i></span
                  >
                </span>
              </h5>
            </div>

            <template v-if="!next">
              <PolicyEditDiv
                :policy="editPolicy"
                @complete="handleUpdateComplete"
                v-if="editPolicy"
              />

              <template v-else>
                <!-- Selected -->
                <div
                  class="card-panel"
                  v-for="(plan, id) in plans"
                  :key="plan.id"
                >
                  <div
                    class="row"
                    :class="{ 'orange lighten-4': plan.status == 's' }"
                  >
                    <div class="col s1">
                      <span class="chip grey darken-2 white-text">{{
                        id + 1
                      }}</span>
                    </div>
                    <div class="col s9">
                      <h5>
                        {{ plan.name }}
                      </h5>
                      <p
                        class="chip green lighten-4"
                        v-if="plan.scheme.type === 'p'"
                      >
                        {{ plan.description }}
                      </p>
                      <p
                        class="chip indigo lighten-4"
                        v-if="plan.scheme.type === 's'"
                      >
                        {{ plan.description }}
                      </p>
                      <h6>Individual: ₦{{ toMoney(plan.premiums, "i") }}</h6>
                      <h6>Family: ₦{{ toMoney(plan.premiums, "f") }}</h6>
                      <p>
                        (<b>Benefits</b>) {{ plan.benefits.length }} line items
                        defined
                        <i
                          class="material-icons clickable"
                          :class="{
                            'red-text': showBenefit == plan.id,
                            'teal-text': !(showBenefit == plan.id),
                          }"
                          title="view all"
                          @click="showBenefits(plan)"
                          >{{
                            showBenefit == plan.id ? "cancel" : "visibility"
                          }}</i
                        >
                      </p>
                    </div>
                    <div class="col s2">
                      <div class="right">
                        <a
                          class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2"
                          ><i
                            class="material-icons"
                            title="edit plan"
                            @click="handleEdit(plan)"
                            >edit</i
                          ></a
                        >
                      </div>
                      <p><br /></p>
                      <div class="right" v-if="plan.scheme.type === 'p'">
                        <a
                          class="btn-floating btn-medium waves-effect waves-light"
                          :class="{
                            'red lighten-2': plan.status == 'a',
                            'green lighten-2': plan.status == 's',
                          }"
                          ><i
                            class="material-icons"
                            :title="
                              plan.status == 'a'
                                ? 'suspend plan'
                                : 'activate plan'
                            "
                            @click="handleStatus(plan)"
                            >{{
                              plan.status == "a"
                                ? "pause_circle_outline"
                                : "play_circle_filled"
                            }}</i
                          ></a
                        >
                      </div>
                    </div>
                  </div>

                  <div class="row teal lighten-5" v-if="showBenefit == plan.id">
                    <span
                      v-for="(benefit, y) in plan.benefits"
                      :key="benefit.id"
                    >
                      <div class="col s1"><br /></div>
                      <div class="col s11">
                        <h6>
                          {{ y + 1 }}. {{ benefit.item }}
                          <span
                            class="secondary-content black white-text chip"
                            >{{ benefit.description }}</span
                          >
                        </h6>
                      </div>
                    </span>
                  </div>
                </div>

                <!-- Empty -->
                <div v-if="!plans.length">
                  <span class="chip">No Plan added</span>
                </div>
              </template>
            </template>

            <template v-else>
              <div class="section">
                <div class="card grey lighten-4">
                  <!-- Heading -->
                  <div class="card-content">
                    <span class="card-title teal-text"
                      >Add Shelf Policy<i
                        class="material-icons right"
                        v-if="plans.length"
                        >check_circle</i
                      ></span
                    >
                  </div>

                  <!-- Register Policy -->
                  <div class="card-panel teal-text">
                    <div class="row">
                      <div class="col s12">
                        <span class="black-text">Policy Name</span>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col s12"
                        :class="{ 'red lighten-5': error && !policyName }"
                      >
                        <div class="input-field">
                          <input
                            type="text"
                            id="policyName"
                            v-model="policyName"
                          />
                          <label for="policyName">Policy</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Premium -->
                  <div class="card-panel teal-text">
                    <div class="row">
                      <div class="col s12">
                        <span class="black-text">Premiums</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col s1"><span class="chip">₦</span></div>
                      <div
                        class="col s4"
                        :class="{
                          'red lighten-5': error && !premiumIndividual,
                        }"
                      >
                        <div class="input-field">
                          <input
                            type="number"
                            id="premiumIndividual"
                            v-model="premiumIndividual"
                            class="validate"
                            required
                          />
                          <label class="active" for="premiumIndividual"
                            >Premium (Individual)</label
                          >
                        </div>
                      </div>

                      <div class="col s1"></div>

                      <div class="col s1"><span class="chip">₦</span></div>
                      <div
                        class="col s4"
                        :class="{ 'red lighten-5': error && !premiumFamily }"
                      >
                        <div class="input-field">
                          <input
                            type="number"
                            id="premiumFamily"
                            v-model="premiumFamily"
                            class="validate"
                            required
                          />
                          <label class="active" for="premiumFamily"
                            >Premium (Family)</label
                          >
                        </div>
                      </div>

                      <div class="col s1"></div>
                    </div>
                  </div>

                  <!-- Benefits -->
                  <div class="card-panel teal-text">
                    <div class="row">
                      <div class="col s12">
                        <span class="black-text">Plan</span>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col s12"
                        :class="{
                          'red lighten-5': error && !file,
                        }"
                      >
                        <div class="file-field input-field">
                          <div class="btn" :class="{ disabled: isSubmitting }">
                            <span>Benefits</span>
                            <input type="file" @change="handleFile" />
                          </div>
                          <div class="file-path-wrapper">
                            <input
                              class="file-path"
                              v-model="inputFile"
                              type="text"
                            />
                            <p class="red-text" v-if="fileError">
                              {{ fileError }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="section center">
                <p>
                  <a
                    class="waves-effect waves-light btn-large grey"
                    :class="{
                      disabled: isSubmitting,
                    }"
                    @click="handleBack"
                  >
                    <i class="material-icons left">chevron_left</i>Back
                  </a>
                  <button
                    class="waves-effect waves-light btn-large black"
                    :class="{
                      disabled: isSubmitting,
                    }"
                    @click="handleAdd"
                  >
                    <i class="material-icons left">add</i>
                    {{ isSubmitting ? `Adding (${uploadProgress})` : "Add" }}
                  </button>
                </p>
              </div>
            </template>

            <template v-if="!next && !editPolicy">
              <div class="section center">
                <p>
                  <br /><br /><br /><br />
                  <button
                    class="waves-effect waves-light btn-large"
                    @click="handleNext"
                  >
                    <i class="material-icons right">call_made</i>Submit
                  </button>
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- <footer class="page-footer grey lighten-3">
        <div class="footer-copyright">
          <div class="container black-text">© 2024 easyHMO.com.ng</div>
        </div>
      </footer> -->
    </div>
  </template>
</template>
    
<script>
import { ref, onMounted, computed, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import useStorage from "@/composables/useStorage";
import putItem from "@/composables/putItem";
import PolicyEditDiv from "@/components/client/onboard/PolicyEditOnboardComponent.vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "PlanSetupComponent",
  components: { PolicyEditDiv, LoadingDiv },
  emits: ["next"],
  setup(props, context) {
    const response = ref(null);
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const error = ref(null);
    const next = ref(false);
    const policyName = ref(null);
    const premiumIndividual = ref(null);
    const premiumFamily = ref(null);
    const showBenefit = ref(null);
    const editPolicy = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // search function
    const getItemsFromApi = (table) => {
      (async () => {
        // start
        isLoading.value = true;

        const { res } = await getItems(table);
        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = "Plans couldn't load!";
        }

        // end
        isLoading.value = false;
      })();
    };

    // start
    onMounted(() => {
      // page name
      getItemsFromApi("plan/setup");
    });

    // to naira
    const toMoney = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // show benefits
    const showBenefits = (val) => {
      if (val.id != showBenefit.value) {
        showBenefit.value = val.id;
      } else {
        showBenefit.value = null;
      }
    };

    // edit policy
    const handleEdit = (val) => {
      if (val) {
        let newVal = val;
        let i = false;
        let f = false;
        val.premiums.forEach((x) => {
          if (x.type === "i" && !i) {
            newVal.premiumIndividual = x.amount;
            i = true;
          }
          if (x.type === "f" && !f) {
            newVal.premiumFamily = x.amount;
            f = true;
          }
        });
        editPolicy.value = newVal;
      }
    };

    // edit status
    const handleStatus = async (val) => {
      if (val?.scheme.type === "p") {
        // start
        isLoading.value = true;

        // value
        let newStatus;
        let planId = val.id;

        // new status
        if (val.status == "a") {
          newStatus = "s"; // update status
        } else if (val.status == "s") {
          newStatus = "a"; // update status
        }

        // update
        let newVal = {
          status: newStatus,
        };

        // post item
        const { res, err } = await putItem("plan", planId, newVal);

        if (res.value) {
          // grab new copy
          getItemsFromApi("plan/setup");
        } else {
          error.value = err.value;
        }

        // end
        isLoading.value = false;
      }
    };

    // handle update complete
    const handleUpdateComplete = (val) => {
      if (val.complete) {
        // grab new copy
        getItemsFromApi("plan/setup");
      }
      // release
      editPolicy.value = null;
    };

    // handle add
    const handleReset = () => {
      // clear files
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      uploadProgress.value = "0%";
      uploadError.value = null;
      data.value = null;
      completed.value = null;
      // null all
      policyName.value = null;
      premiumIndividual.value = null;
      premiumFamily.value = null;
      // next
      next.value = false;
      // clear error
      error.value = false;
      // clear loading
      isLoading.value = false;
      // clear submitting
      isSubmitting.value = false;
    };

    const handleBack = () => {
      // restart
      handleReset();
    };

    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    // handle add
    const handleAdd = async () => {
      if (
        file.value &&
        policyName.value &&
        premiumIndividual.value &&
        premiumFamily.value
      ) {
        // Benefits
        // Start Uploading
        isSubmitting.value = true;

        //start
        uploadError.value = null;

        // Cloud Function
        const fx = "planLoader";

        // setup
        // new plan
        const plan = {
          scheme_id: null, // update this
          name: policyName.value,
          description: "Standard Shelf Plan",
        };

        // individual premium
        const individual = {
          plan_id: null, // update this on server
          amount: premiumIndividual.value,
          type: "i",
        };

        // family premium
        const family = {
          plan_id: null, // update this on server
          amount: premiumFamily.value,
          type: "f",
        };

        // scope
        const scope = 0; // default

        // round up,
        const cloudInput = {
          plan: plan,
          individual: individual,
          family: family,
          scope: scope,
          action: "new",
        };

        // upload
        uploadFile(file.value, fx, cloudInput);
      } else {
        error.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        // grab new copy
        getItemsFromApi("plan/setup");
        // restart
        handleReset();
      }
      if (uploadError.value) {
        // restart
        // handleReset();
        // stop
        error.value = uploadError.value;
        isSubmitting.value = false;
      }
    });

    // handle submit
    const handleNext = () => {
      // next
      // emit new parameters
      context.emit("next", {
        step: 3,
        isComplete: true,
      });
    };

    // handle plan
    const plans = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      isLoading,
      isSubmitting,
      error,
      handleStatus,
      handleEdit,
      handleNext,
      handleFile,
      handleAdd,
      handleBack,
      handleUpdateComplete,
      showBenefits,
      toMoney,
      showBenefit,
      editPolicy,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      next,
      policyName,
      premiumIndividual,
      premiumFamily,
      plans,
    };
  },
};
</script>
    
<style>
.grey-bg-custom {
  background-color: #f2f2f2;
}
.hover-text {
  cursor: pointer;
}
.clickable {
  cursor: pointer;
}
</style>