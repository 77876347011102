<template>
  <div class="grey lighten-5">
  <Header />
  <main>
      <router-view />
  </main>
  <Footer />
</div>
</template>


<script>
import Header from "@/components/HeaderDiv.vue";
import Footer from "@/components/FooterDiv.vue";

export default {
  name: "App",
  components: { Header, Footer },
  setup() {},
};
</script>

<style>
  body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  main {
    flex: 1 0 auto;
  }
</style>