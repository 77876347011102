<template>
  <div class="section center">
    <ul class="pagination">
      <span v-for="page in pager" :key="page.id">
        <li
          :class="{
            'waves-effect': page.url,
            disabled: !page.url,
          }"
          v-if="page.label.includes('Previous')"
        >
          <a :href="`#${pagePrevious}`" @click.prevent="emitPage(pagePrevious)"
            ><i class="material-icons">chevron_left</i></a
          >
        </li>

        <li
          :class="{
            'waves-effect': !page.active,
            active: page.active,
          }"
          v-if="/\d/.test(page.label)"
        >
          <a :href="`#${page.label}`" @click.prevent="emitPage(page.label)">{{
            page.label
          }}</a>
        </li>

        <li
          :class="{
            'waves-effect': page.url,
            disabled: !page.url,
          }"
          v-if="page.label.includes('Next')"
        >
          <a :href="`#${pageNext}`" @click.prevent="emitPage(pageNext)"
            ><i class="material-icons">chevron_right</i></a
          >
        </li>
      </span>
    </ul>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import pageControl from "@/composables/pageControl";

export default {
  name: "PaginationComponent",
  props: ["payLoad"],
  setup(props, context) {
    const pager = ref(null);
    const pageNext = ref(null);
    const pagePrevious = ref(null);
    const pageCurr = ref(0);
    const pageSize = ref(0);
    const pageTotal = ref(0);

    watchEffect(() => {
      const {
        pageCurrPc,
        pageNextPc,
        pagePreviousPc,
        pageTotalPc,
        pageSizePc,
        pagerPc,
      } = pageControl(props.payLoad);
      if (pagerPc.value) {
        pageCurr.value = pageCurrPc.value;
        pageNext.value = pageNextPc.value;
        pagePrevious.value = pagePreviousPc.value;
        pageTotal.value = pageTotalPc.value;
        pageSize.value = pageSizePc.value;
        pager.value = pagerPc.value;
      }
    });

    const emitPage = (page) => {
      context.emit("newPage", page);
    };

    return {
      emitPage,
      pager,
      pageNext,
      pagePrevious,
      pageCurr,
      pageTotal,
    };
  },
};
</script>

<style>
</style>