<template>
  <div class="row">
    <div class="col s12">
      <form @submit.prevent="handleNext">
        <div class="section">
          <div class="card grey lighten-4">
            <div class="card-content">
              <span class="card-title teal-text"
                ><span class="chip">5/6</span> Status<i
                  class="material-icons right"
                  v-if="
                    selectedDurationMonth &&
                    selectedDurationYear &&
                    selectedInvoice
                  "
                  >check_circle</i
                ></span
              >
            </div>

            <div class="card-panel teal-text">
              <div class="row">
                <div class="col s12">
                  <span class="black-text">Scheme Start Date</span>
                </div>
              </div>
              <div class="row">
                <div class="col s7">
                  <div class="input-field">
                    <FilterSimpleDiv
                      inputId="filterDurationMonth"
                      fieldName="Select Month"
                      :data="filterDurationMonthData"
                      :search="false"
                      @selected="handlePick"
                    />
                  </div>
                </div>
                <div class="col s5">
                  <div class="input-field">
                    <FilterSimpleDiv
                      inputId="filterDurationYear"
                      fieldName="Select Year"
                      :data="filterDurationYearData"
                      :search="false"
                      @selected="handlePick"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-panel teal-text">
              <div class="row">
                <div class="col s12">
                  <span class="black-text">Invoicing Cycle</span>
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <div class="input-field">
                    <FilterSimpleDiv
                      inputId="filterInvoice"
                      fieldName="Select Cycle"
                      :data="filterInvoiceData"
                      :search="false"
                      @selected="handlePick"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section center">
          <p>
            <button
              class="waves-effect waves-light btn-large"
              :class="{
                disabled:
                  isSubmitting || 
                  !selectedDurationMonth || 
                  !selectedDurationYear || 
                  !selectedInvoice,
              }"
            >
              <i class="material-icons right">chevron_right</i
              >{{ isSubmitting ? "Updating Status" : "Next" }}
            </button>
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
        
  <script>
import { ref, computed, onMounted, watchEffect } from "vue";
import postItem from "@/composables/postItem";
import FilterSimpleDiv from "@/components/search/FilterSimpleDiv.vue";

export default {
  name: "StatusOnboardComponent",
  components: { FilterSimpleDiv },
  props: ["cat", "newClient"],
  emits: ["next"],
  setup(props, context) {
    const isSubmitting = ref(false);
    const error = ref(null);
    const responseDurationYearDefault = ref(null);
    const responseDurationMonthDefault = ref(null);
    const responseInvoiceDefault = ref(null);
    const selectedItem = ref(null);
    const selectedDurationYear = ref(null);
    const selectedDurationMonth = ref(null);
    const selectedInvoice = ref(null);

    // Duration (Month)
    const filterDurationMonthData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseDurationMonthDefault.value) {
        if (responseDurationMonthDefault.value.key == "filterDurationMonth") {
          valPlus = responseDurationMonthDefault.value.value.reduce(
            (newArr, x) => {
              newArr[x.period] = null;
              return newArr;
            },
            {}
          );
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // Duration (Year)
    const filterDurationYearData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseDurationYearDefault.value) {
        if (responseDurationYearDefault.value.key == "filterDurationYear") {
          valPlus = responseDurationYearDefault.value.value.reduce(
            (newArr, x) => {
              newArr[x.period] = null;
              return newArr;
            },
            {}
          );
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // Duration (Month)
    const filterInvoiceData = computed(() => {
      // start
      let val = null;

      let valPlus;

      if (responseInvoiceDefault.value) {
        if (responseInvoiceDefault.value.key == "filterInvoice") {
          valPlus = responseInvoiceDefault.value.value.reduce((newArr, x) => {
            newArr[x.period] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // filter (Initialize)
    onMounted(() => {
      // initialize filterDuration
      let val = [
        { period: "January", month: '01' },
        { period: "February", month: '02' },
        { period: "March", month: '03' },
        { period: "April", month: '04' },
        { period: "May", month: '05' },
        { period: "June", month: '06' },
        { period: "July", month: '07' },
        { period: "August", month: '08' },
        { period: "September", month: '09' },
        { period: "October", month: '10' },
        { period: "November", month: '11' },
        { period: "December", month: '12' },
      ];
      // set
      responseDurationMonthDefault.value = {
        key: "filterDurationMonth",
        value: val,
      };
    });

    // filter (Initialize)
    onMounted(() => {
      // initialize filterDuration
      let currentYear = new Date().getFullYear();

      let val = [
        { period: currentYear.toString(), year: currentYear },
        {
          period: `${(currentYear + 1).toString()} - Next Year`,
          year: currentYear + 1,
        },
      ];

      // set
      responseDurationYearDefault.value = {
        key: "filterDurationYear",
        value: val,
      };
    });

    // filter (Initialize)
    onMounted(() => {
      // initialize filterDuration
      let val = [
        { period: "Yearly", timing: 12 },
        { period: "Every 6 Months", timing: 6 },
        { period: "Every 3 Months", timing: 3 },
        { period: "Every 2 Months", timing: 2 },
        { period: "Monthly", timing: 1 },
      ];
      // set
      responseInvoiceDefault.value = {
        key: "filterInvoice",
        value: val,
      };
    });

    // handle netxt
    const handleNext = async () => {
      if (
        !selectedDurationMonth.value ||
        !selectedDurationYear.value ||
        !selectedInvoice.value
      ) {
        error.value = "All fields not selected"; // most unlikely
      } else {
        // start
        isSubmitting.value = true;
        error.value = false;

        // set timeformat
        let timeformat = `${selectedDurationYear.value.search}${selectedDurationMonth.value.search}01`;

        // round up
        const newVal = [
          {
            setting: "policyDate",
            value: timeformat,
            scope: props.newClient.id,
          },
          {
            setting: "premiumCycle",
            value: selectedInvoice.value.timing,
            scope: props.newClient.id,
          },
        ];

        // post item
        const { res, err } = await postItem("rules", newVal);

        if (res.value) {
          // next
          // emit new parameters
          context.emit("next", {
            id: "status",
            value: true,
          });
        } else {
          error.value = err.value;
        }
      }
    };

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;
      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterDurationMonth") {
          //set
          if (!selectedItem.value.value) {
            selectedDurationMonth.value = null;
          } else {
            responseDurationMonthDefault.value.value.forEach((x) => {
              if (x.period == selectedItem.value.value) {
                // found
                selectedDurationMonth.value = {
                  name: x.period,
                  search: x.month,
                };
              }
            });
          }
        } else if (selectedItem.value.id == "filterDurationYear") {
          //set
          if (!selectedItem.value.value) {
            selectedDurationYear.value = null;
          } else {
            responseDurationYearDefault.value.value.forEach((x) => {
              if (x.period == selectedItem.value.value) {
                // found
                selectedDurationYear.value = { name: x.period, search: x.year };
              }
            });
          }
        } else if (selectedItem.value.id == "filterInvoice") {
          //set
          if (!selectedItem.value.value) {
            selectedInvoice.value = null;
          } else {
            responseInvoiceDefault.value.value.forEach((x) => {
              if (x.period == selectedItem.value.value) {
                // found
                selectedInvoice.value = { name: x.period, timing: x.timing };
              }
            });
          }
        }
      }
    });

    return {
      isSubmitting,
      error,
      handleNext,
      handlePick,
      filterDurationMonthData,
      filterDurationYearData,
      filterInvoiceData,
      selectedDurationMonth,
      selectedDurationYear,
      selectedInvoice,
    };
  },
};
</script>
        
  <style>
</style>