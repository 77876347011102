<template>
  <form>
  <div class="card-panel" :class="{ 'yellow lighten-5': editId == 'name' }">
    <div class="input-field">
      <div class="row">
        <div
          :class="{ 'col s10': editId != 'name', 'col s12': editId == 'name' }"
        >
          <span v-if="editId != 'name'">
            <h5>{{ name }}</h5>
          </span>
          <input id="name" type="text" v-model="name" class="validate" required v-else />
          <label class="active" for="name">Client name</label>
        </div>
        <div class="col s2" v-if="editId != 'name'">
          <span>
            <a
              class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
              ><i
                class="material-icons"
                @click="handleEdit('name')"
                title="edit"
                >edit</i
              ></a
            >
          </span>
        </div>
        <div class="col s12 right" v-if="editId == 'name'">
          <button
            class="waves-effect waves-light btn black right"
            :class="{
              disabled: isSubmitting,
            }"
            @click.prevent="handleBack"
          >
            <i class="material-icons left" title="go back">chevron_left</i> Back
          </button>

          <button
            class="waves-effect waves-light btn right"
            :class="{
              disabled: isSubmitting,
            }"
            @click.prevent="handleSubmit('name', name)"
          >
            Update
            <i class="material-icons right" title="go back">check_circle</i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card-panel" :class="{ 'yellow lighten-5': editId == 'address' }">
    <div class="input-field">
      <div class="row">
        <div
          :class="{
            'col s10': editId != 'address',
            'col s12': editId == 'address',
          }"
        >
          <span v-if="editId != 'address'">
            <h6>{{ address }}</h6>
          </span>
          <input id="address" type="text" v-model="address" class="validate" required v-else />
          <label class="active" for="address">Client address</label>
        </div>
        <div class="col s2" v-if="editId != 'address'">
          <span>
            <a
              class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
              ><i
                class="material-icons"
                @click="handleEdit('address')"
                title="edit"
                >edit</i
              ></a
            >
          </span>
        </div>
        <div class="col s12 right" v-if="editId == 'address'">
          <button
            class="waves-effect waves-light btn black right"
            :class="{
              disabled: isSubmitting,
            }"
            @click.prevent="handleBack"
          >
            <i class="material-icons left" title="go back">chevron_left</i> Back
          </button>

          <button
            class="waves-effect waves-light btn right"
            :class="{
              disabled: isSubmitting,
            }"
            @click.prevent="handleSubmit('address', address)"
          >
            Update
            <i class="material-icons right" title="go back">check_circle</i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <template v-if="allowEmailChange">
    <div class="card-panel" :class="{ 'yellow lighten-5': editId == 'email' }">
    <div class="input-field">
      <div class="row">
        <div
          :class="{
            'col s10': editId != 'email',
            'col s12': editId == 'email',
          }"
        >
          <span v-if="editId != 'email'">
            <h6>{{ email }}</h6>
          </span>
          <input id="email" type="email" v-model="email" class="validate" required v-else />
          <label class="active" for="email">Client email</label>
        </div>
        <div class="col s2" v-if="editId != 'email'">
          <span>
            <a
              class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
              ><i
                class="material-icons"
                @click="handleEdit('email')"
                title="edit"
                >edit</i
              ></a
            >
          </span>
        </div>
        <div class="col s12 right" v-if="editId == 'email'">
          <button
            class="waves-effect waves-light btn black right"
            :class="{
              disabled: isSubmitting,
            }"
            @click.prevent="handleBack"
          >
            <i class="material-icons left" title="go back">chevron_left</i> Back
          </button>

          <button
            class="waves-effect waves-light btn right"
            :class="{
              disabled: isSubmitting,
            }"
            @click.prevent="handleSubmit('email', email)"
          >
            Update
            <i class="material-icons right" title="go back">check_circle</i>
          </button>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <template v-else>
    <div class="card-panel">
    <div class="input-field">
      <div class="row">
        <div class="col s10">
          <span>
            <h5>{{ email }}</h5>
          </span>
          <label class="active" for="email">Client Email</label>
        </div>
        <div class="col s2">
          <span>
            <span
              class="btn-floating btn-medium waves-effect waves-light grey pointer right"
              ><i class="material-icons" title="locked">lock</i></span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  </template>

  <div class="card-panel" :class="{ 'yellow lighten-5': editId == 'phone' }">
    <div class="input-field">
      <div class="row">
        <div
          :class="{
            'col s10': editId != 'phone',
            'col s12': editId == 'phone',
          }"
        >
          <span v-if="editId != 'phone'">
            <h6>{{ phone }}</h6>
          </span>
          <input id="phone" type="text" v-model="phone" class="validate" pattern="[0-9]{10,13}" required v-else />
          <label class="active" for="phone">Client phone</label>
        </div>
        <div class="col s2" v-if="editId != 'phone'">
          <span>
            <a
              class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
              ><i
                class="material-icons"
                @click="handleEdit('phone')"
                title="edit"
                >edit</i
              ></a
            >
          </span>
        </div>
        <div class="col s12 right" v-if="editId == 'phone'">
          <button
            class="waves-effect waves-light btn black right"
            :class="{
              disabled: isSubmitting,
            }"
            @click.prevent="handleBack"
          >
            <i class="material-icons left" title="go back">chevron_left</i> Back
          </button>

          <button
            class="waves-effect waves-light btn right"
            :class="{
              disabled: isSubmitting,
            }"
            @click.prevent="handleSubmit('phone', phone)"
          >
            Update
            <i class="material-icons right" title="go back">check_circle</i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="card-panel">
    <div class="input-field">
      <div class="row">
        <div class="col s10">
          <span>
            <h5>{{ cat == 'tpa' ? 'TPA' : scheme }}</h5>
          </span>
          <label class="active" for="scheme">Applicable Scheme</label>
        </div>
        <div class="col s2">
          <span>
            <span
              class="btn-floating btn-medium waves-effect waves-light grey pointer right"
              ><i class="material-icons" title="locked">lock</i></span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { ref, onMounted } from "vue";
import putItem from "@/composables/putItem";

export default {
  name: "ProfileClientComponent",
  props: ["cat", "client"],
  emits: ["update"],
  setup(props, context) {
    const name = ref(null);
    const address = ref(null);
    const email = ref(null);
    const allowEmailChange = ref(null);
    const phone = ref(null);
    const scheme = ref(null);
    const editId = ref(null);
    const response = ref(props.client);
    const isSubmitting = ref(false);
    const error = ref(null);

    // loader
    const handleLoad = () => {
      // reset
      editId.value = null;
      name.value = response.value.name;
      address.value = response.value.address;
      email.value = response.value.user?.email;
      allowEmailChange.value = response.value.user?.status == "a" ? false : true; // user has logged in
      phone.value = response.value.phone;
      scheme.value = "Private Scheme"; // to be updated
    };

     // update all
     const handleUpdate = () => {
        response.value.name = name.value;
        response.value.address = address.value;
        response.value.phone = phone.value;
        if (response.value.user === null) { response.value.user = {} }
        response.value.user.email = email.value;
     };

    // initialize
    onMounted(() => {
      handleLoad();
    });

    // handle Edit
    const handleEdit = (val) => {
      editId.value = val;
    };

    // handle Back
    const handleBack = () => {
      handleLoad();
    };

    // handle Submit
    const handleSubmit = async (key, val) => {
      // start
      isSubmitting.value = true;
      error.value = false;

      if (val) {
      // prepare for take off
      const newVal = {
          [key]: val,
        };

      // post item
      const { res, err } = await putItem("client", props.client.id, newVal);

      if (res.value) {
        // next
        //update all
        handleUpdate();

        // update record with parent
        context.emit("update", response.value);
      } else {
        error.value = err.value;
      }
    }

      // end here
      isSubmitting.value = false;

      // close
      handleBack();
    };

    return {
      handleEdit,
      handleBack,
      handleSubmit,
      isSubmitting,
      editId,
      error,
      name,
      address,
      email,
      allowEmailChange,
      phone,
      scheme,
    };
  },
};
</script>


<style scoped>
.pointer {
  cursor: default;
}
</style>