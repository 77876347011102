<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <div class="row">
      <div class="col s12">
        <div class="teal-text">
          <span
            v-for="tab in tabs"
            :key="tab.id"
            :class="{
              'seondary-content right': tab.icon,
              'tab-custom-active': tab.id == currTab.id,
              'tab-custom': tab.id != currTab.id,
            }"
            @click="handleActive(tab)"
          >
            <i class="tiny material-icons" v-if="tab.icon">{{ tab.icon }}</i>
            {{ tab.title }}
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12">
        <!-- View All Items -->
        <template v-if="currTab.title == 'All Items'">
          <div class="section">
            <!-- Search -->
            <nav class="white">
              <div class="nav-wrapper">
                <form>
                  <div class="input-field blue-text text-darken-2">
                    <input
                      type="search"
                      v-model="searchItem"
                      placeholder="Search Tariff Item"
                    />
                    <label class="label-icon" for="search"
                      ><i class="material-icons black-text">search</i></label
                    >
                    <i
                      class="material-icons white-text orange"
                      @click="handleClearSearch"
                      v-if="searchItem"
                      >clear</i
                    >
                  </div>
                </form>
              </div>
            </nav>

            <!-- Mini loader -->
            <div class="progress" v-if="isLoading">
              <div class="indeterminate"></div>
            </div>

            <!-- Tariff -->
            <div
              class="card-panel"
              :class="{ 'yellow lighten-5': tariff.id == isEditing }"
              v-for="tariff in tariffs"
              :key="tariff.id"
            >
              <div class="row">
                <div v-if="tariff.id != isEditing">
                  <div class="col s10">
                    <h6>{{ tariff.item }}</h6>
                    <h5>
                      {{
                        `₦${Number(tariff.price)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      }}
                    </h5>
                  </div>
                  <div class="col s2">
                    <a
                      class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
                      ><i
                        class="material-icons"
                        title="edit"
                        @click="handleEdit(tariff)"
                        >edit</i
                      ></a
                    >
                  </div>
                </div>

                <div v-else>
                  <div class="col s10">
                    <h5>Edit Item</h5>

                    <div class="row">
                      <form @submit.prevent="handleEditConfirm(tariff)">
                        <div class="col s6">
                          <div class="input-field">
                            <input type="text" v-model="item" readonly />
                          </div>
                        </div>

                        <div class="col s4">
                          <div class="input-field">
                            <input type="text" v-model="price" />
                          </div>
                        </div>

                        <div class="col s2">
                          <button
                            class="waves-effect waves-light btn"
                            :class="{
                              disabled: isSubmitting,
                            }"
                          >
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col s2">
                    <a
                      class="btn-floating btn-medium waves-effect waves-light black right"
                      ><i
                        class="material-icons"
                        title="close"
                        @click="handleCancel()"
                        >close</i
                      ></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section center" v-if="!tariffs.length">
            <h6>No Tariff <em>found</em>...</h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </template>

        <!-- Add Item-->
        <AddSingleTariff
          :provider="provider"
          @update="handleUpdate"
          v-if="currTab.title == 'Add Item'"
        />

        <!-- Replace -->
        <AddTariff
          :provider="provider"
          @update="handleUpdate"
          v-if="currTab.title == 'Replace All'"
        />
      </div>
    </div>
  </template>
</template>
  
<script>
import { onMounted, ref, computed, watchEffect } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";
import getItemsOfItemsOfItems from "@/composables/getItemsOfItemsOfItems";
import {
  removeDuplicates,
} from "@/composables/fxLibrary/coreFx";
import postItem from "@/composables/postItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import AddTariff from "@/components/tariff/AddTariffComponent.vue";
import AddSingleTariff from "@/components/tariff/AddSingleTariffComponent.vue";
import M from "materialize-css";

export default {
  name: "TariffProviderComponent",
  components: { LoadingDiv, PaginationDiv, AddTariff, AddSingleTariff },
  props: ["provider", "id"],
  setup(props) {
    const response = ref(null);
    const schedule = ref(null);
    const scheme = ref(null);
    const updated = ref(null);
    const error = ref(null);
    const isEditing = ref(null);
    const isSubmitting = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const currUrl = ref(null);
    const currHook = ref(null);
    const item = ref(null);
    const price = ref(null);
    const searchItem = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);

    // Title
    watchEffect(() => {
      if (response.value) {
        // load title
        setTimeout(() => {
          var elems = document.querySelectorAll(".tooltipped");
          M.Tooltip.init(elems, {});
        }, 2000);
      }
    });

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    // get function
    const getTariffsFromApi = (table, id1, id2) => {
      (async () => {
        const { res, err } = await getItemsOfItemsOfItems(table, id1, id2);

        if (res.value) {
         
          // filter zero cost from the list
          res.value.data.data = res.value.data.data.filter((x) => Number(x.price) != 0);

          // start sorting
          const { fx: noDuplicates } = removeDuplicates(
            res.value.data.data,
            "item",
            "version"
          ); //key:item, sortWith:version
        
          res.value.data.data = noDuplicates.value;
          response.value = res.value.data;

        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // Initiate
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, title: "All Items", icon: null, status: null });
      tabs.value.push({
        id: 2,
        title: "Replace All",
        icon: "edit",
        status: "remove",
      });
      tabs.value.push({
        id: 3,
        title: "Add Item",
        icon: "add_circle",
        status: "add",
      });

      // set default
      currTab.value = tabs.value[0];
    });

    // starter (schedule)
    onMounted(() => {
      (async () => {
        const { res } = await getItemsOfItems("schedule", props.id);
        if (res.value) {
          // search
          schedule.value = res.value.data.plan.name;
          scheme.value = res.value.data.plan.scheme.name;
          updated.value = res.value.data.updated_at;
        }
      })();
    });

    const tariffRestart = () => {
      // set current Url
      currUrl.value = "tariff/schedule";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getTariffsFromApi(currUrl.value, props.id, props.provider.id); // provider.schedule_id && provider.id
    };

    // starter
    onMounted(() => {
      // load tariff base
      tariffRestart();
    });

    const handleUpdate = () => {
      // new tariff was added
      // load tariff base
      tariffRestart();
    };

    // handle edit
    const handleEdit = (tariff) => {
      isEditing.value = tariff.id;
      item.value = tariff.item;
      price.value = tariff.price;
    };

    // handle cancel
    const handleCancel = () => {
      isEditing.value = null;
      item.value = null;
      price.value = null;
    };

    // handle edit confirm
    const handleEditConfirm = async (tariff) => {
      // put all together
      let newVal = {
        item: item.value,
        price: price.value,
        schedule_id: props.provider.schedule_id,
        scope: props.provider.id,
      };

      // post item
      const { res, err } = await postItem("tariff/add", newVal);

      if (res.value) {
        // success, update current array
        response.value.data.forEach((x) => {
          if (x.id == tariff.id) {
            x.item = item.value;
            x.price = price.value;
          }
        });
      } else {
        error.value = `Error: ${item.value} not updated! (${err.value})`;
      }

      handleCancel();
    };

    // restart
    const handleClearSearch = () => {
      // start laoding
      isLoading.value = true;
      // null search
      searchItem.value = null;
      // load tariff base
      tariffRestart();
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getTariffsFromApi(
        currUrl.value,
        props.id,
        `${props.provider.id}${currHook.value}page=${page}`
      );
    };

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currTab.value = tab;
    };

    // search watcher
    watchEffect(() => {
      if (searchItem.value) {
        // start laoding
        isLoading.value = true;
        // set current Url
        currUrl.value = "tariff/schedule/search";
        // set current Hook
        currHook.value = `?q=${searchItem.value}&`;
        // get searchItem items from function
        getTariffsFromApi(
          currUrl.value,
          props.id,
          `${props.provider.id}?q=${searchItem.value}`
        );
      } else {
        handleClearSearch();
      }
    });

    // output results
    const tariffs = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handleEdit,
      handleActive,
      handleEditConfirm,
      handleCancel,
      handleClearSearch,
      handlePage,
      handleUpdate,
      numberBadge,
      error,
      isEditing,
      isSubmitting,
      isReady,
      isLoading,
      searchItem,
      item,
      price,
      currTab,
      tabs,
      schedule,
      scheme,
      updated,
      tariffs,
      allPages,
    };
  },
};
</script>
  
<style scoped>
.progress {
  margin-top: 0px; /* Adjust the value as needed to move the progress bar upwards */
}

.pointer {
  cursor: default;
}

.clickable {
  cursor: pointer;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}

.info-item:hover .material-icons.grey-text,
.info-item:hover .material-icons.grey-text::before {
  color: rgb(0, 0, 0) !important;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.reset-item:hover .material-icons.grey-text,
.reset-item:hover .material-icons.grey-text::before {
  color: rgb(23, 124, 40) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}
</style>