<template>
  <div class="container">
    <div class="card-content">
      <router-link :to="{ name: 'provider' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >label</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2">
        <small>Hospitals</small>
      </h3>
    </div>

    <div class="section">
      <nav class="white">
        <div class="nav-wrapper">
          <form>
            <div class="input-field blue-text text-darken-2">
              <input
                v-model="searchItem"
                type="search"
                placeholder="Search Providers by Name"
              />
              <label class="label-icon" for="search"
                ><i class="material-icons black-text">search</i></label
              >
              <i
                class="material-icons white-text orange"
                @click="handleClearSearch"
                v-if="searchItem"
                >clear</i
              >
            </div>
          </form>
        </div>
      </nav>
    </div>

    <!-- Loader -->
    <div class="progress" v-if="isLoading && isReady">
      <div class="indeterminate"></div>
    </div>

    <LoadingDiv v-if="!isReady" />

    <div v-else>
      <div class="black-text center">
        <h5 v-if="searchItem">
          <span class="black blue-text chip"
            >Search <i class="tiny material-icons">check_circle</i></span
          >
          <span class="yellow lighten-2 black-text chip"
            ><em>{{ searchItem }}</em></span
          >
        </h5>
        <h5 v-else>
          <span class="black blue-text chip"
            >Display <i class="tiny material-icons">check_circle</i></span
          >
          <span class="yellow lighten-2 black-text chip"
            ><em>All Providers</em></span
          >
        </h5>
      </div>

      <div class="section">
        <div
          class="card-panel"
          v-for="provider in providers"
          :key="provider.id"
        >
          <h5>
            <span
              class="new badge pointer secondary-content"
              data-badge-caption="Days Ago"
              :class="{
                grey: inDays(provider?.encounters[0].encounter_date) < 0,
                teal:
                  inDays(provider?.encounters[0].encounter_date) > 0 &&
                  inDays(provider?.encounters[0].encounter_date) <= 42,
                yellow:
                  inDays(provider?.encounters[0].encounter_date) > 42 &&
                  inDays(provider?.encounters[0].encounter_date) <= 84,
                red: inDays(provider?.encounters[0].encounter_date) > 84,
              }"
              :title="`Active ${new Date(
                provider?.encounters[0].encounter_date
              ).toLocaleString(undefined, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}`"
              v-if="provider?.encounters.length"
            >
              {{ inDays(provider?.encounters[0].encounter_date) }}
            </span>

            <span
              class="new badge pointer grey secondary-content"
              data-badge-caption="Never Active"
              :title="`Created ${new Date(provider.created_at).toLocaleString(
                undefined,
                {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }
              )}`"
              v-else
            >
            </span>

            {{ provider.name }}
          </h5>
          <h6 class="grey-text darken-4">{{ provider.address }}</h6>
          <p>{{ provider.phone }}, {{ provider.user?.email }}</p>
        </div>
      </div>

      <div class="section center" v-if="!providers.length">
        <h6>No Provider <em>found</em>...</h6>
      </div>

      <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
    </div>
  </div>
</template>
  
  <script>
import { computed, onMounted, ref, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import { cacheGet, cachePush } from "@/composables/cache/cache.js";

export default {
  name: "enrolleeHome",
  components: { LoadingDiv, PaginationDiv },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const currUrl = ref(null);
    const currHook = ref(null);
    const serverTime = ref(null);
    const error = ref(null);
    const searchItem = ref(null);

    // start
    onMounted(() => {
      // page name
      document.title = "Hospitals | easyHMO";
    });

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    const inDays = (val) => {
      if (val && serverTime.value) {
        const date1 = new Date(val);
        const date2 = new Date(serverTime.value);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      } else {
        return -1;
      }
    };

    // get function
    const getItemsFromApi = (table) => {
      // get cache
      response.value = cacheGet(table);

      if (response.value) {
        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      }

      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          serverTime.value = res.value.server;
          // push cache
          cachePush(table, response.value);
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // get function
    const getSearchItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "provider";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    });

    // restart
    const handleClearSearch = () => {
      // start laoding
      isLoading.value = true;
      // null search
      searchItem.value = null;
      // set current Url
      currUrl.value = "provider";
      // set current Hook
      currHook.value = "?";
      // re-get items from function
      getItemsFromApi(currUrl.value);
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`); //
    };

    // search watcher
    watchEffect(() => {
      if (searchItem.value) {
        // start laoding
        isLoading.value = true;
        // set current Url
        currUrl.value = `provider/list/search?q=${searchItem.value}`;
        // set current Hook
        currHook.value = "&";
        // get searchItem items from function
        getSearchItemsFromApi(currUrl.value);
      }
    });

    // output results
    const providers = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      numberBadge,
      handleClearSearch,
      handlePage,
      inDays,
      isReady,
      isLoading,
      error,
      searchItem,
      providers,
      allPages,
    };
  },
};
</script>
  
  <style scoped>
.progress {
  margin-top: -15px;
}

.spacer {
  padding: 8px;
}

.top-spacer {
  padding: 10px;
}

.pointer {
  cursor: default;
}
</style>