<template>
  <!-- Loader -->
  <div class="progress" v-if="!isReady">
    <div class="indeterminate"></div>
  </div>

  <div class="card">
    <div class="card-content teal lighten-5" v-if="success">
      <div class="input-field">
        <h6 class="grey-text darken-5 center">
          {{ success }} <i class="material-icons">check</i>
        </h6>
      </div>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="card-content">
        <div class="file-field input-field">
          <div class="btn grey darken-1">
            <span>Principals (List)</span>
            <input type="file" @change="handleFile" />
          </div>
          <div class="file-path-wrapper">
            <input class="file-path" v-model="inputFile" type="text" />
            <p v-if="fileError">{{ fileError }}</p>
          </div>
        </div>
      </div>

      <div class="card-panel">
        <div class="input-field">
          <label class="active teal-text" for="plan">Plan</label>
          <select id="plan" v-model="plan" class="validate" required>
            <option v-for="plan in plans" :key="plan.id" :value="plan.id">
              {{ plan.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- load provider select -->
      <ProviderDiv
        :userPlanPoints="userPlanPoints"
        @update="handleUpdate"
        v-if="plan && cat == 'tpa'"
      />

      <div class="card-panel">
        <div class="input-field">
          <label class="active teal-text" for="type">Type</label>
          <select id="type" v-model="type" class="validate" required>
            <option value="i">Individual</option>
            <option value="f">Family</option>
          </select>
        </div>
      </div>

      <div class="card grey lighten-5">
        <p v-if="uploadError"><br />{{ uploadError }}</p>

        <p>
          <button
            class="waves-effect waves-light btn-large"
            :class="{
              disabled: !file || !plan || !type || !isReady || isSubmitting,
            }"
          >
            <i class="material-icons right">upload</i
            >{{ isSubmitting ? `Uploading (${uploadProgress})` : "Upload" }}
          </button>
        </p>
      </div>
    </form>
  </div>
</template>
  
<script>
import { onMounted, onUpdated, computed, ref, watchEffect } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";
import useStorage from "@/composables/useStorage";
import ProviderDiv from "@/components/client/tpa/ProviderSelectTPAComponent.vue";
import M from "materialize-css";

export default {
  name: "EnrolleeBulkOnboardComponent",
  components: { ProviderDiv },
  props: ["cat", "clientId"],
  setup(props) {
    const response = ref(null);
    const userPlanPoints = ref(null);
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const success = ref(null);
    const isReady = ref(false);
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const error = ref(false);
    const provider = ref(0);
    const plan = ref(null);
    const type = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // get function
    const getItemsFromApi = (table, match) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, match);

        if (res.value) {
          // suspend as per cat
          if (props.cat != "tpa") {
            response.value = res.value.data.filter(
              (x) => Array.isArray(x.premiums) && x.premiums[0]?.amount > 0
            ); // remove suspended (zeros)
          } else {
            response.value = res.value.data.filter(
              (x) => Array.isArray(x.premiums) && x.premiums[0]?.amount == 0
            ); // remove suspended (premium amounts)
          }
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // start from the top
      getItemsFromApi("plan/client/plans", props.clientId);
    });

    // drop down
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // select
    onUpdated(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // clear
    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      uploadProgress.value = "0%";
      plan.value = null;
      type.value = null;
    };

    // file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    // update provider
    const handleUpdate = (val) => {
      if (val) {
        provider.value = val;
      } else {
        provider.value = 0;
      }
    };

    //hand
    watchEffect(() => {
      if (plan.value) {
        // start ploading
        let userPlan = plans.value.filter((x) => x.id == plan.value);
        let userPlanPointsArray = userPlan[0].premiums
          .filter((x) => x.scope === 0 && x.type === "i")
          .reduce((a, b) =>
            new Date(a.created_at) > new Date(b.created_at) ? a : b
          );
        // finalize
        userPlanPoints.value = userPlanPointsArray?.amount;
      } else {
        // null
        userPlanPoints.value = null;
      }
    });

    const handleSubmit = async () => {
      if (file.value && plan.value) {
        // Start Uploading
        isSubmitting.value = true;
        uploadError.value = null;
        success.value = null;

        // Cloud Function
        const fx = "principalLoader";

        // Round up
        const cloudInput = {
          hmo: "LWH", // HMO Abbr
          client: props.clientId, // client ID
          code: "ICS", // Client Abbr
          plan: plan.value, // Plan Abbr
          provider: provider.value, //Provider Abbr
          type: type.value,
        };

        // upload
        uploadFile(file.value, fx, cloudInput);
      } else {
        // clear all
        clearForm();
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        //do stuff...
        // data.succ.Success, data.succ.inserted_rows, data.succ.Error

        // start
        success.value = `Principals (${data.value.succ.inserted_rows}) added! - Lobby`;

        // clear all
        clearForm();
      }
      if (uploadError.value) {
        // clear all
        clearForm();
      }
    });

    // all enrollees
    const plans = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleFile,
      handleUpdate,
      handleSubmit,
      success,
      isReady,
      isLoading,
      isSubmitting,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      userPlanPoints,
      provider,
      plan,
      type,
      plans,
    };
  },
};
</script>
  
<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>