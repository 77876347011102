<template>
  <form @submit.prevent="handleSubmit">
    <div class="card-content">
      <div class="file-field input-field">
        <div class="btn grey darken-1">
          <span>Pricipals (Suspension List)</span>
          <input type="file" @change="handleFile" />
        </div>
        <div class="file-path-wrapper">
          <input class="file-path" v-model="inputFile" type="text" />
          <p class="red-text" v-if="fileError">{{ fileError }}</p>
        </div>
      </div>
    </div>

    <div class="card grey lighten-5">
      <p v-if="uploadError"><br />{{ uploadError }}</p>

      <p>
        <button
          class="waves-effect waves-light btn-large"
          :class="{
            disabled: !file || isSubmitting,
          }"
        >
          <i class="material-icons right">upload</i
          >{{ isSubmitting ? `Uploading (${uploadProgress})` : "Upload" }}
        </button>
      </p>
    </div>
  </form>
</template>
    
  <script>
import { onMounted, ref, watchEffect } from "vue";
import useStorage from "@/composables/useStorage";
import M from "materialize-css";

export default {
  name: "EnrolleeRemoveBulkOnboardComponent",
  emits: ["complete"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isSubmitting = ref(false);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

     // storage
     const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // select dropdown
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // clear
    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      uploadProgress.value = "0%";
    };

    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    const handleSubmit = async () => {
      if (file.value) {
        // Start Uploading
        isSubmitting.value = true;
        uploadError.value = null;

        // Cloud Function
        const fx = "principalRemover";

        // Round up
        const cloudInput = true; // Boolean (no use for this for now)

        // upload
        uploadFile(file.value, fx, cloudInput);
      } else {
        // clear all
        clearForm();
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        //do stuff...
        // data.succ.Success, data.succ.inserted_rows, data.succ.Error
        // emit new parameters
        context.emit("complete", {
          status: true,
          value: data.value.succ.inserted_rows
        });

        // clear all
        clearForm();
      }
      if (uploadError.value) {
        // clear all
        clearForm();
      }
    });

    return {
      handleFile,
      handleSubmit,
      isSubmitting,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
    };
  },
};
</script>
    
  <style>
</style>