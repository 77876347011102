<template>
  <div class="section">
    <form>
      <div class="row">
        <div class="col s4">
          <!-- <div class="card-panel"> -->
          <FilterDiv
            inputId="filterScheme"
            :data="filterSchemeData"
            :startAgain="resetScheme"
            @selected="handlePick"
            @searching="handleSearch"
            @clear="handleClear"
          />
          <!-- </div> -->
        </div>
        <div class="col s8">
          <!-- <div class="card-panel"> -->
          <FilterDiv
            inputId="filterEnrollee"
            :data="filterEnrolleeData"
            :startAgain="resetEnrollee"
            @selected="handlePick"
            @searching="handleSearch"
            @clear="handleClear"
          />
          <!-- </div> -->
        </div>
      </div>

      <div class="row">
        <div class="col s5">
          <!-- <div class="card-panel"> -->
          <FilterDiv
            inputId="filterDuration"
            :data="filterDurationData"
            :startAgain="resetDuration"
            @selected="handlePick"
            @searching="handleSearch"
            @clear="handleClear"
          />
          <!-- </div> -->
        </div>
        <div class="col s7">
          <!-- <div class="card-panel"> -->
          <FilterDiv
            inputId="filterProvider"
            :data="filterProviderData"
            :startAgain="resetProvider"
            @selected="handlePick"
            @searching="handleSearch"
            @clear="handleClear"
          />
          <!-- </div> -->
        </div>
      </div>
    </form>
  </div>
</template>
  
  <script>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import getItems from "@/composables/getItems";
import FilterDiv from "@/components/search/FilterDiv.vue";
import { searchMonths } from "@/composables/fxLibrary/months";

export default {
  name: "FilterAuthComponent",
  components: { FilterDiv },
  setup(props, context) {
    const responseSearch = ref(null);
    const responseSchemeDefault = ref(null);
    const responseDurationDefault = ref(null);
    const resetEnrollee = ref(null);
    const resetDuration = ref(null);
    const resetScheme = ref(null);
    const resetProvider = ref(null);
    const searchBody = ref([]);
    const selectedItem = ref(null);
    const selectedEnrollee = ref("");
    const selectedProvider = ref("");
    const selectedDuration = ref("");
    const selectedScheme = ref("");
    const specialCase = ref([]);

    // vue router
    const router = useRouter();

    // store
    const store = useStore();

    // enrollee
    const filterEnrolleeData = computed(() => {
      // start
      let val = {
        "All Enrollees": null,
      };

      let valPlus;
      let valPlusPlus;

      if (responseSearch.value) {
        if (responseSearch.value.key == "filterEnrollee") {
          valPlus = responseSearch.value.value.reduce((newArr, x) => {
            newArr[x.encounter.name] = null;
            return newArr;
          }, {});
          valPlusPlus = responseSearch.value.value.reduce((newArr, x) => {
            newArr[x.encounter.enrollee_number] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus, ...valPlusPlus };
        }
      }

      // return final
      return val;
    });

    // provider
    const filterProviderData = computed(() => {
      // start
      let val = {
        "All Providers": null,
      };

      let valPlus;

      if (responseSearch.value) {
        if (responseSearch.value.key == "filterProvider") {
          valPlus = responseSearch.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // scheme
    const filterSchemeData = computed(() => {
      // start
      let val = {
        Schemes: null, // scheme will be picked on the fly
      };

      let valPlus;

      if (responseSchemeDefault.value) {
        if (responseSchemeDefault.value.key == "filterScheme") {
          valPlus = responseSchemeDefault.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...valPlus }; // ignore val (its null)
        }
      }

      // return final
      return val;
    });

    // Duration
    const filterDurationData = computed(() => {
      // start
      let val = {
        "All Time": null,
      };

      let valPlus;

      if (responseDurationDefault.value) {
        if (responseDurationDefault.value.key == "filterDuration") {
          valPlus = responseDurationDefault.value.value.reduce((newArr, x) => {
            newArr[x.period] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // search function
    const getSearchItemsFromApi = (table, key) => {
      (async () => {
        const { res } = await getItems(table);
        if (res.value) {
          // search
          responseSearch.value = { key: key, value: res.value.data };
        }
      })();
    };

    // scheme initialization
    onMounted(() => {
      // initialize filterScheme
      if (store.state.masterSchemes) {
        // allowedSchemes
        let allowedSchemes = [];
        if (store.state.schemes) {
          allowedSchemes = store.state.masterSchemes.filter((x) =>
            store.state.schemes.includes(x.id)
          );
        }

        // one time
        responseSchemeDefault.value = {
          key: "filterScheme",
          value: allowedSchemes,
        };
        /*
        Special defualt cases
      */
        specialCase.value.push({
          ...responseSchemeDefault.value.value[0],
          key: "filterScheme",
        }); // current scheme
        /* Set reset (search special array, 
     in case there are other special cases)
     */
        specialCase.value.forEach((x) => {
          if (x.key == "filterScheme") {
            resetScheme.value = x.name;
            selectedItem.value = { id: x.key, value: x.name, special: true };
          }
        });
      } else {
        // Error: refer back to login
        router.push({
          name: "login",
        });
      }
    });

    // filter (Initialize)
    onMounted(() => {
      // initialize filterDuration
      const { fx } = searchMonths();

      // set
      responseDurationDefault.value = {
        key: "filterDuration",
        value: fx.value,
      };
    });

    // filter components
    const handleSearch = (val) => {
      if (val.id == "filterEnrollee") {
        // get new serach items
        getSearchItemsFromApi(`claim/filter?q=${val.value}`, val.id);
      } else if (val.id == "filterProvider") {
        // get new serach items
        getSearchItemsFromApi(`provider/filter?q=${val.value}`, val.id);
      } else if (val.id == "filterScheme") {
        // preset onMounted
      } else if (val.id == "filterDuration") {
        // preset
      }
    };

    // null reset
    const handleClear = (val) => {
      if (val.id == "filterEnrollee") {
        resetEnrollee.value = val.value;
      } else if (val.id == "filterProvider") {
        resetProvider.value = val.value;
      } else if (val.id == "filterScheme") {
        resetScheme.value = val.value;
      } else if (val.id == "filterDuration") {
        resetDuration.value = val.value;
      }
    };

    // get option
    const handlePick = (val) => {
      selectedItem.value = val;
      // watchEffect will take over from here
    };

    // search watcher
    watchEffect(() => {
      // token
      let searchToken = false;

      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterEnrollee") {
          // set token
          searchToken = true;

          //set
          if (
            Object.keys(filterEnrolleeData.value)[0] == selectedItem.value.value
          ) {
            selectedEnrollee.value = "";
            searchBody.value = searchBody.value.filter(
              (x) => x !== "Enrollees"
            );
          } else {
            // update search body
            searchBody.value = searchBody.value.filter(
              (x) => x !== "Enrollees"
            );
            searchBody.value = searchBody.value.filter(
              (x) => x !== "Providers" && x !== "Period"
            );
            searchBody.value.push("Enrollees");
            // get enrollee number from name or enrollee_number (self)
            // responseSearch will have all the complete data
            responseSearch.value.value.forEach((x) => {
              if (x.encounter.name == selectedItem.value.value) {
                // found
                selectedEnrollee.value = x.encounter.enrollee_number;
              }
              if (x.encounter.enrollee_number == selectedItem.value.value) {
                // found
                selectedEnrollee.value = x.encounter.enrollee_number;
              }
            });
            if (selectedEnrollee.value) {
              // reset search fields (for better search results)
              resetProvider.value = Object.keys(filterProviderData.value)[0];
              resetDuration.value = Object.keys(filterDurationData.value)[0];
              // clear corresponding search
              selectedProvider.value = "";
              selectedDuration.value = "";
            }
          }
        } else if (selectedItem.value.id == "filterProvider") {
          // set token
          searchToken = true;

          //set
          if (
            Object.keys(filterProviderData.value)[0] == selectedItem.value.value
          ) {
            selectedProvider.value = "";
            searchBody.value = searchBody.value.filter(
              (x) => x !== "Providers"
            );
          } else {
            // update search body
            searchBody.value = searchBody.value.filter(
              (x) => x !== "Providers"
            );
            searchBody.value = searchBody.value.filter(
              (x) => x !== "Enrollees"
            );
            searchBody.value.push("Providers");
            // get provider id
            // responseSearch will have all the complete data
            responseSearch.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                selectedProvider.value = x.id;
              }
            });
            if (selectedProvider.value) {
              // reset search fields (for better search results)
              resetEnrollee.value = Object.keys(filterEnrolleeData.value)[0];
              // clear corresponding search
              selectedEnrollee.value = "";
            }
          }
        } else if (selectedItem.value.id == "filterDuration") {
          // set token
          searchToken = true;

          //set
          if (
            Object.keys(filterDurationData.value)[0] == selectedItem.value.value
          ) {
            selectedDuration.value = "";
            searchBody.value = searchBody.value.filter(
              (x) => !x.includes("Period")
            );
          } else {
            // update search body
            searchBody.value = searchBody.value.filter(
              (x) => !x.includes("Period")
            );
            searchBody.value = searchBody.value.filter(
              (x) => x !== "Enrollees"
            );
            searchBody.value.push(`Period (${selectedItem.value.value})`);
            // get duration day
            // responseDurationDefault will have all the complete data
            responseDurationDefault.value.value.forEach((x) => {
              if (x.period == selectedItem.value.value) {
                // found
                selectedDuration.value = x.date;
              }
            });
            if (selectedDuration.value) {
              // reset search fields (for better search results)
              resetEnrollee.value = Object.keys(filterEnrolleeData.value)[0];
              // clear corresponding search
              selectedEnrollee.value = "";
            }
          }
        } else if (selectedItem.value.id == "filterScheme") {
          // set token
          searchToken = true;
          // check default
          if (selectedItem.value.special) {
            specialCase.value.forEach((x) => {
              if (x.key == "filterScheme") {
                resetScheme.value = x.name;
                selectedItem.value.value = x.name;
              }
            });
          }

          //set (no empty scheme)
          // update search body
          searchBody.value = searchBody.value.filter(
            (x) => !x.includes("Schemes")
          );
          searchBody.value = searchBody.value.filter(
            (x) =>
              x !== "Enrollees" && !x.includes("Period") && x !== "Providers"
          );
          searchBody.value.push(`Schemes (${selectedItem.value.value})`);
          // get scheme id
          // responseSchemeDefault will have all the complete data
          responseSchemeDefault.value.value.forEach((x) => {
            if (x.name == selectedItem.value.value) {
              // found
              selectedScheme.value = x.id;
            }
          });
          if (selectedScheme.value) {
            // reset search fields (for better search results)
            resetEnrollee.value = Object.keys(filterEnrolleeData.value)[0];
            resetProvider.value = Object.keys(filterProviderData.value)[0];
            resetDuration.value = Object.keys(filterDurationData.value)[0];
            // clear corresponding search
            selectedEnrollee.value = "";
            selectedProvider.value = "";
            selectedDuration.value = "";
          }
        }

        if (searchToken) {
          // start laoding
          let searchTitle = null;
          let currUrl = null;
          let currHook = null;

          // label
          if (
            selectedEnrollee.value ||
            selectedProvider.value ||
            selectedScheme.value ||
            selectedDuration.value
          ) {
            let uniqueBody = [...new Set(searchBody.value)];
            searchTitle = `<span class="black blue-text chip">Search <i class="tiny material-icons">check_circle</i></span>`;
            uniqueBody.forEach((x) => {
              searchTitle += `<span class="yellow lighten-2 black-text chip"><em>${x}</em></span> `;
            });
            searchTitle = searchTitle.slice(0, -2);
          } else {
            searchTitle = null;
          }

          //quick solution
          if (!selectedScheme.value) {
            selectedScheme.value = responseSchemeDefault.value.value[0]?.id;
          }

          // always add schemes
          if (selectedScheme.value) {
            // set current Url
            currUrl = `claim/auth/search?e=${selectedEnrollee.value}&p=${selectedProvider.value}&s=${selectedScheme.value}&d=${selectedDuration.value}`;
            // set current Hook
            currHook = "&";

            // emit new parameters
            context.emit("search", {
              searchTitle: searchTitle,
              currUrl: currUrl,
              currHook: currHook,
            });
          }

          // kill token
          searchToken = false;
          // kill searchItem
          selectedItem.value = null;
        }
      }
    });

    return {
      handleSearch,
      handleClear,
      handlePick,
      selectedItem,
      resetEnrollee,
      resetDuration,
      resetScheme,
      resetProvider,
      filterEnrolleeData,
      filterProviderData,
      filterSchemeData,
      filterDurationData,
      selectedEnrollee,
      selectedProvider,
      selectedScheme,
      selectedDuration,
    };
  },
};
</script>
  
<style>
</style>