<template>
  <!-- Loading -->
  <div class="progress" v-if="isLoading">
    <div class="indeterminate"></div>
  </div>

  <!-- Display -->
  <template v-else>
      <div class="card">
        <div class="card-content white">
          <table class="responsive-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name <small>(Enrollee ID)</small></th>

                <th>Plan</th>
                <th>Type</th>
                <th>Billable</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="clickable"
                v-for="(invoice, index) in invoices"
                :key="invoice.id"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  {{ invoice.principal.name }}<br />
                  ({{ invoice.principal.enrollee_number }})
                </td>

                <td>{{ invoice.premium.plan.name }}
                    <span v-if="invoice.premium.scope > 0"><br /><em>(Customized)</em></span>
                </td>
                <td>{{ invoice.premium.type == "f" ? "Family" : "Individual" }}</td>
                <td>
                  <span
                    >₦{{
                      changeToNaira(invoice.bill)
                    }} </span
                  > <span v-if="invoice.description.toLowerCase().includes('prorated')"><em>(Prorated)</em></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    <!-- No Invoices Found -->
    <div class="section center" v-if="!invoices.length">
      <h6>No Invoices <em>found</em>...</h6>
    </div>

    <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
  </template>

</template>
        
<script>
import { ref, computed, onMounted } from "vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import getItemsOfItems from "@/composables/getItemsOfItems";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";


export default {
  name: "InvoiceDiv",
  components: { PaginationDiv },
  props: ["currInvoice"],
  setup(props) {
    const response = ref(null);
    const error = ref(false);
    const isLoading = ref(false);
    const currUrl = ref(null);
    const currHook = ref(null);

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isLoading.value = false;
      })();
    };

    // to naira
    const changeToNaira = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // starter
    onMounted(() => {
      // start from the top
      getAllInvoices();
    });

    // prinipals
    const getAllInvoices = () => {
      // loader
      isLoading.value = true;
      // set current Url
      currUrl.value = "invoices";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value, props.currInvoice.id);
    };

    // switch page
    const handlePage = (page) => {
      // loader
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(
        currUrl.value,
        `${props.currInvoice.id}${currHook.value}page=${page}`
      );
    };

    // output results
    const invoices = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });


    return {
      changeToNaira,
      handlePage,
      error,
      isLoading,
      invoices,
      allPages,
    };
  },
};
</script>
      
<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>