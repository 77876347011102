<template>
    <div class="card">
      <div class="card-panel grey lighten-5">
        <div class="input-field">
          <select v-model="type">
            <option value="single" selected>(Single) Remove</option>
            <option value="few">(Few) Remove</option>
            <option value="bulk">(Bulk) Remove</option>
          </select>
          <label>Remove Type</label>
        </div>
      </div>
  
      <EnrolleeRemoveSingleDiv v-if="type == 'single'" />
      <EnrolleeRemoveFewDiv v-if="type == 'few'" />
    <EnrolleeRemoveBulkDiv v-if="type == 'bulk'" @complete="handleComplete" />
    </div>
  
  </template>
      
  <script>
  import { ref, onMounted } from "vue";
  import EnrolleeRemoveSingleDiv from "@/components/client/onboard/EnrolleeRemoveSingleOnboardComponent.vue";
  import EnrolleeRemoveFewDiv from "@/components/client/onboard/EnrolleeRemoveFewOnboardComponent.vue";
  import EnrolleeRemoveBulkDiv from "@/components/client/onboard/EnrolleeRemoveBulkOnboardComponent.vue";
  import M from "materialize-css";
  
  export default {
    name: "EnrolleeRemoveOnboardComponent",
    components: { EnrolleeRemoveSingleDiv, EnrolleeRemoveFewDiv, EnrolleeRemoveBulkDiv },
    props: ["cat"],
    setup() {
        const type = ref("single");
  
      onMounted(() => {
        // activate select field
        const elemsSelect = document.querySelectorAll("select");
        M.FormSelect.init(elemsSelect, {});
      });

      // handle complete
      const handleComplete = (val) => {
        if (val.status) {
          type.value = "single" // back to default
        }
      };
  

      return {
        handleComplete,
        type,
      };
    },
  };
  </script>
      
    
    <style scoped>
  .spacer10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .pointer {
    cursor: default;
  }
  
  .edit-item:hover .material-icons.grey-text,
  .edit-item:hover .material-icons.grey-text::before {
    color: rgb(21, 21, 206) !important;
  }
  
  /* open */
  .chip-more {
    cursor: pointer;
    background-color: #eaecee !important;
  }
  
  .chip-more:hover {
    color: white !important;
    background-color: black !important;
  }
  
  /* close */
  .chip-less {
    cursor: pointer;
    color: white !important;
    background-color: black !important;
  }
  
  .chip-less:hover {
    color: black !important;
    background-color: #eaecee !important;
  }
  
  /* base class */
  .tab-custom {
    display: inline-block;
    cursor: pointer;
    margin: 0 0 -1px;
    padding: 10px 15px;
    font-weight: 600;
    text-align: center;
    border: 1px solid transparent;
  }
  
  .tab-custom:hover {
    color: #000000 !important;
  }
  
  .tab-custom-active {
    display: inline-block;
    cursor: pointer;
    margin: 0 0 -1px;
    padding: 10px 15px;
    color: white;
    font-weight: 600;
    text-align: center;
    background-color: black;
  }
  
  /* display */
  .tab-custom-section {
    padding: 20px 0 0;
    border-top: 1px solid #ddd;
  }
  </style>