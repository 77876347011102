<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <form @submit.prevent="handleSubmit">
    <div
      class="card-panel"
      :class="{ 'yellow lighten-5': editId == 'policyDateMonth' }"
    >
      <div class="input-field">
        <div class="row">
          <div
            class="col s5"
          >
            <span>
              <p>12 Months</p>
              <h5>
                {{
                  new Date(`${year}-${month}-01`).toLocaleString(undefined, {
                    year: "numeric",
                    month: "short",
                  })
                }}
                -
                {{
                  new Date(
                    `${Number(year) + 1}-${Number(month) - 1}-01`
                  ).toLocaleString(undefined, {
                    year: "numeric",
                    month: "short",
                  })
                }}
              </h5>
            </span>
            <span v-if="editId == 'policyDateMonth-(Block)'">
              <select id="policyDateYear" class="validate" required>
              <option :value="Number(year) + 1">{{ Number(year) + 1 }}</option>
                <option :value="Number(year)">{{ Number(year) }}</option>
                <option :value="Number(year) - 1">
                  {{ Number(year) - 1 }}
                </option>
              </select>
            </span>
            <label class="active teal-text" for="policyDateYear"
              >{{ cat == 'tpa' ? 'TPA' : 'Policy' }} Year</label
            >
          </div>

          <div
            class="col s5"
          >
            <span v-if="editId == 'policyDateMonth-(Block)'">
              <select id="policyDateMonth" class="validate" required>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </span>
            <label
              class="active teal-text"
              for="policyDateMonth"
              v-if="editId == 'policyDateMonth'"
              >Policy Month</label
            >
          </div>
          <div class="col s2">
            <span
              class="btn-floating btn-medium waves-effect waves-light grey pointer right"
              ><i class="material-icons" title="locked">lock</i></span
            >
          </div>
          <div class="col s12 right" v-if="editId == 'policyDateMonth-(Block)'">
            <button
              class="waves-effect waves-light btn black right"
              :class="{
                disabled: isSubmitting,
              }"
              @click.prevent="handleBack"
            >
              <i class="material-icons left" title="go back">chevron_left</i>
              Back
            </button>

            <button
              class="waves-effect waves-light btn right"
              :class="{
                disabled: isSubmitting,
              }"
            >
              Update
              <i class="material-icons right" title="go back">check_circle</i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card-panel"
      :class="{ 'yellow lighten-5': editId == 'premiumCycle' }"
    >
      <div class="input-field">
        <div class="row">
          <div
            class="col s10"
          >
            <span>
              <h5>Every {{ premiumCycle }} Month(s)</h5>
            </span>
            <span v-if="editId == 'premiumCycle-(Block)'">
              <select id="premiumCycle" class="validate" required>
                <option value="12">Yearly</option>
                <option value="6">Every 6 Months</option>
                <option value="4">Every 4 Months</option>
                <option value="3">Every 3 Months</option>
                <option value="2">Every 2 Months</option>
                <option value="1">Monthly</option>
              </select>
            </span>
            <label class="active teal-text" for="premiumCycle"
              >Payment Cycle</label
            >
          </div>
          <div class="col s2">
            <span
              class="btn-floating btn-medium waves-effect waves-light grey pointer right"
              ><i class="material-icons" title="locked">lock</i></span
            >
          </div>
          <div class="col s12 right" v-if="editId == 'premiumCycle-(Block)'">
            <button
              class="waves-effect waves-light btn black right"
              :class="{
                disabled: isSubmitting,
              }"
              @click.prevent="handleBack"
            >
              <i class="material-icons left" title="go back">chevron_left</i>
              Back
            </button>

            <button
              class="waves-effect waves-light btn right"
              :class="{
                disabled: isSubmitting,
              }"
            >
              Update
              <i class="material-icons right" title="go back">check_circle</i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card-panel"
      :class="{ 'yellow lighten-5': editId == 'familySize' }"
    >
      <div class="input-field">
        <div class="row">
          <div
            :class="{
              'col s10': editId != 'familySize',
              'col s12': editId == 'familySize',
            }"
          >
            <span v-if="editId != 'familySize'">
              <h5>{{ familySize }} Members</h5>
            </span>
            <span v-else>
              <input
                id="familySize"
                type="number"
                v-model="familySize"
                class="validate"
                required
              />
            </span>
            <label class="active teal-text" for="familySize">Family Size</label>
          </div>
          <div class="col s2" v-if="editId != 'familySize'">
            <span>
              <a
                class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
                ><i
                  class="material-icons"
                  @click="handleEdit('familySize')"
                  title="edit"
                  >edit</i
                ></a
              >
            </span>
          </div>
          <div class="col s12 right" v-if="editId == 'familySize'">
            <button
              class="waves-effect waves-light btn black right"
              :class="{
                disabled: isSubmitting,
              }"
              @click.prevent="handleBack"
            >
              <i class="material-icons left" title="go back">chevron_left</i>
              Back
            </button>

            <button
              class="waves-effect waves-light btn right"
              :class="{
                disabled: isSubmitting,
              }"
            >
              Update
              <i class="material-icons right" title="go back">check_circle</i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card-panel"
      :class="{ 'yellow lighten-5': editId == 'principalAge' }"
    >
      <div class="input-field">
        <div class="row">
          <div
            :class="{
              'col s10': editId != 'principalAge',
              'col s12': editId == 'principalAge',
            }"
          >
            <span v-if="editId != 'principalAge'">
              <h5>{{ principalAge }} Years</h5>
            </span>
            <span v-else>
              <input
                id="prinipalAge"
                type="number"
                v-model="principalAge"
                class="validate"
                required
              />
            </span>
            <label class="active teal-text" for="principalAge"
              >Principal Age</label
            >
          </div>
          <div class="col s2" v-if="editId != 'principalAge'">
            <span>
              <a
                class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
                ><i
                  class="material-icons"
                  @click="handleEdit('principalAge')"
                  title="edit"
                  >edit</i
                ></a
              >
            </span>
          </div>
          <div class="col s12 right" v-if="editId == 'principalAge'">
            <button
              class="waves-effect waves-light btn black right"
              :class="{
                disabled: isSubmitting,
              }"
              @click.prevent="handleBack"
            >
              <i class="material-icons left" title="go back">chevron_left</i>
              Back
            </button>

            <button
              class="waves-effect waves-light btn right"
              :class="{
                disabled: isSubmitting,
              }"
            >
              Update
              <i class="material-icons right" title="go back">check_circle</i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card-panel"
      :class="{ 'yellow lighten-5': editId == 'dependantAge' }"
    >
      <div class="input-field">
        <div class="row">
          <div
            :class="{
              'col s10': editId != 'dependantAge',
              'col s12': editId == 'dependantAge',
            }"
          >
            <span v-if="editId != 'dependantAge'">
              <h5>{{ dependantAge }} Years</h5>
            </span>
            <span v-else>
              <input
                id="dependantAge"
                type="number"
                v-model="dependantAge"
                class="validate"
                required
              />
            </span>
            <label class="active teal-text" for="dependantAge"
              >Dependant Age</label
            >
          </div>
          <div class="col s2" v-if="editId != 'dependantAge'">
            <span>
              <a
                class="btn-floating btn-medium waves-effect waves-light light-blue lighten-2 right"
                ><i
                  class="material-icons"
                  @click="handleEdit('dependantAge')"
                  title="edit"
                  >edit</i
                ></a
              >
            </span>
          </div>
          <div class="col s12 right" v-if="editId == 'dependantAge'">
            <button
              class="waves-effect waves-light btn black right"
              :class="{
                disabled: isSubmitting,
              }"
              @click.prevent="handleBack"
            >
              <i class="material-icons left" title="go back">chevron_left</i>
              Back
            </button>

            <button
              class="waves-effect waves-light btn right"
              :class="{
                disabled: isSubmitting,
              }"
            >
              Update
              <i class="material-icons right" title="go back">check_circle</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  </template>
</template>
    
<script>
import { onMounted, onUpdated, ref } from "vue";
import getItems from "@/composables/getItems";
import postItem from "@/composables/postItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import M from "materialize-css";

export default {
  name: "SettingsClientComponent",
  components: { LoadingDiv },
  props: ["cat", "clientId"],
  emits: ["next"],
  setup(props) {
    // Workmen
    const response = ref([]);
    const error = ref(null);
    const isSubmitting = ref(false);
    const isReady = ref(false);
    const editId = ref(null);
    const year = ref(null);
    const month = ref(null);

    // Rules table
    const policyDateMonth = ref(null);
    const policyDateYear = ref(null);
    const premiumCycle = ref(null);
    const familySize = ref(null);
    const principalAge = ref(null);
    const dependantAge = ref(null);

    // get settings
    const getItemsFromApi = () => {
      // set current Url
      let currUrl = "rules";
      // set current Hook
      let currHook = "?";

      // get items
      (async () => {
        const { res, err } = await getItems(
          `${currUrl}${currHook}q=${props.clientId}`
        );

        if (res.value) {
          // get
          response.value = res.value.data;
          // load form variables
          loadVariables();
        } else {
          error.value = err.value;
        }

        // ready
        isReady.value = true;
        // clear edit (if required)
        editId.value = null;
      })();
    };

    // start here
    onMounted(() => {
      // get settings
      getItemsFromApi();
    });

    // select
    onUpdated(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // handle form variables
    const loadVariables = () => {
      // clear all
      editId.value = null;
      policyDateMonth.value = null;
      policyDateYear.value = null;
      premiumCycle.value = null;
      familySize.value = null;
      principalAge.value = null;
      dependantAge.value = null;

      // set
      response.value.forEach((x) => {
        if (x.setting == "policyDate" && policyDateMonth.value == null) {
          // set
          policyDateMonth.value = x.value.toString().slice(4, 6);
          //set
          policyDateYear.value = x.value.toString().slice(0, 4);
          // year
          year.value = x.value.toString().slice(0, 4);
          // month
          month.value = x.value.toString().slice(4, 6);
        }
        if (x.setting == "premiumCycle" && premiumCycle.value == null) {
          // set
          premiumCycle.value = x.value;
        }

        if (x.setting == "familySize" && familySize.value == null) {
          // set
          familySize.value = x.value;
        }

        if (x.setting == "principalAge" && principalAge.value == null) {
          // set
          principalAge.value = x.value;
        }

        if (x.setting == "dependantAge" && dependantAge.value == null) {
          // set
          dependantAge.value = x.value;
        }
      });
    };

    // handle Edit
    const handleEdit = (val) => {
      editId.value = val;
    };

    // handle Back
    const handleBack = () => {
      // load form variables
      loadVariables();
    };

    // submit
    const handleSubmit = async () => {
      // start
      isSubmitting.value = true;
      error.value = false;

      // Materialize CSS handled all custome errors \\
      const newVal = [
        {
          setting: "policyDate",
          value: `${policyDateYear.value}${policyDateMonth.value}01`,
          scope: props.clientId,
        },
        {
          setting: "premiumCycle",
          value: premiumCycle.value,
          scope: props.clientId,
        },
        {
          setting: "familySize",
          value: familySize.value,
          scope: props.clientId,
        },
        {
          setting: "principalAge",
          value: principalAge.value,
          scope: props.clientId,
        },
        {
          setting: "dependantAge",
          value: dependantAge.value,
          scope: props.clientId,
        },
      ];

      // post item
      const { res, err } = await postItem("rules", newVal);

      if (res.value) {
        // next
        // get items
        isReady.value = false;
        // call settings
        getItemsFromApi();
      } else {
        error.value = err.value;
      }

      // end here
      isSubmitting.value = false;
    };

    return {
      error,
      isReady,
      editId,
      handleEdit,
      handleBack,
      isSubmitting,
      handleSubmit,
      year,
      month,
      policyDateMonth,
      policyDateYear,
      premiumCycle,
      familySize,
      principalAge,
      dependantAge,
    };
  },
};
</script>
    
<style scoped>
.pointer {
  cursor: default;
}
.grey-bg-custom {
  background-color: #f2f2f2;
}
.clickable {
  cursor: pointer;
}
</style>