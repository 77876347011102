<template>
  <div class="section">
    <form>
      <div class="row">
        <div class="col s5">
          <!-- <div class="card-panel"> -->
          <FilterDiv
            inputId="filterCategory"
            :data="filterCategoryData"
            :startAgain="resetCategory"
            @selected="handlePick"
            @searching="handleSearch"
            @clear="handleClear"
          />
          <!-- </div> -->
        </div>

        <div class="col s7">
          <!-- <div class="card-panel"> -->
          <FilterDiv
            inputId="filterClient"
            :data="filterClientData"
            :startAgain="resetClient"
            @selected="handlePick"
            @searching="handleSearch"
            @clear="handleClear"
          />
          <!-- </div> -->
        </div>
      </div>
    </form>
  </div>
</template>
    
  <script>
import { computed, onMounted, ref, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import FilterDiv from "@/components/search/FilterDiv.vue";

export default {
  name: "FilterInvoiceDiv",
  components: { FilterDiv },
  emits: ["search"],
  setup(props, context) {
    const responseSearch = ref(null);
    const responseCategoryDefault = ref(null);
    const resetCategory = ref(null);
    const resetClient = ref(null);
    const searchBody = ref([]);
    const selectedItem = ref(null);
    const selectedCategory = ref("");
    const selectedClient = ref("");

    // enrollee
    const filterCategoryData = computed(() => {
      // start
      let val = {
        "All Status": null,
      };

      let valPlus;

      if (responseCategoryDefault.value) {
        if (responseCategoryDefault.value.key == "filterCategory") {
          valPlus = responseCategoryDefault.value.value.reduce((newArr, x) => {
            newArr[x.cat] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // duration data
    const filterClientData = computed(() => {
      // start
      let val = {
        "All Clients": null,
      };

      let valPlus;

      if (responseSearch.value) {
        if (responseSearch.value.key == "filterClient") {
          valPlus = responseSearch.value.value.reduce((newArr, x) => {
            newArr[x.name] = null;
            return newArr;
          }, {});
          val = { ...val, ...valPlus };
        }
      }

      // return final
      return val;
    });

    // search function
    const getSearchItemsFromApi = (table, key) => {
      (async () => {
        const { res } = await getItems(table);
        if (res.value) {
          // search
          responseSearch.value = { key: key, value: res.value.data };
        }
      })();
    };

    // filter
    onMounted(() => {
      // initialize filterCategory
      let val = [
        { cat: "Upcoming", item: "" }, // this will search p & z
        { cat: "Unpaid (Approved)", item: "z" },
        { cat: "Paid", item: "c" },
        { cat: "Deleted", item: "d" },
      ];

      // set
      responseCategoryDefault.value = { key: "filterCategory", value: val };

      //set default
      resetCategory.value = "Upcoming";
      selectedItem.value = { id: "filterCategory", value: "Upcoming", special: true };
    });

    // enrollee, provider & client filter
    const handleSearch = (val) => {
      if (val.id == "filterCategory") {
        // pre-set
      } else if (val.id == "filterClient") {
        // get new serach items
        getSearchItemsFromApi(`client/filter?q=${val.value}`, val.id);
      }
    };

    // clear (null reset)
    const handleClear = (val) => {
      if (val.id == "filterCategory") {
        resetCategory.value = val.value;
      } else if (val.id == "filterClient") {
        resetClient.value = val.value;
      }
    };

    // selected item
    const handlePick = (val) => {
      selectedItem.value = val;
      // watchEffect will take over from here
    };

    // route composer
    watchEffect(() => {
      // token
      let searchToken = false;

      // start
      if (selectedItem.value) {
        // sort
        if (selectedItem.value.id == "filterCategory") {
          // set token
          searchToken = true;

          //set
          if (
            Object.keys(filterCategoryData.value)[0] == selectedItem.value.value
          ) {
            selectedCategory.value = "all";
            searchBody.value = searchBody.value.filter((x) => x !== "Status");
          } else {
            // update search body
            searchBody.value = searchBody.value.filter((x) => x !== "Status");
            searchBody.value.push("Status");
            // responseSearch will have all the complete data
            responseCategoryDefault.value.value.forEach((x) => {
              if (x.cat == selectedItem.value.value) {
                // found
                selectedCategory.value = x.item;
              }
            });
          }
        } else if (selectedItem.value.id == "filterClient") {
          // set token
          searchToken = true;

          //set
          if (
            Object.keys(filterClientData.value)[0] == selectedItem.value.value
          ) {
            selectedClient.value = "";
            searchBody.value = searchBody.value.filter((x) => x !== "Clients");
          } else {
            // update search body
            searchBody.value = searchBody.value.filter((x) => x !== "Clients");
            searchBody.value.push("Clients");
            // get client
            // responseSearch will have all the complete data
            responseSearch.value.value.forEach((x) => {
              if (x.name == selectedItem.value.value) {
                // found
                selectedClient.value = x.id;
              }
            });
          }
        }

        if (searchToken) {
          // start laoding
          let searchTitle = null;
          let currUrl = null;
          let currHook = null;

          // label
          if (selectedCategory.value || selectedClient.value) {
            let uniqueBody = [...new Set(searchBody.value)];
            searchTitle = `<span class="black blue-text chip">Search <i class="tiny material-icons">check_circle</i></span>`;
            uniqueBody.forEach((x) => {
              searchTitle += `<span class="yellow lighten-2 black-text chip"><em>${x}</em></span> `;
            });
            searchTitle = searchTitle.slice(0, -2);
          } else {
            searchTitle = null;
          }
          // set current Url
          currUrl = `group/invoices/group?s=${selectedCategory.value}&c=${selectedClient.value}`;
          // set current Hook
          currHook = "&";

          // emit new parameters
          context.emit("search", {
            searchTitle: searchTitle,
            currUrl: currUrl,
            currHook: currHook,
          });

          // kill token
          searchToken = false;
          // kill searchItem
          selectedItem.value = null;
        }
      }
    });

    return {
      handleSearch,
      handleClear,
      handlePick,
      selectedItem,
      resetCategory,
      resetClient,
      filterCategoryData,
      filterClientData,
      selectedCategory,
      selectedClient,
    };
  },
};
</script>
    
  <style>
</style>