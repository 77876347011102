<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
    <template v-if="currScheme"> </template>

    <template v-else>
      <div class="row">
        <div
          class="col s12 m12 l6 yellow lighten-5"
          v-for="scheme in schemes"
          :key="scheme.id"
        >
          <ul id="projects-collection" class="collection z-depth-4">
            <li class="collection-item avatar">
              <i class="material-icons cyan circle">check_circle</i>
              <h6 class="collection-header m-0">
                {{ scheme.name }}
              </h6>
              <p class="chip grey lighten-4">Schemes</p>
            </li>
            <li class="collection-item">
              <div class="row">
                <div class="col s9">
                  <p class="collections-title">
                    <strong
                      ><b>{{ scheme.encounters_count }}</b></strong
                    >
                    Encounters
                    <br />
                    <strong>₦{{ claimsTotal(scheme) }}</strong> Claims
                  </p>
                </div>
                <div class="col s3">
                  <span
                    class="spacer pointer blue-grey lighten-3 white-text"
                    title="Year of Interest"
                    >{{ scheme.year }}</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section center" v-if="!schemes.length">
        <h6>No Scheme Report <em>found</em>...</h6>
      </div>
    </template>
  </template>
</template>
      
    <script>
import { onMounted, ref, computed } from "vue";
import getItems from "@/composables/getItems";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "SchemesInsightComponent",
  components: {
    LoadingDiv,
  },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currScheme = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);

    // total claims
    const claimsTotal = (scheme) => {
      if (scheme?.claims) {
        return Number(scheme.claims)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "operator/insight/schemes";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    });

    // output results
    const schemes = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      claimsTotal,
      isReady,
      isLoading,
      error,
      currScheme,
      schemes,
    };
  },
};
</script>
      
  <style scoped>
.spacer {
  padding: 2px;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
      color: #000000;
      border: 1px solid #ddd;
      background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>