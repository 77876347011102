<template>
  <form @submit.prevent="handleSubmit">
    <div class="card-content">
      <div class="input-field">
        <textarea id="enrollees" class="materialize-textarea" v-model="enrolleeIds"></textarea>
          <label for="enrollees">Enrollee IDs</label>
      </div>
    </div>

    <div class="card grey lighten-5">
      <p v-if="uploadError"><br />{{ uploadError }}</p>

      <p>
        <button
          class="waves-effect waves-light btn-large"
          :class="{
            disabled: !file || isSubmitting,
          }"
        >
          <i class="material-icons right">upload</i
          >{{ isSubmitting ? `Uploading (${uploadProgress})` : "Upload" }}
        </button>
      </p>
    </div>
  </form>
</template>
    
  <script>
import { onMounted, ref } from "vue";
import { storage } from "@/firebase/config";
import { ref as sRef, uploadBytesResumable } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import M from "materialize-css";

export default {
  name: "EnrolleeRemoveSingleOnboardComponent",
  setup() {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const uploadError = ref(null);
    const tariffType = ref(null);
    const providerName = ref(null);
    const tariffName = ref(null);
    const isSubmitting = ref(false);
    const uploadProgress = ref("0%");
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      uploadProgress.value = "0%";
      providerName.value = null;
      tariffType.value = null;
      tariffName.value = null;
    };

    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
      providerName.value = null;
      tariffType.value = null;
      tariffName.value = null;
    };

    const handleData = () => {
      providerName.value = null;
      tariffName.value = null;
    };

    const handleSubmit = async () => {
      if (
        file.value &&
        tariffType.value &&
        (providerName.value || tariffName.value)
      ) {
        // Start Uploading
        isSubmitting.value = true;
        uploadError.value = null;

        // Initiate variables
        let assignedType = null;
        let assignedName = null;

        // Assign Tariff-Type
        if (tariffType.value == "public") {
          assignedType = 1; // social health scheme tariff // placeholders
        } else {
          assignedType = 2; // private health scheme tariff // placeholders
        }

        // Assign Tariff-Name
        if (providerName.value) {
          assignedName = providerName.value + " (Custom)";
        } else {
          assignedName = tariffName.value;
        }

        // Create the file metadata
        /** @type {any} */
        const metadata = {
          contentType: file.value.type,
        };

        // Set upload variables
        const bucketName = "easy-hmo.appspot.com";
        const imgName = "images/" + file.value.name;

        // Upload file and metadata to the object 'images/mountains.jpg'
        const storageRef = sRef(storage, imgName);
        const uploadTask = uploadBytesResumable(
          storageRef,
          file.value,
          metadata
        );

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            uploadProgress.value =
              Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              ) + "%";
            switch (snapshot.state) {
              case "paused":
                uploadProgress.value = "(paused)";
                //console.log('Upload is paused');
                break;
              case "running":
                //console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                uploadError.value =
                  "User doesn't have permission to access the object";
                break;
              case "storage/canceled":
                // User canceled the upload
                uploadError.value = "User canceled the upload";
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                uploadError.value =
                  "Unknown error occurred, inspect error.serverResponse";
                break;
            }
          },
          () => {
            // speak to the cloud
            const cloudFunctions = getFunctions();
            const tariffLoader = httpsCallable(cloudFunctions, "tariffLoader");
            tariffLoader({ bucketName, imgName, assignedType, assignedName })
              .then((result) => {
                // Read result of the Cloud Function.
                const data = result.data; // data.succ.Success, data.succ.inserted_rows, data.succ.Error

                if (data.err || data.succ.Error.includes("Failed")) {
                  throw new Error(data.err);
                }

                // Upload completed successfully
                clearForm();

                // Send to tariff view
                // router.push({
                //   name: "tariffView",
                //   params: { id: data.schedule },
                // });
              })
              .catch((error) => {
                // Getting the Error details. ${error.message}
                uploadError.value = `Something went wrong... ${error.message}`;
                clearForm();
              });
          }
        );
      } else {
        uploadError.value =
          "Something went wrong... Re-select File & Complete Form.";
        clearForm();
      }
    };

    return {
      handleFile,
      handleData,
      isSubmitting,
      handleSubmit,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      tariffType,
      tariffName,
      providerName,
    };
  },
};
</script>
    
  <style>
</style>