import { ref as vRef } from 'vue';
import { storage } from '@/firebase/config';
import { ref as fRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";


const useStorage = () => {
    const uploadProgress = vRef(null);
    const uploadError = vRef(null);
    const completed = vRef(false);
    const data = vRef(null);

    // upload function
    const uploadFile = (file, fx = null, cloudInput = null) => {
        // initials
        const filePath = "images/" + file.name;
        const metadata = {
            contentType: file.type,
        };

        // start process
        const storageRef = fRef(storage, filePath);
        const uploadTask = uploadBytesResumable(
            storageRef,
            file,
            metadata
        );

        // Listen for state changes
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress
                uploadProgress.value =
                    Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    ) + "%";
                switch (snapshot.state) {
                    case "paused":
                        uploadProgress.value = "(paused)";
                        break;
                    case "running":
                        break;
                }
            },
            (error) => {
                switch (error.code) {
                    case "storage/unauthorized":
                        // User doesn't have permission
                        uploadError.value =
                            "User doesn't have permission to access the object";
                        break;
                    case "storage/canceled":
                        // User canceled the upload
                        uploadError.value = "User canceled the upload";
                        break;

                    // ...

                    case "storage/unknown":
                        // Unknown error occurred
                        uploadError.value =
                            "Unknown error occurred, inspect error.serverResponse";
                        break;
                }
            },
            () => {

                if (cloudInput && fx) {
                    // initialize
                    const bucketName = "easy-hmo.appspot.com";
                    // speak to the cloud
                    const cloudFunctions = getFunctions();
                    const runFx = httpsCallable(cloudFunctions, fx);
                    runFx({ bucketName, filePath, cloudInput })
                        .then((result) => {
                            // Read result of the Cloud Function.
                            if (result.data.err || result.data.succ.Error.includes('Failed')) {
                                throw new Error(result.data.err);
                            }
                            // end
                            data.value = result.data;
                            completed.value = true;
                        })
                        .catch((error) => {
                            // Getting the Error details. ${error.message}
                            uploadError.value = `Something went wrong (Cloud Function)... ${error.message}`;
                        });

                } else {

                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref)
                        .then((downloadURL) => {
                            // end
                            data.value = downloadURL;
                            completed.value = true;
                        }).catch((error) => {
                            // Getting the Error details. ${error.message}
                            uploadError.value = `Something went wrong (URL Getter)... ${error.message}`;
                        });

                }

            }
        );

    };

    return { uploadFile, uploadProgress, uploadError, data, completed }

}

export default useStorage