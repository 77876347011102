<template>
  <!-- Auth -->
  <div class="card" v-for="claim in claims" :key="claim.id">
    <div class="card-panel">
      <div class="row">
        <div class="col s3">
          <h6>
            {{ claim.encounter.name }}
            <small
              ><span class="blue-text">{{
                showAgeSex(claim.encounter.dob, claim.encounter.sex)
              }}</span></small
            >
            <br />
            <small>{{ claim.encounter.enrollee_number }}</small>
            <br />
            <small>({{ claim.encounter.client_name }})</small>
          </h6>
        </div>
        <div class="col s2">
          <p>{{ claim.encounter.provider.name }}</p>
        </div>
        <div class="col s2">
          <p>
            <b>{{ claim.encounter.diagnosis }}</b>
          </p>
        </div>
        <div class="col s2">
          <p>
            {{ claim.item }}
            <small>
              <span class="black-text"
                >(<b>{{ claim.sent_qty }}</b
                >)</span
              ></small
            ><br />
            <span
              v-if="claim.sent_price"
              :class="{
                'amber-text text-darken-4': claim.authorization === 'z' && claim.status === 'a',
                'black-text': !(claim.authorization === 'z' && claim.status === 'a'),
              }"
              >₦{{
                Number(claim.sent_price)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </span>
          </p>
        </div>
        <div class="col s2">
          <p>
            <span v-if="claim.authorization === 'z'">
              <span class="chip" v-if="claim.status === 'a'">Pending</span>
              <span class="chip red lighten-2" v-else>Declined</span>
            </span>
            
            <span v-else
              >{{ claim.authorization_code }}<br />
              <span class="chip green lighten-2" v-if="claim.sent_price"
                ><b>₦{{
                  Number(claim.sent_price)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}</b>
              </span>
              </span
            >
          </p>
        </div>
        <div class="col s1">
          <p
            class="right"
            :class="{
              'black-text': claim.authorization === 'z',
              'green-text': claim.authorization !== 'z',
            }"
          >
            {{
              new Date(claim.created_at).toLocaleString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Loading -->
  <div class="progress" v-if="isLoading">
    <div class="indeterminate"></div>
  </div>
  <!-- No Auth Found -->
  <div class="section center" v-if="!claims.length && !isLoading">
    <h6 class="chip">No Previous Authorization <em>found</em>...</h6>
  </div>
</template>
      
<script>
import { ref, computed, onMounted } from "vue";
import { ageSex } from "@/composables/fxLibrary/coreFx";
import getItems from "@/composables/getItems";

export default {
  name: "HistoryAuthComponent",
  props: ["enrolleeNumber"],
  setup(props) {
    const response = ref(null);
    const error = ref(false);
    const isLoading = ref(false);
    const nextLoad = ref(null);

    // infinite scroll
    onMounted(() => {
      const handleScroll = () => {
        const scrollY = window.scrollY;
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = window.innerHeight;

        // Check if user is near the bottom of the page
        if (
          scrollY + clientHeight >= scrollHeight - 100 &&
          nextLoad.value &&
          !isLoading.value
        ) {
          // Trigger your data loading logic here
          handlePage(nextLoad.value);
        }
      };

      window.addEventListener("scroll", handleScroll);

      // Remember to remove the listener on component unmount for proper cleanup (optional)
      return () => window.removeEventListener("scroll", handleScroll);
    });

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          if (response.value) {
            response.value = [...response.value, ...res.value.data.data];
          } else {
            response.value = res.value.data.data;
          }

          if (res.value.data.last_page > res.value.data.current_page) {
            nextLoad.value = res.value.data.current_page + 1;
          } else {
            nextLoad.value = null;
          }
        } else {
          error.value = err.value;
        }

        // loading is complete
        isLoading.value = false;
      })();
    };

    // start
    onMounted(() => {
      // start
      handlePage(nextLoad.value);
    });

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(
        `claim/auth/enrollee?e=${props.enrolleeNumber}&page=${page}`
      );
    };

    // output results
    const claims = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      showAgeSex,
      error,
      isLoading,
      claims,
    };
  },
};
</script>
    
<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>