<template>
  <LoadingDiv v-if="!isReady" />

  <template v-else>
  <div class="grey-bg-custom">
    <div class="container">
      <div class="row">
        <div class="col s12">
          <!-- <h1 class="teal-text">
            <i class="medium material-icons">filter_1</i> HMO
          </h1> -->

          <form @submit.prevent="handleSubmit">
            <div class="section">
              <h5>General Information</h5>
              <p class="red-text">*Section is compulsory.</p>

              <br />

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="hmoName"
                    type="text"
                    v-model="hmoName"
                    class="validate"
                    required
                  />
                  <label class="active" for="hmoName">HMO Name</label>
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="hmoSlogan"
                    type="text"
                    v-model="hmoSlogan"
                    class="validate"
                    required
                  />
                  <label class="active" for="hmoSlogan">HMO Slogan</label>
                </div>
              </div>

              <br />

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="email"
                    type="email"
                    v-model="email"
                    class="validate"
                    required
                  />
                  <label class="active" for="email">Email (Main)</label>
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    type="text"
                    v-model="phone"
                    pattern="[0-9]{10,13}"
                    class="validate"
                    required
                  />
                  <label class="active" for="phone"
                    >Phone (Main) [*Mobile]</label
                  >
                </div>
              </div>

              <br />

              <div class="card-panel">
                <div class="input-field">
                  <input
                    type="text"
                    v-model="addressHq"
                    class="validate"
                    required
                  />
                  <label class="active" for="addressHq">Address (HQ)</label>
                </div>
              </div>

              <br />

              <div class="card-panel">
                <div class="input-field">
                  <textarea
                    id="aboutHmo"
                    class="validate materialize-textarea"
                    v-model="aboutHmo"
                    required
                  ></textarea>
                  <label class="active" for="aboutHmo">About HMO</label>
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <textarea
                    id="generalNote"
                    class="validate materialize-textarea"
                    v-model="generalNote"
                    required
                  ></textarea>
                  <label class="active" for="generalNote"
                    >General Note (Others)</label
                  >
                </div>
              </div>
            </div>

            <div class="section">
              <h5>Management Contacts</h5>

              <p>
                <span
                  class="chip orange lighten-3 black-text clickable secondary-content"
                  v-if="email && phone"
                  @click="handleCopy('md')"
                  >Copy (Main) Email & Phone</span
                >
                <br />
              </p>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="emailMd"
                    type="email"
                    v-model="emailMd"
                    class="validate"
                  />
                  <label class="active" for="emailMd">Email (MD)</label>
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="phoneMd"
                    type="text"
                    v-model="phoneMd"
                    pattern="[0-9]{10,13}"
                    class="validate"
                  />
                  <label class="active" for="phoneMd"
                    >Phone (MD) [*Mobile]</label
                  >
                </div>
              </div>
            </div>

            <div class="section">
              <h5>Admin (IT) Contacts</h5>

              <p>
                <span
                  class="chip orange lighten-3 black-text clickable secondary-content"
                  v-if="email && phone"
                  @click="handleCopy('admin')"
                  >Copy (Main) Email & Phone</span
                >
                <br />
              </p>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="emailSuperAdmin"
                    type="email"
                    v-model="emailSuperAdmin"
                    class="validate"
                  />
                  <label class="active" for="emailSuperAdmin"
                    >Email (Admin)</label
                  >
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="phoneSuperAdmin"
                    type="text"
                    v-model="phoneSuperAdmin"
                    pattern="[0-9]{10,13}"
                    class="validate"
                  />
                  <label class="active" for="phoneSuperAdmin"
                    >Phone (Admin)</label
                  >
                </div>
              </div>
            </div>

            <div class="section">
              <h5>
                Customer Care
                Contacts
              </h5>

              <p>
                <span
                  class="chip orange lighten-3 black-text clickable secondary-content"
                  v-if="email && phone"
                  @click="handleCopy('care')"
                  >Copy (Main) Email & Phone</span
                >
                <br />
              </p>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="emailCustomerCare"
                    type="email"
                    v-model="emailCustomerCare"
                    class="validate"
                  />
                  <label class="active" for="emailCustomerCare"
                    >Email (Customer Care)</label
                  >
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="phoneCustomerCare"
                    type="text"
                    v-model="phoneCustomerCare"
                    pattern="[0-9]{10,13}"
                    class="validate"
                  />
                  <label class="active" for="phoneCustomerCare"
                    >Phone (Customer Care) [*Mobile]</label
                  >
                </div>
              </div>
            </div>

            <div class="section">
              <h5>Finance Contacts</h5>

              <p>
                <span
                  class="chip orange lighten-3 black-text clickable secondary-content" 
                  v-if="email && phone"
                  @click="handleCopy('finance')"
                  >Copy (Main) Email & Phone</span
                >
                <br />
              </p>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="emailFinance"
                    type="email"
                    v-model="emailFinance"
                    class="validate"
                  />
                  <label class="active" for="emailFinance"
                    >Email (Finance)</label
                  >
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="phoneFinance"
                    type="text"
                    v-model="phoneFinance"
                    pattern="[0-9]{10,13}"
                    class="validate"
                  />
                  <label class="active" for="phoneFinance"
                    >Phone (Finance) [*Mobile]</label
                  >
                </div>
              </div>
            </div>

            <div class="section">
              <h5>Backup Contacts</h5>

              <p>
                <span
                  class="chip orange lighten-3 black-text clickable secondary-content" 
                  v-if="email && phone"
                  @click="handleCopy('backup')"
                  >Copy (Main) Email & Phone</span
                >
                <br />
              </p>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="emailBackup"
                    type="email"
                    v-model="emailBackup"
                    class="validate"
                  />
                  <label class="active" for="emailBackup">Email (Backup)</label>
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input
                    id="phoneBackup"
                    type="text"
                    v-model="phoneBackup"
                    pattern="[0-9]{10,13}"
                    class="validate"
                  />
                  <label class="active" for="phoneBackup"
                    >Phone (Backup) [*Mobile]</label
                  >
                </div>
              </div>
            </div>

            <div class="section">
              <h5>Web Assets</h5>

              <br />

              <div class="card-panel">
                <div class="input-field">
                  <input id="logoUrl" type="text" v-model="logoUrl" />
                  <label class="active" for="logoUrl">Logo (link)</label>
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input id="webAddress" type="text" v-model="webAddress" />
                  <label class="active" for="webAddress">Website (link)</label>
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <textarea
                    id="socialHandles"
                    class="materialize-textarea"
                    v-model="socialHandles"
                  ></textarea>
                  <label class="active" for="socialHandles"
                    >Social Handles</label
                  >
                </div>
              </div>
            </div>

            <div class="section">
              <h5>Application Assets</h5>

              <br />

              <div class="card-panel">
                <div class="input-field">
                  <input readonly type="text" v-model="easyServer" />
                  <label class="active" for="disabled"
                    >Easy Server (link)</label
                  >
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input readonly type="text" v-model="easyAdmin" />
                  <label class="active" for="disabled">Easy Admin (link)</label>
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input readonly type="text" v-model="easyProvider" />
                  <label class="active" for="disabled"
                    >Easy Provider (link)</label
                  >
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input readonly type="text" v-model="easyClient" />
                  <label class="active" for="disabled"
                    >Easy Client (link)</label
                  >
                </div>
              </div>

              <div class="card-panel">
                <div class="input-field">
                  <input readonly type="text" v-model="easyUser" />
                  <label class="active" for="disabled">Easy User (link)</label>
                </div>
              </div>
            </div>

            <div class="section">
              <button
                class="waves-effect waves-light btn-large right"
                :class="{
                  disabled: isSubmitting,
                }"
              >
                <i
                  class="material-icons white-text right"
                  >call_made</i
                >{{ isSubmitting ? `Updating...` : "Submit" }}
              </button>
            </div>
          </form>

          <p><br /><br /></p>
        </div>
      </div>
    </div>

    <!-- <footer class="page-footer grey lighten-3">
      <div class="footer-copyright">
        <div class="container black-text">© 2024 easyHMO.com.ng</div>
      </div>
    </footer> -->

    </div>
  </template>
</template>
  
<script>
import { onMounted, ref } from "vue";
import getItems from "@/composables/getItems";
import putItem from "@/composables/putItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import M from "materialize-css";

export default {
  name: "HmoComponent",
  components: { LoadingDiv },
  emits: ["next"],
  setup(props, context) {
    // Workmen
    const response = ref(null);
    const error = ref(null);
    const isSubmitting = ref(false);
    const isReady = ref(false);

    // Owner's table
    const hmoName = ref(null);
    const hmoSlogan = ref(null);
    const email = ref(null);
    const emailMd = ref(null);
    const emailBackup = ref(null);
    const emailCustomerCare = ref(null);
    const emailFinance = ref(null);
    const emailSuperAdmin = ref(null);
    const phone = ref(null);
    const phoneMd = ref(null);
    const phoneBackup = ref(null);
    const phoneCustomerCare = ref(null);
    const phoneFinance = ref(null);
    const phoneSuperAdmin = ref(null);
    const addressHq = ref(null);
    const aboutHmo = ref(null);
    const generalNote = ref(null);
    const logoUrl = ref(null);
    const webAddress = ref(null);
    const socialHandles = ref(null);
    const easyServer = ref(null);
    const easyAdmin = ref(null);
    const easyProvider = ref(null);
    const easyClient = ref(null);
    const easyUser = ref(null);
    const wallet = ref(null);
    const spent = ref(null);
    const total = ref(null);
    const status = ref(null);

    // start here
    onMounted(() => {
      // get items
      (async () => {
        const { res, err } = await getItems("owner");

        if (res.value) {
          response.value = res.value;

          // assign
          hmoName.value = response.value.data.hmo_name;
          hmoSlogan.value = response.value.data.hmo_slogan;
          email.value = response.value.data.email;
          emailMd.value = response.value.data.email_md;
          emailBackup.value = response.value.data.email_backup;
          emailCustomerCare.value = response.value.data.email_customer_care;
          emailFinance.value = response.value.data.email_finance;
          emailSuperAdmin.value = response.value.data.email_super_admin;
          phone.value = response.value.data.phone;
          phoneMd.value = response.value.data.phone_md;
          phoneBackup.value = response.value.data.phone_backup;
          phoneCustomerCare.value = response.value.data.phone_customer_care;
          phoneFinance.value = response.value.data.phone_finance;
          phoneSuperAdmin.value = response.value.data.phone_super_admin;
          addressHq.value = response.value.data.address_hq;
          aboutHmo.value = response.value.data.about_hmo;
          generalNote.value = response.value.data.general_note;
          logoUrl.value = response.value.data.logo_url;
          webAddress.value = response.value.data.web_address;
          socialHandles.value = response.value.data.social_handles;
          easyServer.value = response.value.data.easy_server;
          easyAdmin.value = response.value.data.easy_admin;
          easyProvider.value = response.value.data.easy_provider;
          easyClient.value = response.value.data.easy_client;
          easyUser.value = response.value.data.easy_user;
          wallet.value = response.value.data.wallet;
          spent.value = response.value.data.spent;
          total.value = response.value.data.total;
          status.value = response.value.data.status;

          // is now Ready
          isReady.value = true;
        } else {
          error.value = err.value;
        }
      })();
    });

    // select dropdown
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // copy
    const handleCopy = (type) => {
      // start
      if (type == "md") {
        emailMd.value = email.value;
        phoneMd.value = phone.value;
      } else if (type == "admin") {
        emailSuperAdmin.value = email.value;
        phoneSuperAdmin.value = phone.value;
      } else if (type == "care") {
        emailCustomerCare.value = email.value;
        phoneCustomerCare.value = phone.value;
      } else if (type == "finance") {
        emailFinance.value = email.value;
        phoneFinance.value = phone.value;
      } else if (type == "backup") {
        emailBackup.value = email.value;
        phoneBackup.value = phone.value;
      }
    };

    // submit
    const handleSubmit = async () => {
      // start
      isSubmitting.value = true;
      error.value = false;

      // Materialize CSS handled all custome errors \\

      // new status
      let newStatus = status.value;
      if (newStatus != "d") {
        newStatus = "a";
      }

      // move
      const val = {
        hmo_name: hmoName.value,
        hmo_slogan: hmoSlogan.value,
        email: email.value,
        email_md: emailMd.value,
        email_backup: emailBackup.value,
        email_customer_care: emailCustomerCare.value,
        email_finance: emailFinance.value,
        email_super_admin: emailSuperAdmin.value,
        phone: phone.value,
        phone_md: phoneMd.value,
        phone_backup: phoneBackup.value,
        phone_customer_care: phoneCustomerCare.value,
        phone_finance: phoneFinance.value,
        phone_super_admin: phoneSuperAdmin.value,
        address_hq: addressHq.value,
        about_hmo: aboutHmo.value,
        general_note: generalNote.value,
        logo_url: logoUrl.value,
        web_address: webAddress.value,
        social_handles: socialHandles.value,
        status: newStatus,
      };

      // post item
      const { res, err } = await putItem("owner", 1, val);

      if (res.value) {
        response.value = res.value;
        // next
        // emit new parameters
        context.emit("next", {
          step: 1,
          isComplete: true,
        });

      } else {
        error.value = err.value;
      }

      // end here
      isSubmitting.value = false;
    };

    return {
      error,
      isReady,
      isSubmitting,
      handleCopy,
      handleSubmit,
      hmoName,
      hmoSlogan,
      email,
      emailMd,
      emailBackup,
      emailCustomerCare,
      emailFinance,
      emailSuperAdmin,
      phone,
      phoneMd,
      phoneBackup,
      phoneCustomerCare,
      phoneFinance,
      phoneSuperAdmin,
      addressHq,
      aboutHmo,
      generalNote,
      logoUrl,
      webAddress,
      socialHandles,
      easyServer,
      easyAdmin,
      easyProvider,
      easyClient,
      easyUser,
    };
  },
};
</script>
  
  <style scoped>
.grey-bg-custom {
  background-color: #f2f2f2;
}
.clickable {
  cursor: pointer;
}
</style>