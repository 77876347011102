<template>
  <!-- Loader -->
  <div class="progress" v-if="!isReady">
    <div class="indeterminate"></div>
  </div>

  <div class="card">
    <div class="card-content teal lighten-5" v-if="success">
      <div class="input-field">
        <h6 class="grey-text darken-5 center">
          {{ success }} <i class="material-icons">check</i>
        </h6>
      </div>
    </div>

    <form @submit.prevent="handleSubmit">
      <div class="card-content">
        <div class="row">
          <div class="col s10">
            <div class="file-field input-field">
              <div class="btn grey">
                <span>Change</span>
                <input type="file" @change="handleFile" />
              </div>
              <div class="file-path-wrapper">
                <input class="file-path" v-model="inputFile" type="text" />
                <p v-if="fileError">{{ fileError }}</p>
              </div>
            </div>
          </div>
          <div class="col s2">
          <LoadingSmallDiv v-if="isLoading" />
          <span v-else>
            <img
              :src="img"
              width="64"
              class="circle responsive-img"
              v-if="img"
            />
              <img
                src="https://ui-avatars.com/api/?name=P&size=128"
                width="64"
                class="circle responsive-img"
                v-else
              />
          </span>
        </div>
        </div>
      </div>

      <div class="card-panel">
        <div class="input-field">
          <input type="text" v-model="name" class="validate" required />
          <label class="active teal-text" for="name">Principal Name</label>
        </div>
      </div>

      <div class="card-panel">
        <div class="input-field">
          <label class="active teal-text" for="sex">Sex</label>
          <select id="sex" v-model="sex" class="validate" required>
            <option value="m">Male</option>
            <option value="f">Female</option>
          </select>
        </div>
      </div>

      <div class="card-panel">
        <div class="input-field">
          <input
            type="text"
            v-model="dob"
            class="datepicker validate"
            required
          />
          <label class="active teal-text" for="dob">Date of Birth</label>
        </div>
      </div>

      <div class="card-panel">
        <div class="input-field">
          <input type="email" v-model="email" class="validate" />
          <label class="active teal-text" for="email">Email</label>
        </div>
      </div>

      <div class="card-panel">
        <div class="input-field">
          <input type="text" v-model="phone" class="validate" required />
          <label class="active teal-text" for="phone">Phone</label>
        </div>
      </div>

      <div class="card-panel">
        <div class="input-field">
          <input type="text" v-model="address" class="validate" required />
          <label class="active teal-text" for="address">Address</label>
        </div>
      </div>

      <div class="card-panel">
        <div class="input-field">
          <label class="active teal-text" for="plan">Plan</label>
          <select id="plan" v-model="plan" class="validate" required>
            <option v-for="plan in plans" :key="plan.id" :value="plan.id">
              {{ plan.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- load provider select -->
      <ProviderDiv
        :userPlanPoints="userPlanPoints"
        @update="handleUpdate"
        v-if="plan && cat == 'tpa'"
      />

      <div class="card-panel">
        <div class="input-field">
          <label class="active teal-text" for="type">Type</label>
          <select id="type" v-model="type" class="validate" required>
            <option value="i">Individual</option>
            <option value="f">Family</option>
          </select>
        </div>
      </div>

      <div class="card grey lighten-5">
        <p v-if="error"><br />{{ error }}</p>

        <p>
          <button
            class="waves-effect waves-light btn-large"
            :class="{
              disabled:
                !name || !sex || !plan || !type || !isReady || isSubmitting,
            }"
          >
            <i class="material-icons right">check_circle</i
            >{{ isSubmitting ? "Adding" : "Add Principal" }}
          </button>
        </p>
      </div>
    </form>
  </div>
</template>
  
<script>
import { onMounted, onUpdated, ref, watchEffect, computed } from "vue";
import postItem from "@/composables/postItem";
import getItemsOfItems from "@/composables/getItemsOfItems";
import useStorage from "@/composables/useStorage";
import ProviderDiv from "@/components/client/tpa/ProviderSelectTPAComponent.vue";
import LoadingSmallDiv from "@/components/LoadingSmallDiv.vue";
import M from "materialize-css";

export default {
  name: "EnrolleeSingleOnboardComponent",
  components: { LoadingSmallDiv, ProviderDiv },
  props: ["cat", "clientId"],
  setup(props) {
    const response = ref(null);
    const userPlanPoints = ref(null);
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const name = ref(null);
    const sex = ref(null);
    const dob = ref(null);
    const email = ref(null);
    const phone = ref(null);
    const address = ref(null);
    const plan = ref(null);
    const provider = ref(0);
    const type = ref(null);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const success = ref(null);
    const error = ref(null);
    const img = ref(null);
    const types = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // get function
    const getItemsFromApi = (table, match) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, match);

        if (res.value) {
          // suspend as per cat
          if (props.cat != "tpa") {
            response.value = res.value.data.filter(
              (x) => Array.isArray(x.premiums) && x.premiums[0]?.amount > 0
            ); // remove suspended (zeros)
          } else {
            response.value = res.value.data.filter(
              (x) => Array.isArray(x.premiums) && x.premiums[0]?.amount == 0
            ); // remove suspended (premium amounts)
          }
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // start from the top
      getItemsFromApi("plan/client/plans", props.clientId);
    });

    // select
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});

      // activate date
      const elemsDate = document.querySelectorAll(".datepicker");
      M.Datepicker.init(elemsDate, {
        onSelect: function (date) {
          dob.value = date;
        },
      });
    });

    // select
    onUpdated(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

     // clear
     const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      isLoading.value = false;
      uploadProgress.value = "0%";
    };

    // restart
    const resetForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      isLoading.value = false;
      uploadProgress.value = "0%";
      name.value = null;
      sex.value = null;
      dob.value = null;
      img.value = null;
      email.value = null;
      phone.value = null;
      address.value = null;
      plan.value = null;
      type.value = null;
    };

    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Image (.JPG, .PNG, .GIF)";
      }
    };

    const handleData = () => {
      // console.log("data");
    };

    // update provider
    const handleUpdate = (val) => {
      if (val) {
        provider.value = val;
      } else {
        provider.value = 0;
      }
    };

    //hand
    watchEffect(() => {
      if (plan.value) {
        // start ploading
        let userPlan = plans.value.filter((x) => x.id == plan.value);
        let userPlanPointsArray = userPlan[0].premiums
          .filter((x) => x.scope === 0 && x.type === "i")
          .reduce((a, b) =>
            new Date(a.created_at) > new Date(b.created_at) ? a : b
          );
        // finalize
        userPlanPoints.value = userPlanPointsArray?.amount;
      } else {
        // null
        userPlanPoints.value = null;
      }
    });

    //hand
    watchEffect(() => {
      if (file.value) {
        // start ploading
        isLoading.value = true;
        uploadError.value = null;

        // upload image
        uploadFile(file.value);
      }
    });

    //handle submit
    const handleSubmit = async () => {
      // Materialize CSS handled all custome errors \\

      // start
      isSubmitting.value = true;
      error.value = false;
      uploadProgress.value = false;

      // re-format date
      let newDate = new Date(dob.value);
      newDate = newDate
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");

      // variable
      const newVal = {
        name: name.value,
        sex: sex.value,
        dob: newDate,
        email: email.value,
        phone: phone.value,
        address: address.value,
        profile_img: img.value,
        plan_id: plan.value,
        provider_id: provider.value,
        client_id: props.clientId,
        type: type.value,
      };

      // post item
      const { res, err } = await postItem("principal", newVal);

      if (res.value) {
        // start
        success.value = `Principal (${res.value.data.name}) added! - Lobby`;
        resetForm();
      } else {
        error.value = err.value;
      }
    };

    // watch tariff upload
    watchEffect(() => {
      if (completed.value) {
        // set image
        img.value = data.value;
            // clear
            clearForm();
        // data.succ.Success, data.succ.inserted_rows, data.succ.Error
        // console.log(data); // might not be necessary
      }
      if (uploadError.value) {
        // clear
        clearForm();
      }
    });

    // all enrollees
    const plans = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleFile,
      handleData,
      handleUpdate,
      handleSubmit,
      isReady,
      isSubmitting,
      isLoading,
      success,
      error,
      uploadProgress,
      fileError,
      uploadError,
      file,
      inputFile,
      userPlanPoints,
      img,
      name,
      sex,
      dob,
      email,
      phone,
      address,
      type,
      provider,
      plan,
      plans,
    };
  },
};
</script>
    
<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>