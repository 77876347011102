<template>
  <div class="row">
    <div class="col s12">
      <p v-if="success">{{ success }}</p>
      <form @submit.prevent="handleSubmit">
        <div class="card-panel">
          <div class="file-field input-field">
            <div class="btn grey darken-1">
              <span>New Tariff</span>
              <input type="file" @change="handleFile" />
            </div>
            <div class="file-path-wrapper">
              <input class="file-path" v-model="inputFile" type="text" />
              <p v-if="fileError">{{ fileError }}</p>
            </div>
          </div>
        </div>

        <div class="card-panel">
          <div class="input-field">
            <select v-model="plan">
              <option value="" disabled selected>Select Plan Type</option>
              <option value="2">LifeBlue</option>
              <option value="1">LifeOrange</option>
              <option value="4">LifeGreen</option>
            </select>
            <label>Type of Plan (Enrollee)</label>
          </div>
        </div>

        <p v-if="uploadError"><br />{{ uploadError }}</p>

        <p>
          <br />
          <button
            class="waves-effect waves-light btn-large"
            :class="{
              disabled: !inputFile || !plan || isSubmitting,
            }"
          >
            <i class="material-icons right">upload</i
            >{{ isSubmitting ? `Uploading (${uploadProgress})` : "Upload" }}
          </button>
        </p>
      </form>
    </div>
  </div>
</template>
  
<script>
import { onMounted, ref, watchEffect } from "vue";
import useStorage from "@/composables/useStorage";
import M from "materialize-css";

export default {
  name: "AddTariffComponent",
  props: ["provider"],
  emits: ["update"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const plan = ref(null);
    const success = ref(null);
    const isSubmitting = ref(false);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // drop down
    onMounted(() => {
      // activate select field
      const elemsSelect = document.querySelectorAll("select");
      M.FormSelect.init(elemsSelect, {});
    });

    // clear
    const clearForm = () => {
      file.value = null; //clear file value
      inputFile.value = null; // Clear the file input value
      isSubmitting.value = false;
      uploadProgress.value = "0%";
    };

    // handle file
    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    // submit
    const handleSubmit = () => {
      if (file.value && plan.value) {
        // Start Uploading
        isSubmitting.value = true;
        uploadError.value = null;

        // Cloud Function
        const fx = "tariffLoader";

        // Start
        let schedule = {
          plan_id: plan.value,
          type: "c", // c=customized, s=standard
        };

        // Round up
        const cloudInput = {
          schedule: schedule,
          provider: props.provider.id,
          newProvider: null,
        };

        // upload
        uploadFile(file.value, fx, cloudInput);
      } else {
        // error
        clearForm();
      }
    };

    // watch tariff upload
    watchEffect(() => {
      if (completed.value) {
        // clear
        clearForm();

        // data.succ.Success, data.succ.inserted_rows, data.succ.Error
        success.value = `${data.value.succ.Success}. ${data.value.succ.inserted_rows}`;

        // tariff update
        context.emit("update", true);
      }
      if (uploadError.value) {
        // clear
        clearForm();
      }
    });

    return {
      isSubmitting,
      handleFile,
      handleSubmit,
      completed,
      uploadProgress,
      fileError,
      uploadError,
      plan,
      success,
      file,
      inputFile,
    };
  },
};
</script>
  
<style>
</style>