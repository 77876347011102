<template>
  <div class="container">
    <div class="card-content">
      <a href="" @click.prevent="handleBack">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >show_chart</i
        >
      </a>
      <h3 class="header blue-grey-text text-darken-2"><small>Insights</small></h3>
    </div>

    <div class="center">
      <span
        v-for="tab in tabs"
        :key="tab.id"
        :class="{
          'tab-custom-active': tab.id == currTab,
          'tab-custom': tab.id != currTab,
        }"
        @click="handleActive(tab.id)"
      >
        {{ tab.title }}
      </span>
      <br /><br /><br />
    </div>

    <!-- Clients -->
    <ClientsInsight v-if="currTab == 1" />
    <!-- Schemes -->
    <SchemesInsight v-if="currTab == 2" />
    <!-- Report -->
    <ReportInsight v-if="currTab == 3" />
  </div>
</template>
  
<script>
import { onMounted, ref } from "vue";
import ClientsInsight from "@/components/insight/ClientsInsightComponent.vue";
import SchemesInsight from "@/components/insight/SchemesInsightComponent.vue";
import ReportInsight from "@/components/insight/ReportInsightComponent.vue";

export default {
  name: "reportHome",
  components: { ClientsInsight, SchemesInsight, ReportInsight },
  setup() {
    const currTab = ref(null);
    const tabs = ref([]);

    // start
    onMounted(() => {
      // page name
      document.title = "Insights | EasyHMO";
    });

    // handle back
    const handleBack = () => {
      // restart menu
      currTab.value = 1;
    };

    // start
    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, title: "Clients" });
      tabs.value.push({ id: 2, title: "Schemes" });
      tabs.value.push({ id: 3, title: "Quarterly Report" });
      // set default
      currTab.value = 1;
    });

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currTab.value = tab;
    };

    return {
      handleActive,
      handleBack,
      currTab,
      tabs,
    };
  },
};
</script>
  
<style scoped>
.spacer {
  padding: 2px;
}

.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
  color: #000000;
  border: 1px solid #ddd;
  background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>