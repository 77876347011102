<template>
  <div
    class="progress"
    :class="{ red: status == 'suspend', blue: status == 'restore' }"
  >
    <div class="indeterminate"></div>
  </div>
</template>
        
<script>
import { ref, onMounted } from "vue";
import getItemsOfItemsOfItems from "@/composables/getItemsOfItemsOfItems";
import postItem from "@/composables/postItem";

export default {
  name: "PolicyReviewEditOnboardComponent",
  props: ["cat", "policy", "client"],
  emits: ["reset"],
  setup(props, context) {
    const status = ref(null);

    // get current premiums
    const getPremium = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      return val;
    };

    // get previous premiums
    const getPreviousPremium = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && x.amount > 0 && !val) {
          // the first real amount
          val = x.amount;
        }
      });
      return val;
    };

    // handle back
    const handleBack = () => {
      context.emit("reset", null);
    };

    // starter
    onMounted(() => {
      // get policies
      // initiate final amount
      let newIndividualAmount;
      let newFamilyAmount;

      (async () => {
        const { res } = await getItemsOfItemsOfItems(
          "premium/plan/search",
          props.policy.id,
          props.client.id
        );

        if (res.value) {
          // current premium
          let premiumIndividual = getPremium(res.value.data, "i");
          let premiumFamily = getPremium(res.value.data, "f");

          // previous premium
          let previousIndividual = getPreviousPremium(res.value.data, "i");
          let previousFamily = getPreviousPremium(res.value.data, "f");

          if (premiumIndividual == 0 && premiumFamily == 0) {
            // restore || suspend
            if (props.cat != "tpa") {
              status.value = "restore";
            } else {
              status.value = "suspend";
            }
            // assignments
            newIndividualAmount = previousIndividual;
            newFamilyAmount = previousFamily;
          } else {
            // restore || suspend
            if (props.cat != "tpa") {
              status.value = "suspend";
            } else {
              status.value = "restore";
            }
            // assignments
            newIndividualAmount = 0;
            newFamilyAmount = 0;
          }

          // ship
          handleUpdate(newIndividualAmount, newFamilyAmount);
        } else {
          handleBack();
        }
      })();
    });

    // handle add
    const handleUpdate = async (iVal, fVal) => {
      // initiate for update
      let plan = null;
      let individual = null;
      let family = null;
      let scope = props.client.id; // get client id from props

      // new plan
      plan = {
        id: props.policy.id, // use 1 for testing
      };

      // update individual premium
      individual = {
        plan_id: null, // update this on server
        amount: iVal,
        scope: scope,
        type: "i",
      };

      // update family premium
      family = {
        plan_id: null, // update this on serve
        amount: fVal,
        scope: scope,
        type: "f",
      };

      // round up
      const newVal = {
        plan: plan,
        individual: individual,
        family: family,
        scope: scope,
        action: "client",
        benefits: [], // empty array (no Benefit change required)
      };

      // post item
      const { res } = await postItem("plan/benefits/bulk", newVal);

      if (res.value) {
        context.emit("reset", res.value.data);
      } else {
        handleBack();
      }
    };

    return {
      status,
    };
  },
};
</script>
        

<style scoped>
.progress {
  margin-top: -14px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>