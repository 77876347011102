<template>
  <div class="card grey lighten-4">

    <!-- Premium (HMO, Retail only)-->
    <div class="card-panel teal-text" v-if="cat != 'tpa'">
      <div class="row">
        <div class="col s12">
          <span class="black-text">Premiums</span>
        </div>
      </div>
      <div class="row">
        <div class="col s1"><span class="chip">₦</span></div>
        <div
          class="col s4"
          :class="{
            'red lighten-5': error && !premiumIndividual,
          }"
        >
          <div class="input-field">
            <input
              type="number"
              id="premiumIndividual"
              v-model="premiumIndividual"
              class="validate"
              required
            />
            <label class="active" for="premiumIndividual"
              >Premium (Individual)</label
            >
          </div>
        </div>

        <div class="col s1"></div>

        <div class="col s1"><span class="chip">₦</span></div>
        <div
          class="col s4"
          :class="{ 'red lighten-5': error && !premiumFamily }"
        >
          <div class="input-field">
            <input
              type="number"
              id="premiumFamily"
              v-model="premiumFamily"
              class="validate"
              required
            />
            <label class="active" for="premiumFamily">Premium (Family)</label>
          </div>
        </div>

        <div class="col s1"></div>
      </div>
    </div>

    <!-- Benefits -->
    <div class="card-panel teal-text">
      <div class="row">
        <div class="col s12">
          <span class="black-text">Plan</span>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <div class="file-field input-field">
            <div class="btn grey" :class="{ disabled: isSubmitting }">
              <span>New Benefits</span>
              <input type="file" @change="handleFile" />
            </div>
            <div class="file-path-wrapper">
              <input class="file-path" v-model="inputFile" type="text" />
              <p class="red-text" v-if="fileError">
                {{ fileError }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="section center">
    <p>
      <a
        class="waves-effect waves-light btn-large black"
        :class="{
          disabled: isSubmitting,
        }"
        @click="handleBack"
      >
        <i class="material-icons left">chevron_left</i>Back
      </a>
      <button
        class="waves-effect waves-light btn-large teal"
        :class="{
          disabled:
            isSubmitting ||
            (premiumIndividual == checkIndividual &&
              premiumFamily == checkFamily &&
              !file),
        }"
        @click="handleUpdate"
      >
        <i class="material-icons right">check_circle</i>
        {{ isSubmitting ? `Updating (${uploadProgress})` : "Update" }}
      </button>
    </p>
  </div>
</template>
      
<script>
import { ref, onMounted, watchEffect } from "vue";
import useStorage from "@/composables/useStorage";
import postItem from "@/composables/postItem";

export default {
  name: "PolicyReviewEditOnboardComponent",
  props: ["cat", "policy", "client"],
  emits: ["reset"],
  setup(props, context) {
    const file = ref(null);
    const inputFile = ref(null);
    const fileError = ref(null);
    const isSubmitting = ref(false);
    const error = ref(null);
    const next = ref(false);
    const premiumIndividual = ref(null);
    const premiumFamily = ref(null);
    const checkIndividual = ref(null);
    const checkFamily = ref(null);
    const types = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    // storage
    const { uploadFile, uploadProgress, uploadError, data, completed } =
      useStorage();

    // starter
    onMounted(() => {
      // get policies
      // null all
      premiumIndividual.value = getPremium(props.policy.premiums, "i");
      premiumFamily.value = getPremium(props.policy.premiums, "f");
      // checkers
      checkIndividual.value = getPremium(props.policy.premiums, "i");
      checkFamily.value = getPremium(props.policy.premiums, "f");
    });

    // to naira
    const getPremium = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      return val;
    };

    // handle back
    const handleBack = () => {
      context.emit("reset", null);
    };

    const handleFile = (e) => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        file.value = selected;
        inputFile.value = file.value.name;
        fileError.value = null;
      } else {
        file.value = null;
        inputFile.value = null;
        fileError.value = "Please select an Excel file (.XLSX, XLS)";
      }
    };

    // handle add
    const handleUpdate = async () => {
      if (premiumIndividual.value && premiumFamily.value) {
        // Start Uploading
        isSubmitting.value = true;

        // initiate for update
        let plan = null;
        let individual = null;
        let family = null;
        let scope = props.client.id; // get client id from props

        // new plan
        plan = {
          id: props.policy.id, // use 1 for test
        };

        // update individual premium
        if (checkIndividual.value != premiumIndividual.value) {
          individual = {
            plan_id: null, // update this on server
            amount: premiumIndividual.value,
            scope: scope,
            type: "i",
          };
        }

        // update family premium
        if (checkFamily.value != premiumFamily.value) {
          family = {
            plan_id: null, // update this on serve
            amount: premiumFamily.value,
            scope: scope,
            type: "f",
          };
        }

        // File (Benefits)
        if (file.value) {
          // Start Uploading
          uploadError.value = null;

          // Cloud Function
          const fx = "planLoader";

          // round up
          const cloudInput = {
            plan: plan,
            individual: individual,
            family: family,
            scope: scope,
            action: "client",
          };

          // upload
          uploadFile(file.value, fx, cloudInput);
        } else {
          // update without File
          // (without File)

          // Start Uploading
          uploadProgress.value = "100%";

          // round up
          const newVal = {
            plan: plan,
            individual: individual,
            family: family,
            scope: scope,
            action: "client",
            benefits: [], // empty array (no Benefit change required)
          };

          // post item
          const { res, err } = await postItem("plan/benefits/bulk", newVal);

          if (res.value) {
            context.emit("reset", res.value.data);
          } else {
            error.value = err.value;
            isSubmitting.value = false;
          }
        }
      } else {
        error.value = true;
      }
    };

    // watch upload
    watchEffect(() => {
      if (completed.value) {
        // next
        context.emit("reset", data.value.succ.data);
      }
      if (uploadError.value) {
        // restart
        // handleReset();
        // stop
        error.value = uploadError.value;
        isSubmitting.value = false;
      }
    });

    return {
      isSubmitting,
      handleUpdate,
      handleBack,
      handleFile,
      fileError,
      uploadError,
      file,
      inputFile,
      uploadProgress,
      error,
      next,
      premiumIndividual,
      premiumFamily,
      checkIndividual,
      checkFamily,
    };
  },
};
</script>
      
  <style>
</style>