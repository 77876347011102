<template>
  <template v-if="showHeader">
    <div class="grey lighten-5">
      <header>
        <div class="navbar-fixed">
          <nav>
            <div class="nav-wrapper indigo">
              <router-link to="/" class="brand-logo center"
                ><img src="@/assets/logo/logo.png" width="200"
              /></router-link>
              <ul class="left">
                <li>
                  <a
                    href="#!"
                    data-target="slide-out"
                    class="sidenav-trigger show-on-medium-and-up"
                    ><i class="large material-icons">menu</i></a
                  >
                </li>
              </ul>
              <ul class="right">
                <li class="active">
                  <router-link :to="{ name: 'home' }" title="Dashboard"
                    ><i class="large material-icons">dashboard</i></router-link
                  >
                </li>
                <li class="hide-on-med-and-down">
                  <router-link :to="{ name: 'enrollee' }" title="Enrollees"
                    ><i class="large material-icons">people</i></router-link
                  >
                </li>
                <li class="hide-on-med-and-down">
                  <router-link :to="{ name: 'claim' }" title="Claims"
                    ><i class="large material-icons">folder</i></router-link
                  >
                </li>
                <li class="hide-on-med-and-down">
                  <router-link
                    :to="{ name: 'auth' }"
                    title="Authorization"
                    class="btn-floating pulse"
                    ><i class="large material-icons"
                      >insert_comment</i
                    ></router-link
                  >
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <!-- Side Bar -->
        <div id="slide-out" class="nav-wrapper sidenav grey lighten-2">
          <div class="center grey lighten-4">
            <br />

            <img
              :src="profile?.profile_img"
              width="96"
              class="circle responsive-img"
              v-if="profile?.profile_img"
            />
            <img
              :src="`https://ui-avatars.com/api/?name=${profile?.name}&background=a0a0a0&size=128`"
              width="96"
              class="circle responsive-img"
              v-else
            />

            <h6>
              {{ user }}
              <br />
              <small>({{ role }})</small>
            </h6>

            <br />
          </div>

          <div class="divider"></div>

          <ul>
            <li>
              <router-link :to="{ name: 'home' }" title="Dashboard"
                ><i class="material-icons indigo-text">dashboard</i>
                Dashboard</router-link
              >
            </li>
            <li v-for="shortcut in shortcuts" :key="shortcut.id">
              <router-link :to="{ name: shortcut.to }" :title="shortcut.title"
                ><i class="material-icons indigo-text">{{ shortcut.icon }}</i>
                {{ shortcut.title }}</router-link
              >
            </li>
          </ul>

          <ul>
            <li @click.prevent="handleSignOut">
              <router-link :to="{ name: 'home' }" title="Logout"
                ><i class="small material-icons orange-text"
                  >power_settings_new</i
                >
                Logout Account</router-link
              >
            </li>
          </ul>
        </div>

        <nav v-if="breadcrumbs.length">
          <div class="nav-wrapper">
            <div class="col s12 center">
              <router-link class="breadcrumb" to="/">Dashboard</router-link>

              <router-link
                class="breadcrumb"
                :to="index === 0 ? { name: crumb } : currentPath"
                v-for="(crumb, index) in breadcrumbs"
                :key="index"
                >{{ capitalizeFirstLetter(crumb) }}</router-link
              >
            </div>
          </div>
        </nav>

        <nav class="custom-nav nav-wrapper" v-else></nav>

        <!-- <br /> -->
      </header>
    </div>
  </template>
</template>

<script>
import { onMounted, onUpdated, computed, watchEffect, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { logout } from "@/composables/logout/logout.js";
import M from "materialize-css";

// firebase
// import watchAuth from "@/composables/fb/watchAuth";
// import addColDoc from "@/composables/fb/addColDoc";
// import deleteColDoc from "@/composables/fb/deleteColDoc";

export default {
  name: "HeaderComponent",
  setup() {
    const tabs = ref([]);

    // vue router
    const router = useRouter();

    // route
    const route = useRoute();

    // store
    const store = useStore();

    //     // title
    //     onMounted(() => {
    //       (async () => {
    //       // menu
    //       const { res, err } = await addColDoc("auth", {
    //         provider_id: "goffoodd",
    //       });

    // if (res.value) {
    //   newId.value = res.value;
    // } else {
    //   console.log(err.value);
    // }
    // })();

    // (async () => {
    //       // menu
    //       const { res, err } = await deleteColDoc("auth", newId.value);

    // if (res.value) {
    //   console.log(res.value);
    // } else {
    //   console.log(err.value);
    // }
    // })();

    // (async () => {
    //       // menu
    //       const { res, err } = await watchAuth();

    // if (res.value) {
    //   console.log(res.value);

    // } else {
    //   console.log(err.value);
    // }
    // })();

    //     });

    // capitalize
    function capitalizeFirstLetter(val) {
      if (!val) return ""; // Handle empty strings
      return val.charAt(0).toUpperCase() + val.slice(1);
    }

    // title
    onMounted(() => {
      // menu
      const elems = document.querySelectorAll(".sidenav");
      M.Sidenav.init(elems, {});
    });

    // title
    onUpdated(() => {
      // menu
      const elems = document.querySelectorAll(".sidenav");
      M.Sidenav.init(elems, {});
    });

    // Current path
    const currentPath = computed(() => {
      let paths = route.path;
      return paths;
    });

    // Generate breadcrumbs
    const breadcrumbs = computed(() => {
      let paths = route.path.replace(/[0-9]/g, "").split("/");
      paths = paths.filter((x) => x);
      return paths.filter((x, y) => y < 2);
    });

    // user
    const user = computed(() => {
      if (!store.state.user) {
        return null;
      } else {
        return store.state.user;
      }
    });

    // user
    const profile = computed(() => {
      if (!store.state.profile) {
        return null;
      } else {
        return store.state.profile;
      }
    });

    // role
    const role = computed(() => {
      if (!store.state.role) {
        return null;
      } else {
        return store.state.role;
      }
    });

    const shortcuts = computed(() => {
      let filteredTabs = [];
      if (store.state.modules) {
        filteredTabs = tabs.value.filter((x) =>
          store.state.modules.includes(x.to)
        );
      } else {
        filteredTabs = [];
      }

      return filteredTabs;
    });

    //state management
    watchEffect(() => {
      if (!store.state.user) {
        //log out
        router.push({
          name: "login",
        });
      }
    });

    // handle SignOut
    const handleSignOut = () => {
      // clean up
      logout();
    };

    onMounted(() => {
      // all
      tabs.value.push({
        id: tabs.value.length + 1,
        to: "enrollee",
        card: "card-1",
        icon: "people",
        title: "Enrollees",
        description: "view active enrollees.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "auth",
        card: "card-2",
        icon: "insert_comment",
        title: "Pre-Authorizations",
        description: "manage pre-authorization.",
        notification: "new authorization",
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "provider",
        card: "card-1",
        icon: "local_hospital",
        title: "Providers",
        description: "manage the providers.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "hospital",
        card: "card-2",
        icon: "label",
        title: "Hospitals",
        description: "view primary care hospital.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "claim",
        card: "card-1",
        icon: "description",
        title: "Claims",
        description: "manage claims.",
        notification: "new submission",
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "private",
        card: "card-2",
        icon: "business",
        title: "HMO Clients",
        description:
          "Manage (HMO) clients, contracts, enrollees, invoices and more.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "public",
        card: "card-1",
        icon: "group_add",
        title: "Schemes",
        description:
          "Manage all State Social Health Insurance Schemes. Upload and review monthly lists.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "retail",
        card: "card-1",
        icon: "shopping_cart",
        title: "Retail Clients",
        description:
          "Manage (Retail) clients, contracts, enrollees, invoices and more.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "tpa",
        card: "card-2",
        icon: "monetization_on",
        title: "TPA (Retainer) Clients",
        description:
          "Manage (TPA) clients, contracts, enrollees, invoices and more.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "client",
        card: "card-2",
        icon: "business",
        title: "Clients",
        description: "View all clients",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "advisory",
        card: "card-2",
        icon: "work",
        title: "Advisory",
        description: "View upcoming invoices for prompt payment.",
        notification: "new pending",
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "adder",
        card: "card-2",
        icon: "add_circle",
        title: "Adders",
        description: "Quickly add dependants to active enrollees.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "compliance",
        card: "card-1",
        icon: "check_circle",
        title: "Compliance",
        description: "Review and approve claims. View invoices.",
        notification: "new claim",
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "finance",
        card: "card-1",
        icon: "account_balance",
        title: "Finance",
        description: "Pay claims. Confirm client payments.",
        notification: "new batch",
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "report",
        card: "card-2",
        icon: "show_chart",
        title: "Insights",
        description: "View latest utilization patterns by clients, providers.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "settings",
        card: "card-1",
        icon: "settings",
        title: "Settings",
        description:
          "Load Shelf Plans, Standard Tariffs, Create Users and other settings.",
        notification: null,
      });

      tabs.value.push({
        id: tabs.value.length + 1,
        to: "bypass",
        card: "card-1",
        icon: "file_upload",
        title: "Bypass",
        description:
          "Bypass the standard invoicing system - Directly add enrollees to the monthly list.",
        notification: null,
      });
    });

    // display
    const showHeader = computed(() => {
      if (route.path.includes("setup")) {
        return false;
      } else if (route.path.includes("login")) {
        return false;
      } else if (route.path.includes("utility")) {
        return false;
      } else {
        return true;
      }
    });

    // watcher // sender

    return {
      capitalizeFirstLetter,
      currentPath,
      breadcrumbs,
      handleSignOut,
      showHeader,
      user,
      profile,
      role,
      shortcuts,
    };
  },
};
</script>

<style scoped>
.brand-logo {
  margin-top: 7px;
}
.divider-lift {
  margin-top: -12px;
}
.custom-nav {
  height: 15px;
}
</style>