<template>
  <div class="container">
    <LoadingDiv v-if="!isReady" />

    <template v-else>
      <div class="card-content">
        <router-link :to="{ name: 'provider' }">
          <i class="medium material-icons blue-grey-text text-darken-2 right"
            >local_hospital</i
          >
        </router-link>

        <h3 class="header blue-grey-text text-darken-2">
          <router-link :to="{ name: 'provider' }"
            ><i class="small material-icons" title="Go Back"
              >subdirectory_arrow_left</i
            ></router-link
          >

          <small>
            <span
              class="tooltipped pointer"
              data-position="bottom"
              :data-tooltip="provider.name"
              v-if="provider && 'name' in provider"
            >
              {{ maxRule(provider.name) }}
            </span>
            <span v-else> Provider... </span>
          </small>
        </h3>
      </div>

      <div class="section center">
        <span
          v-for="tab in tabs"
          :key="tab.id"
          :class="{
            'tab-custom-active': tab.id == currTab,
            'tab-custom': tab.id != currTab,
          }"
          @click="handleActive(tab.id)"
        >
          {{ tab.title }}
        </span>
      </div>

      <div class="row">
        <div class="col s12">
          <!-- Profile -->
          <ProfileDiv
            :provider="provider"
            @update="handleUpdate"
            v-if="currTab == 1"
          />
          <!-- Tariff -->
          <TariffDiv :provider="provider" id="1" v-if="currTab == 2" />
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import { computed, onMounted, ref, watchEffect } from "vue";
import getItem from "@/composables/getItem";
import LoadingDiv from "@/components/LoadingDiv.vue";
import ProfileDiv from "@/components/provider/ProfileProviderComponent.vue";
import TariffDiv from "@/components/provider/TariffProviderComponent.vue";
import M from "materialize-css";

export default {
  name: "providerView",
  components: { LoadingDiv, ProfileDiv, TariffDiv },
  props: ["id"],
  setup(props) {
    onMounted(() => {
      // page name
      document.title = "View Single Provider | EasyHMO";
    });

    const response = ref(null);
    const isReady = ref(false);
    const error = ref(null);
    const currTab = ref(null);
    const tabs = ref([]);
    const providerId = ref(props.id);

    // UI modification
    const maxRule = (text) => {
      let maxLength = 15;
      if (text.length > maxLength) {
        return ` ${text.substring(0, maxLength)} ... `;
      } else {
        return ` ${text} `;
      }
    };

    onMounted(() => {
      // load tab
      tabs.value.push({ id: 1, title: "Profile" });
      tabs.value.push({ id: 2, title: "Tariff" });
      // set default
      currTab.value = 1;
    });

    // handle curr status
    const handleActive = (tab) => {
      // update show and components
      currTab.value = tab;
    };

    // Title
    watchEffect(() => {
      if (response.value) {
        // load title
        setTimeout(() => {
          var elems = document.querySelectorAll(".tooltipped");
          M.Tooltip.init(elems, {});
        }, 2000);
      }
    });

    // Initiate
    onMounted(() => {
      // get provider
      (async () => {
        const { res, err } = await getItem("provider", props.id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    });

    // handle emitted update
    const handleUpdate = (val) => {
      response.value = val;
    };

    // output
    const provider = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleActive,
      handleUpdate,
      maxRule,
      isReady,
      tabs,
      currTab,
      error,
      providerId,
      provider,
    };
  },
};
</script>

<style scoped>
.pointer {
  cursor: default;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

/* Removed
color: #000000;
border: 1px solid #ddd;
background-color: #d1f3df; */
.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid orange;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>