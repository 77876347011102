<template>
  <div class="card">
    <div class="card-panel center">
      All (Staff & Dependants)
      <a href="#" class="edit-item" @click.prevent="handleDownload('all')"
        ><i class="material-icons grey-text">download</i></a
      >
    </div>
    <div class="card-panel center">
      Staff only
      <a href="#" class="edit-item" @click.prevent="handleDownload('staff')"
        ><i class="material-icons grey-text">download</i></a
      >
    </div>
    <div class="card-panel center">
      Dependants only
      <a
        href="#"
        class="edit-item"
        @click.prevent="handleDownload('dependants')"
        ><i class="material-icons grey-text">download</i></a
      >
    </div>
  </div>
</template>
  
    
<script>
import { ref } from "vue";

export default {
  name: "EnrolleeDownloadComponent",
  props: ["clientId"],
  setup() {
    const response = ref(null);

    // handle
    const handleDownload = () => {
    };

    return {
      handleDownload,
      response,
    };
  },
};
</script>
    
  
<style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}
</style>