import { ref } from 'vue';

const pageControl = (pages) => {
    
const pagerPc = ref(null);
const pageNextPc = ref(null);
const pagePreviousPc = ref(null);
const pageCurrPc = ref(0);
const pageSizePc = ref(0);
const pageTotalPc = ref(0);

if (pages && pages.links) {
    // current page
    pageCurrPc.value = pages.current_page
    // next page
    if (pages.current_page < pages.last_page) {
      pageNextPc.value = pages.current_page + 1;
    } else {
      pageNextPc.value = pages.last_page;
    }
    // previous page
    if (pages.current_page > 1) {
      pagePreviousPc.value = pages.current_page - 1;
    } else {
      pagePreviousPc.value = 1;
    }
    // total
    pageTotalPc.value = pages.total;
    // page size
    pageSizePc.value = pages.per_page;
    // pages
    pagerPc.value = pages.links.map((x, y) => {
      return {
        ...x,
        id: y + 1,
      };
    });
  }
    

    return { pageCurrPc, pageNextPc, pagePreviousPc, pageTotalPc, pageSizePc, pagerPc };
}

export default pageControl;
