<template>
  <p class="chip green white-text" v-if="success">{{ success }} <i class="tiny material-icons">check_circle</i></p>
  <p class="chip red white-text" v-if="error">{{ error }}</p>
  <form @submit.prevent="handleSubmit">
    <div class="card-panel">
      <div class="row">
        <div class="col s2"></div>
        <div class="col s6">
          <div class="input-field">
            <input type="text" v-model="item" />
            <label for="item">Item</label>
          </div>
        </div>
        <div class="col s2">
          <div class="input-field">
            <input type="number" v-model="price" />
            <label for="price">Price (Unit)</label>
          </div>
        </div>
        <div class="col s2"></div>
      </div>
    </div>

    <p>
      <br />
      <button
        class="waves-effect waves-light btn-large"
        :class="{
          disabled: !item  || !price || isSubmitting,
        }"
      >
        <i class="material-icons right">check_circle</i
        >{{ isSubmitting ? `Adding ...` : "Add" }}
      </button>
    </p>
  </form>
</template>
    
<script>
import { ref } from "vue";
import postItem from "@/composables/postItem";

export default {
  name: "AddSingleTariffComponent",
  props: ["provider"],
  emits: ["update"],
  setup(props, context) {
    const item = ref(null);
    const price = ref(null);
    const isSubmitting = ref(false);
    const success = ref(null);
    const error = ref(null);

    const clearForm = () => {
      item.value = null;
      price.value = null;
      isSubmitting.value = false;
    };

    const clearMsg = () => {
      success.value = null;
      error.value = null;
    }

    const handleSubmit = async () => {
      // start
      clearMsg();
      // submitting
      isSubmitting.value = true;

      // put all together
      let newVal = { 
        item: item.value,
        price: price.value,
        version: null,
        schedule_id: props.provider.schedule_id,
        scope: props.provider.id,
       };

      // post item
      const { res, err } = await postItem("tariff/add", newVal);

      if (res.value) {
        // final end
        success.value = `${item.value} at N${price.value} added!`;

        // tariff update
        context.emit("update", true);

      } else {
        error.value = `Error: ${item.value} not added! (${err.value})`;
      }

      clearForm();
    };


    return {
      handleSubmit,
      isSubmitting,
      success,
      error,
      item,
      price,
    };
  },
};
</script>
    
<style>
</style>