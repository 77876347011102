<template>
  <div class="container">
    <div class="card-content">
      <router-link :to="{ name: 'auth' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >insert_comment</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2">
        <a href="" @click.prevent="handleBack"
          ><i class="small material-icons" title="Go Back"
            >subdirectory_arrow_left</i
          ></a
        >
        <small> Add Auths</small>
      </h3>
    </div>

    <LoadingDiv v-if="!isReady" />

    <template v-else>
      <template v-if="enrollee">
        <EnrolleeAuth
          @restart="handleRestart"
          :enrollee="enrollee"
          :serverTime="serverTime"
        />
      </template>

      <StartAuth @next="handleNext" v-else />
    </template>
  </div>
</template>
    
  
<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import LoadingDiv from "@/components/LoadingDiv.vue";
import StartAuth from "@/components/auth/StartAuthComponent.vue";
import EnrolleeAuth from "@/components/auth/EnrolleeAuthComponent.vue";

export default {
  name: "authAdd",
  components: { LoadingDiv, StartAuth, EnrolleeAuth },
  setup() {
    const back = ref("auth"); // default
    const isReady = ref(true);
    const enrollee = ref(null);
    const serverTime = ref(null);

    // vue router
    const router = useRouter();

    // start
    onMounted(() => {
      // page name
      document.title = "Add Authorization | EasyHMO";
    });

    // handle back
    const handleBack = () => {
      if (back.value == "auth") {
        router.push({ name: "auth" }); // final back
      } else if (back.value == "authAdd") {
        handleRestart(); // restart auth
      } else {
        router.push({ name: "home" }); // safeguard to prevent unexpected errors
      }
    };

    // handle next
    const handleNext = (val) => {
      enrollee.value = val.value;
      serverTime.value = val.serverTime;
      back.value = "authAdd";
    };

    // handle restart
    const handleRestart = () => {
      enrollee.value = null;
      back.value = "auth";
    };

    return {
      handleBack,
      handleNext,
      handleRestart,
      isReady,
      enrollee,
      serverTime,
    };
  },
};
</script>
  
<style>
</style>