<template>
  <div class="container">
    <div class="card-content">
      <router-link :to="{ name: 'advisory' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >work</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2"><small>Advisory</small></h3>
    </div>

    <div class="row">
      <div class="col s12">
        <LoadingDiv v-if="!isReady" />

        <div class="section" v-else>
          <!-- Invoices -->
          <div class="card teal lighten-5">
            <!-- Start -->
            <div v-for="(invoice, index) in invoices" :key="invoice.id">
              <!-- Principal -->
              <div
                class="card-panel"
                :class="{
                  'amber lighten-5': invoice.status == 'p',
                  'teal lighten-5': invoice.status == 'c',
                  'purple lighten-5': invoice.status == 'z',
                  'grey lighten-3': invoice.status == 'd',
                }"
                v-if="
                  (currInvoice == invoice || !currInvoice) &&
                  (invoice.status == 'p' || invoice.status == 'z')
                "
              >
                <div class="row">
                  <div class="col s1">
                    <h5>{{ index + 1 }}.</h5>
                  </div>
                  <div class="col s10">
                    <p>
                      <span
                        v-if="
                          invoice.status == 'p' &&
                          invoice.start < currServerDate
                        "
                        >Delayed Invoice<i class="tiny material-icons"
                          >schedule</i
                        ></span
                      >
                      <span
                        v-if="
                          invoice.status == 'p' &&
                          invoice.start > currServerDate
                        "
                        >Pending Invoice</span
                      >
                      <span
                        class="orange-text text-darken-4"
                        v-if="invoice.status == 'z'"
                        >Unpaid (Grace Period)</span
                      >
                      <span v-if="invoice.status == 'c'"
                        >Completed Payment<i
                          class="tiny material-icons teal-text"
                          >check_circle</i
                        ></span
                      >
                      <span class="red-text" v-if="invoice.status == 'd'"
                        >Deleted Invoice</span
                      >
                    </p>
                    <h6>
                      {{ invoice.client.name }}
                      <span class="chip orange">{{
                        invoice.client.cat.toUpperCase()
                      }}</span>
                    </h6>
                    <h5>
                      ₦{{
                        changeToNaira(invoice.input_total, invoice.output_total)
                      }}
                      <span class=""
                        ><small>
                          {{
                            new Date(invoice.start).toLocaleString(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                          }}
                        </small>

                        -

                        <small>
                          {{
                            new Date(invoice.end).toLocaleString(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                          }}
                        </small>
                      </span>
                    </h5>
                  </div>
                  <div class="col s1">
                    <span class="right" v-if="!currInvoice">
                      <a
                        class="btn-floating btn-medium waves-effect waves-light right"
                        :class="{
                          red: invoice.start < currServerDate,
                          'light-blue lighten-2': !(
                            invoice.start < currServerDate
                          ),
                        }"
                        ><i
                          class="material-icons"
                          @click="handleView(invoice)"
                          title="view details"
                          >chevron_right</i
                        ></a
                      >
                    </span>
                    <span class="right" v-else>
                      <a
                        class="btn-floating btn-medium waves-effect waves-light light-blue right"
                        ><i
                          class="material-icons"
                          @click="handleClear()"
                          title="go back"
                          >keyboard_return</i
                        ></a
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Invoice details -->
          <InvoiceDiv :currInvoice="currInvoice" v-if="currInvoice" />

          <!-- Pages -->
          <template v-else>
            <div class="section center" v-if="!invoices.length">
              <h6>No Invoices <em>found</em>...</h6>
            </div>

            <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
        
<script>
import { computed, onMounted, ref } from "vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import getItems from "@/composables/getItems";
import InvoiceDiv from "@/components/invoice/InvoiceDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "advisoryHome",
  components: { LoadingDiv, PaginationDiv, InvoiceDiv },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const error = ref(null);
    const currInvoice = ref(false);
    const currUrl = ref(null);
    const currHook = ref(null);
    const currServerDate = ref(null);

    // start
    onMounted(() => {
      // page name
      document.title = "Advisory | EasyHMO";
    });

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          currServerDate.value = res.value.server;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
      })();
    };

    // to naira
    const changeToNaira = (i, o) => {
      // sort value
      let val = i - o;
      // conclude
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // starter
    onMounted(() => {
      // start from the top
      getInvoices();
    });

    // Invoices
    const getInvoices = () => {
      // loader
      isReady.value = false;
      // set current Url
      currUrl.value = "group/invoices/due";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    };

    // switch page
    const handlePage = (page) => {
      // loader
      isReady.value = false;
      // get new page items from function
      getItemsFromApi(
          `${currUrl.value}${currHook.value}page=${page}`
        );
    };

    // enrollee ction
    const handleClear = () => {
      currInvoice.value = null;
    };

    // enrollee ction
    const handleView = (val) => {
      currInvoice.value = val;
    };

    // output results
    const invoices = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handlePage,
      handleClear,
      handleView,
      changeToNaira,
      currServerDate,
      currInvoice,
      isReady,
      error,
      invoices,
      allPages,
    };
  },
};
</script>
        
<style scoped>
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  background-color: #eaecee !important;
}

.chip-more:hover {
  color: white !important;
  background-color: black !important;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>
