<template>
  <div class="section">
    <div class="card">
      <!-- Search -->
      <div
        class="card-panel"
        :class="{ 'red lighten-5': error, 'grey lighten-4': !error }"
      >
        <h5>Enter Enrollee ID</h5>
        <br />
        <nav class="white">
          <div class="nav-wrapper">
            <form @submit.prevent="handleSearch">
              <div class="input-field blue-text text-darken-2">
                <input
                  type="search"
                  v-model="enrolleeId"
                  placeholder="Enrollee ID"
                  @focus="handleClear"
                />
                <label class="label-icon" for="search"
                  ><i class="material-icons black-text">search</i></label
                >
                <i class="material-icons" @click="handleSearch">send</i>
              </div>
            </form>
          </div>
        </nav>
      </div>

      <div class="progress" v-if="isLoading">
        <div class="indeterminate"></div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { ref, watchEffect } from "vue";
import getItems from "@/composables/getItems";

export default {
  name: "StartAuthComponent",
  emits: ["next"],
  setup(props, context) {
    const response = ref(null);
    const serverTime = ref(null);
    const enrolleeId = ref(null);
    const error = ref(false);
    const isLoading = ref(false);

    // get function
    const getSearchItemsFromApi = (table) => {
      (async () => {
        const { res } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          serverTime.value = res.value.server;
        } else {
          error.value = `Enrollee ID (<b>${enrolleeId.value.trim()}</b>) not <em>found</em>!`;
          response.value = null; // clear standing response
        }

        // loading is complete
        isLoading.value = false;
      })();
    };

    // search
    const handleSearch = () => {
      // loading starts
      isLoading.value = true;
      // search
      getSearchItemsFromApi(`enrollee/search?q=${enrolleeId.value.trim()}`);
    };

    // color effect
    const handleClear = () => {
      enrolleeId.value = null;
      response.value = null;
      error.value = false;
    };

    // next
    watchEffect(() => {
      if (response.value) {
        context.emit("next", {
          value: response.value,
          serverTime: serverTime.value,
        });
      }
    });

    return {
      handleSearch,
      handleClear,
      error,
      isLoading,
      enrolleeId,
    };
  },
};
</script>
  
<style scoped>
.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>