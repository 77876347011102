// Laravel

// Development
// const adminAPI = 'http://127.0.0.1:8000/api/admin';
// const apiTariff = 'http://127.0.0.1:8000/api/admin/tariff/schedule';

// Production
const adminAPI = 'https://api.easyhmo.com.ng/api/admin';
const apiTariff = 'https://api.easyhmo.com.ng/api/admin/tariff/schedule';

// export 
export { apiTariff, adminAPI }