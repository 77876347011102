<template>
  <!-- Dependants -->
  <div
    class="card-panel light-green lighten-5"
    v-for="(dependant, index) in enrollee.dependants"
    :key="dependant.id"
  >
    <div class="row">
      <div class="col s2">
        <img
          :src="dependant.profile_img"
          width="96"
          class="circle responsive-img right"
          v-if="dependant.profile_img"
        />
        <img
          :src="`https://ui-avatars.com/api/?name=${dependant.name}&background=FFFFFF&size=128`"
          width="96"
          class="circle responsive-img right"
          v-else
        />
      </div>

      <div class="col s9">
        <h6>{{ dependant.enrollee_number }}</h6>
        <h5>
          {{ dependant.name }}
          <sup>
          <small
            ><span class="black-text">{{
              showAgeSex(dependant.dob, dependant.sex)
            }}</span></small
          ></sup>
        </h5>
        <label class="active teal-text" v-if="!(currId == dependant.id)"
          >(Dependant {{ index + 1 }})</label
        >
      </div>

      <div class="col s1">
        <span class="right" v-if="authLevel >= 3">
          <a
            href="#"
            class="secondary-content teal-text text-darken-1 dropdown-trigger"
            :data-target="`dropmenu_dependant_${dependant.id}`"
            ><i class="material-icons" title="menu">more_vert</i></a
          >

          <!-- DropMenu -->
          <ul
            :id="`dropmenu_dependant_${dependant.id}`"
            class="dropdown-content"
          >
            <li
              @click.prevent="handleMenu({ type: 'close', value: dependant })"
              v-if="currId == dependant.id"
            >
              <a href="#">Close Update</a>
            </li>

            <li
              @click.prevent="
                handleMenu({
                  type: 'profile',
                  value: dependant,
                })
              "
              v-else
            >
              <a href="#">Update Record</a>
            </li>

            <li class="divider" tabindex="-1"></li>

            <li
              @click.prevent="
                handleMenu({
                  type: 'provider',
                  value: dependant,
                })
              "
            >
              <a href="#">Review Provider</a>
            </li>

            <li class="divider" tabindex="-1"></li>

            <li
              @click.prevent="
                handleMenu({
                  type: 'status',
                  value: dependant,
                })
              "
              v-if="dependant.status == 'd'"
            >
              <a href="#">Restore Dependant</a>
            </li>
            <li
              @click.prevent="
                handleMenu({
                  type: 'status',
                  value: dependant,
                })
              "
              v-else
            >
              <a href="#">Delete Dependant</a>
            </li>
          </ul>
        </span>
      </div>
    </div>

    <!-- Review Profile -->
    <DependantEditDiv
      :dependant="dependant"
      :principal="enrollee"
      @reset="handleReset"
      v-if="action == 'profile' && currId == dependant.id"
    />

    <!-- Review Provider -->
    <DependantProviderDiv
      :user="userMerger(dependant, enrollee)"
      :principal="enrollee"
      @reset="handleReset"
      v-if="action == 'provider' && currId == dependant.id"
    />

    <!-- Review Status -->
    <DependantStatusDiv
      :enrollee="dependant"
      :principal="enrollee"
      @reset="handleReset"
      v-if="action == 'status' && currId == dependant.id"
    />
  </div>
</template>
    
<script>
import { ref, onMounted, onUpdated, watch } from "vue";
import { ageSex, changeToMoney } from "@/composables/fxLibrary/coreFx";
import DependantEditDiv from "@/components/client/onboard/EnrolleeEditDependantOnboardComponent.vue";
import DependantProviderDiv from "@/components/client/onboard/EnrolleeProviderDependantOnboardComponent.vue";
import DependantStatusDiv from "@/components/client/onboard/EnrolleeStatusEditDependantOnboardComponent.vue";
import M from "materialize-css";

export default {
  name: "EnrolleeClientComponent",
  components: {
    DependantEditDiv,
    DependantProviderDiv,
    DependantStatusDiv,
  },
  props: ["authLevel", "enrollee", "close"],
  emits: ["reset", "close"],
  setup(props, context) {
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const action = ref(null);
    const currId = ref(null);

    // dropdown
    onMounted(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // dropdown
    onUpdated(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // to naira
    const changeToNaira = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // to naira
    const changeToNairaTotal = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // merge dependant with
    const userMerger = (user, principal) => {
      // load up
      let newVal = {
        ...user,
        plan: { ...principal.plan },
      };

      return newVal;
    };

    // clear all
    const clearAll = () => {
      // clear
      action.value = null;
      currId.value = null;
    };

    // menu
    const handleMenu = (val) => {
      // close any opened dependant
      context.emit("close", true);
      // proceed
      if (val.type == "close") {
        // clear
        clearAll();
      }

      if (val.type == "profile") {
        // clear
        clearAll();
        action.value = "profile";
        currId.value = val.value.id;
      }

      if (val.type == "provider") {
        // clear
        clearAll();
        action.value = "provider";
        currId.value = val.value.id;
      }

      if (val.type == "status") {
        // clear
        clearAll();
        action.value = "status";
        currId.value = val.value.id;
      }
    };

    // handle
    const handleReset = (val) => {
      // send back
      if (val) {
        context.emit("reset", val);
      }
      // clear first
      clearAll();
    };

    // check for close Message
    // watch for changes
    watch(props, (newVal) => {
      if (newVal.close) {
        // clear first
        clearAll();
      }
    });

    return {
      handleReset,
      handleMenu,
      changeToNaira,
      changeToNairaTotal,
      showAgeSex,
      userMerger,
      isReady,
      isSubmitting,
      isLoading,
      action,
      currId,
    };
  },
};
</script>
      
<style scoped>
h5 {
  color: #2e4053;
}

.progress {
  margin-top: 0px; /* Adjust the value as needed to move the progress bar upwards */
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chip-more:hover {
  font-size: 150%;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>