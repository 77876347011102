import store from '@/store';

// Logout
const logout = () => {
    store.commit("loadUser", null);
    store.commit("loadProfile", null);
    store.commit("loadRole", null);
    store.commit("loadMasterSchemes", null);
    store.commit("loadSchemes", null);
    store.commit("loadModules", null);
    store.commit("loadAuthLevel", null);
    store.commit("loadNotify", null);
    store.commit("loadClaims", null);
    store.commit("loadCache", []);
};

export {
    logout,
}