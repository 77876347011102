<template>
  <div class="container">
    <div class="card-content">
      <router-link :to="{ name: 'adder' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >add_circle</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2"><small>Adders</small></h3>
    </div>

    <div class="row">
      <div class="col s12">
        <br />

        <LoadingDiv v-if="!isReady" />

        <template v-else>
          <!-- Search -->

          <!-- Bar -->
          <span v-if="showSearch">
            <nav class="white">
              <div class="nav-wrapper">
                <form>
                  <div class="input-field blue-text text-darken-2">
                    <input
                      type="search"
                      v-model="searchItem"
                      placeholder="Search By Name or Enrollee ID"
                    />
                    <label class="label-icon" for="search"
                      ><i class="material-icons black-text">search</i></label
                    >
                    <i
                      class="material-icons white-text orange"
                      @click="handleClearSearch"
                      v-if="searchItem || enrollees.length"
                      >clear</i
                    >
                  </div>
                </form>
              </div>
            </nav>
            <!-- Loader -->
            <div class="progress" v-if="isLoading">
              <div class="indeterminate"></div>
            </div>
          </span>

          <!-- Active Principals -->
          <!-- On-Hold Principals -->
          <ActiveHoldDiv
            :authLevel="authLevel"
            :cat="cat"
            :enrollees="enrollees"
            :displayType="displayType"
            @reset="handleReset"
            v-if="displayType == 'a' || displayType == 'h'"
          />

          <div class="section center" v-if="!enrollees.length">
            <br />
            <br />
            <h6>
              <i
                class="large material-icons grey-text text-lighten-2 pointer"
                title="No Enrollee"
                >person_pin</i
              >
            </h6>
          </div>

          <span v-else>
            <PaginationDiv :payLoad="allPages" @newPage="handlePage" />
          </span>
        </template>
      </div>
    </div>
  </div>
</template>
  
    
<script>
import { computed, onMounted, onUpdated, ref, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";
import ActiveHoldDiv from "@/components/client/extras/PrincipalActiveHoldClientComponent.vue";
import M from "materialize-css";

export default {
  name: "EnrolleeClientComponent",
  components: {
    LoadingDiv,
    PaginationDiv,
    ActiveHoldDiv,
  },
  setup() {
    const authLevel = 1;
    const cat = "hmo";
    const displayType = "a";
    const response = ref(null);
    const isReady = ref(true);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);
    const searchItem = ref(null);

    // start
    onMounted(() => {
      // page name
      document.title = "Adders | EasyHMO";
    });

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // dropdown
    onMounted(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // dropdown
    onUpdated(() => {
      var elems = document.querySelectorAll(".dropdown-trigger");
      M.Dropdown.init(elems, {});
    });

    // restart
    const handleClearSearch = () => {
      // null search
      searchItem.value = null;
      // clear
      response.value = null;
    };

    // switch page
    const handlePage = (page) => {
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
    };

    // handle
    const handleReset = (val) => {
      // do review
      let idx;

      if (val) {
        response.value.data.forEach((x, y) => {
          if (x.id == val.id) {
            idx = y;
          }
        });
        // update
        response.value.data[idx] = val;
      }
    };

    // search watcher
    watchEffect(() => {
      if (searchItem.value && isReady.value) {
        // start laoding
        isLoading.value = true;
        // set current Url
        currUrl.value = "operator/adders/principlal/search";
        // set current Hook
        currHook.value = `?q=${searchItem.value}&`;
        // get searchItem items from function
        getItemsFromApi(`${currUrl.value}?q=${searchItem.value}`);
      }
    });

    // all enrollees
    const enrollees = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    // opened all up
    const showSearch = computed(() => {
      return true;
    });

    return {
      authLevel,
      cat,
      displayType,
      handlePage,
      handleClearSearch,
      handleReset,
      isReady,
      isSubmitting,
      isLoading,
      error,
      searchItem,
      allPages,
      showSearch,
      enrollees,
    };
  },
};
</script>
    
<style scoped>
.progress {
  margin-top: 0px;
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chip-more:hover {
  font-size: 150%;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>