<template>
  <div class="container">
    <div class="card-content">
      <router-link :to="{ name: 'private' }">
        <i class="medium material-icons blue-grey-text text-darken-2 right"
          >business</i
        >
      </router-link>
      <h3 class="header blue-grey-text text-darken-2"><small>Clients</small></h3>
    </div>
    
    <nav class="white">
        <div class="nav-wrapper">
          <form>
            <div class="input-field blue-text text-darken-2">
              <input
                v-model="searchItem"
                type="search"
                placeholder="Search Clients by Name"
              />
              <label class="label-icon" for="search"
                ><i class="material-icons black-text">search</i></label
              >
              <i
                class="material-icons white-text orange"
                @click="handleClearSearch"
                v-if="searchItem"
                >clear</i
              >
            </div>
          </form>
        </div>
      </nav>

      <!-- Loader -->
      <div class="progress" v-if="isLoading">
        <div class="indeterminate"></div>
      </div>

    <LoadingDiv v-if="!isReady" />

    <div v-else>
      <div class="black-text center">
        <h5 v-if="searchItem">
          <span class="black blue-text chip"
            >Search <i class="tiny material-icons">check_circle</i></span
          >
          <span class="yellow lighten-2 black-text chip"
            ><em>{{ searchItem }}</em></span
          >
        </h5>
        <h5 v-else>
          <span class="black blue-text chip"
            >Display <i class="tiny material-icons">check_circle</i></span
          >
          <span class="yellow lighten-2 black-text chip"
            ><em>All Clients</em></span
          >
        </h5>
      </div>

      <div class="section">
        <div class="card-panel" v-for="client in clients" :key="client.id">
          <h5>
            <span class="new badge" data-badge-caption="principals">{{
              `${client.principals_count
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            }}</span>
            <router-link :to="{ name: 'clientView', params: { id: client.id } }"
              >{{ client.name }}
              <span :class="{'chip yellow lighten-4' : client?.cat == 'hmo', 'chip orange lighten-2' : !(client?.cat == 'hmo')}">{{
                client.cat.toUpperCase()
              }}</span></router-link
            >
          </h5>
          <p>{{ client.phone }}, {{ client.user?.email }}</p>
        </div>
      </div>

      <div class="section center" v-if="!clients.length">
        <h6>No Client <em>found</em>...</h6>
      </div>

      <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
    </div>
  </div>
</template>


<script>
import { onMounted, ref, computed, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import LoadingDiv from "@/components/LoadingDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";

export default {
  name: "clientHome",
  components: { LoadingDiv, PaginationDiv },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(true);
    const error = ref(null);
    const currUrl = ref(null);
    const currHook = ref(null);
    const searchItem = ref(null);

    onMounted(() => {
      // page name
      document.title = "All Clients | EasyHMO";
    });

    // numbering
    const numberBadge = (num) => {
      return (
        (allPages.value.current_page - 1) * allPages.value.per_page + num + 1
      );
    };

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // get function
    const getSearchItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "client";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
    });

    // restart
    const handleClearSearch = () => {
      // start laoding
      isLoading.value = true;
      // null search
      searchItem.value = null;
      // set current Url
      currUrl.value = "client";
      // set current Hook
      currHook.value = "?";
      // re-get items from function
      getItemsFromApi(currUrl.value);
    };

    // switch page
    const handlePage = (page) => {
      // start laoding
      isLoading.value = true;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`); //
    };

    // search watcher
    watchEffect(() => {
      if (searchItem.value) {
        // start laoding
        isLoading.value = true;
        // set current Url
        currUrl.value = `client/list/search?q=${searchItem.value}`;
        // set current Hook
        currHook.value = "&";
        // get searchItem items from function
        getSearchItemsFromApi(currUrl.value);
      } else {
        handleClearSearch();
      }
    });

    // output results
    const clients = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      handleClearSearch,
      handlePage,
      numberBadge,
      isReady,
      isLoading,
      error,
      searchItem,
      clients,
      allPages,
    };
  },
};
</script>

<style scoped>
.progress {
  margin-top: 0px; /* Adjust the value as needed to move the progress bar upwards */
}
</style>