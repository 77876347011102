import * as XLSX from "xlsx";

// Payment Advice
const downloadPaymentAdvice = (data, excel_file_name) => {
    if (data.length === 0) {
        alert("No data to export!");
        return;
    }

    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Trigger the download
    XLSX.writeFile(workbook, excel_file_name);
};

// Payment Batch
const downloadPaymentBatch = (data, excel_file_name) => {
    if (data.length === 0) {
        alert("No data to export!");
        return;
    }

    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Trigger the download
    XLSX.writeFile(workbook, excel_file_name);
};


export {
    downloadPaymentAdvice,
    downloadPaymentBatch,
}