<template>
  <!-- Add Diagnosis -->
  <div
    class="card"
    :class="{ 'teal lighten-5': !error, 'red lighten-5': error }"
    v-if="diagnosisCard"
  >
    <div class="card-content">
      <div class="row">
        <form class="col s12">
          <div class="row">
            <div class="col s12">
              <span class="card-title">{{
                resetDiagnosis ? "Edit Diagnosis" : "Add Diagnosis"
              }}</span>
            </div>

            <div class="input-field col s12">
              <input v-model="diagnosis" id="diagnosis" type="text" />
              <label class="active" for="diagnosis">Diagnosis</label>
            </div>

            <div class="input-field col s12">
              <a
                class="waves-effect waves-light btn red lighten-2"
                @click.prevent="handleRefresh('diagnosis')"
              >
                <i class="medium material-icons left">clear</i>Cancel
              </a>

              <button
                class="waves-effect waves-light btn-large right"
                :class="{ green: diagnosis }"
                @click.prevent="handleDiagnosis"
              >
                <i class="medium material-icons right">{{
                  resetDiagnosis ? "sync" : "chevron_right"
                }}</i
                >{{ resetDiagnosis ? "Confirm Edit" : "Next" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Add Claims -->
  <div
    class="card"
    :class="{ 'teal lighten-5': !error, 'red lighten-5': error }"
    v-if="claimsCard"
  >
    <div class="card-content">
      <div class="row">
        <form class="col s12">
          <div class="row">
            <div class="col s12" v-if="!claimsDisplayCard">
              <span class="card-title">Select Item</span>
            </div>
            <div class="input-field col s6">
              <TariffSearch
                inputId="filterClaim"
                :data="filterData"
                :startAgain="resetClaim"
                :startEdit="editClaim"
                @selected="handlePick"
                @searching="handleSearch"
                @clear="handleClear"
              />
            </div>
            <div class="input-field col s3">
              <input
                v-model="qty"
                id="qty"
                type="number"
                @focus="handlePricing('qty')"
              />
              <label class="active" for="qty">Quantity</label>
            </div>
            <div class="input-field col s3">
              <input
                v-model="amt"
                id="amt"
                type="number"
                @focus="handlePricing('amt')"
              />
              <label class="active" for="amt">Amount Charged</label>
            </div>

            <div class="input-field col s12">
              <a
                class="waves-effect waves-light btn red lighten-2"
                @click.prevent="handleRefresh('claim')"
              >
                <i class="medium material-icons left">clear</i>Cancel
              </a>

              <a
                v-if="type == 'claim' && editId == null"
                class="waves-effect waves-light btn-large right"
                :class="{
                  green: !(selectedItem && qty && amt) && claimsList.length,
                }"
                @click="handleNext('review')"
                ><i class="medium material-icons right">chevron_right</i
                >Review</a
              >

              <button
                class="waves-effect waves-light black btn-large right"
                :class="{ pulse: addPulse }"
                @click.prevent="handleClaims"
              >
                <i class="medium material-icons left">{{
                  editId ? "sync" : "add"
                }}</i
                >{{ editId ? "Edit Item" : "Add Item" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
    
  <script>
import { ref, onMounted, computed, watch, watchEffect } from "vue";
import getItems from "@/composables/getItems";
import TariffSearch from "@/components/search/TariffSearch.vue";
import { capitalizeFirstLetter } from "@/composables/fxLibrary/coreFx.js";
import { totalSentFX } from "@/composables/fxLibrary/claimsFx.js";
import { cacheGet, cachePush } from "@/composables/cache/cache.js";

export default {
  name: "AddManualClaimComponent",
  emits: ["update", "clear"],
  props: ["cluster", "type", "next", "editDiagnosis", "claimItem", "reload"],
  components: { TariffSearch },
  setup(props, context) {
    const responseDefault = ref([]);
    const responseSearch = ref([]);
    const error = ref(false);
    const addPulse = ref(false);
    const submitPulse = ref(false);
    const diagnosis = ref(null);
    const diagnosisList = ref([]);
    const diagnosisCard = ref(true);
    const selectedItem = ref(null);
    const selectedPrice = ref(null);
    const resetDiagnosis = ref(false);
    const editClaim = ref(null);
    const editId = ref(null);
    const resetClaim = ref(false);
    const claimsCard = ref(false);
    const claimsDisplayCard = ref(false);
    const claimsSubmitCard = ref(false);
    const claimsList = ref([]);
    const claim = ref(null);
    const qty = ref(null);
    const amt = ref(null);

    // reload
    onMounted(() => {
      // check reload
      if (props.reload) {
        // re-set
        claimsList.value = props.reload.claims;
        diagnosisList.value.push(props.reload.diagnosis);
        // carry on
        handleNext("moreClaims");
      }
    });

    // data tariff
    const filterData = computed(() => {
      // start
      let val = {};

      let valPlus;

      if (responseSearch.value) {
        valPlus = responseSearch.value.reduce((newArr, x) => {
          newArr[x.item] = null;
          return newArr;
        }, {});
        val = { ...val, ...valPlus };
      }

      // return final
      return val;
    });

    // search function
    const getTariff = (table) => {
      (async () => {
        const { res } = await getItems(table);
        if (res.value) {
          // return
          responseDefault.value = res.value.data;
          // push cache
          cachePush(table, responseDefault.value);
        }
      })();
    };

    // starter
    onMounted(() => {
      // start
      let scheme = "";
      let schedule = "";
      let provider = "";
      // checker
      if (props.cluster?.scheme.type === "s") {
        scheme = props.cluster?.scheme.id;
      } else {
        schedule = props.cluster?.provider.schedule_id;
        provider = props.cluster?.provider.id;
      }
      // search table
      let searchTable = `operator/tariff/search?x=${scheme}&z=${schedule}&p=${provider}`;

      // get cache
      let storedTariff = cacheGet(searchTable);

      // set or get tariff
      if (storedTariff) {
        responseDefault.value = storedTariff;
      } else {
        getTariff(searchTable);
      }
    });

    // add up
    const totalSent = (claims) => {
      const { fx: totalSent } = totalSentFX(claims);
      return totalSent.value;
    };

    // handle pricing
    const handlePricing = (val) => {
      if (val == "qty") {
        qty.value = null;
        amt.value = 0;
      }

      if (val == "amt") {
        amt.value = null;
      }
    };

    // refresh field
    const handleRefresh = (type) => {
      if (type === "diagnosis") {
        resetDiagnosis.value = false;
        // clear entry
        diagnosis.value = null;
        // clear pulse (if needed)
        addPulse.value = false;
        // clear error (if needed)
        error.value = false;
        // clear resetDiagnosis in parent (if needed)
        context.emit("clear", {
          id: "resetDiagnosis",
          value: false,
        });
      } else if (type === "claim") {
        // clear entry
        claimInputClear();
      }
      // redirect
      if (claimsList.value.length) {
        handleNext("review");
      } else {
        handleNext("claims");
      }
    };

    // null diagnosis crew
    const handleReviewDiagnosis = () => {
      // clear diagnosis
      diagnosisCard.value = true;
      // true claims
      claimsCard.value = false;
      claimsDisplayCard.value = false;
      claimsSubmitCard.value = false;
      // clear everything about diagnosis
      diagnosis.value = diagnosisList.value.join(", ");
      resetDiagnosis.value = true;

      // clear entry (insurance)
      claimInputClear();

      // clear edit (if needed)
      editId.value = null;
      // clear pulse (if needed)
      addPulse.value = false;
      // clear error (if needed)
      error.value = false;
    };

    // delete claim
    const handleDelete = (claim) => {
      // mark status as delete
      claimsList.value = claimsList.value.filter((x) => x.id != claim.id);
      // clear all input
      claimInputClear();
      // check if all has been removed
      if (!claimsList.value.length) {
        handleNext("claims");
      }
    };

    // edit claim
    const handleEdit = (claim) => {
      // for child component
      editClaim.value = claim.claim;

      // for main
      editId.value = claim.id;
      selectedItem.value = claim.claim;
      selectedPrice.value = claim.standard;
      qty.value = claim.qty;
      amt.value = claim.amt;

      // clear submit outlook
      handleNext("moreClaims");
    };

    // null diagnosis input field
    const handleClearInput = (field) => {
      if (field === "diagnosis") {
        resetDiagnosis.value = false;
        // clear diagnosis
        diagnosis.value = null;
        // clear pulse (if needed)
        addPulse.value = false;
        // clear error (if needed)
        error.value = false;
      }
    };

    // handle diagnosis
    const handleDiagnosis = () => {
      if (diagnosis.value) {
        // format for saving
        const { fx } = capitalizeFirstLetter(diagnosis.value);

        // add to or override diagnosis array
        if (resetDiagnosis.value) {
          diagnosisList.value = [];
          diagnosisList.value.push(fx.value);
        } else {
          diagnosisList.value.push(fx.value);
        }

        // null result
        diagnosis.value = null;
        // reset error (if required)
        error.value = false;
        // clear pulse (if required)
        addPulse.value = false;

        // clear entry (insurance)
        claimInputClear();

        // move straight to review (if edit)
        if (resetDiagnosis.value) {
          resetDiagnosis.value = false;
          // emit clear
          context.emit("clear", {
            id: "resetDiagnosis",
            value: false,
          });
        }

        // Auto-Next
        if (claimsList.value.length) {
          handleNext("review");
        } else {
          handleNext("claims");
        }
      } else {
        error.value = true;
      }
    };

    // clear inputs
    const claimInputClear = () => {
      // clear all
      selectedItem.value = null;
      selectedPrice.value = null;
      resetClaim.value = true; // will clear claims input
      qty.value = null;
      amt.value = null;
      responseSearch.value = [];

      // clear edit (if needed)
      editId.value = null;
      // clear editId in parent (if needed)
      context.emit("clear", {
        id: "editId",
        value: null,
      });
      // clear pulse (if needed)
      addPulse.value = false;
      // clear error (if needed)
      error.value = false;
    };

    // load claims
    const handleClaims = () => {
      // start action
      if (selectedItem.value && qty.value && amt.value) {
        // format for saving
        const { fx } = capitalizeFirstLetter(selectedItem.value);

        // for review activation
        let editIdVal = null;

        // process
        if (editId.value) {
          editIdVal = editId.value; // for review activation
          // edit
          const index = claimsList.value.findIndex(
            (x) => x.id === editId.value
          );
          if (index !== -1) {
            // found record
            claimsList.value[index] = {
              ...claimsList.value[index],
              claim: fx.value,
              qty: qty.value,
              amt: Number(amt.value).toFixed(2),
            };
          }
        } else {
          // add to claims array
          claimsList.value.push({
            id: Date.now(),
            claim: fx.value,
            qty: qty.value,
            amt: Number(amt.value).toFixed(2),
            standard: Number(selectedPrice.value).toFixed(2),
          });
        }

        // clear entry
        claimInputClear();

        /*
          Auth parents and Edit requests
          Others will skip this
          */
        if (props.type == "auth" || editIdVal) {
          handleNext("review");
        }

        // reset error (if required)
        error.value = false;
      } else {
        error.value = true;
      }

      // open display
      if (claimsList.value.length) {
        claimsDisplayCard.value = true;
      }
    };

    // handle next
    const handleNext = (next) => {
      if (next === "claims") {
        if (!diagnosis.value && diagnosisList.value.length) {
          // clear diagnosis
          diagnosisCard.value = false;
          // true claims
          claimsCard.value = true;
          claimsDisplayCard.value = false;
          claimsSubmitCard.value = false;
          // clear everything about diagnosis
          diagnosis.value = null;

          // reset error (if required)
          error.value = false;
        } else {
          // set error
          error.value = true;
          // set pulse
          if (diagnosis.value) {
            addPulse.value = true;
          }
        }
      } else if (next === "review") {
        if (
          !(selectedItem.value && qty.value && amt.value) &&
          claimsList.value.length
        ) {
          // clear diagnosis addition card
          diagnosisCard.value = false;
          // true claims
          claimsCard.value = false;
          claimsDisplayCard.value = true;
          claimsSubmitCard.value = true;
          // clear everything about diagnosis (in case it's still active)
          diagnosis.value = null;

          // reset error (if required)
          error.value = false;

          // delay submit pulse
          setTimeout(() => {
            submitPulse.value = true;
          }, 5000);
        } else {
          // set error
          error.value = true;
          // set pulse
          if (selectedItem.value && qty.value && amt.value) {
            addPulse.value = true;
          }
        }
      } else if (next === "moreClaims") {
        // clear diagnosis addition card
        diagnosisCard.value = false;
        // true claims
        claimsCard.value = true;
        claimsDisplayCard.value = false;
        claimsSubmitCard.value = false;
        // clear everything about diagnosis
        diagnosis.value = null;

        // reset error (if required)
        error.value = false;
      }

      // open display
      if (claimsList.value.length) {
        claimsDisplayCard.value = true;
      }
    };

    // filter components
    const handleSearch = (val) => {
      if (val.item) {
        // sort new search
        responseSearch.value = responseDefault.value.filter((x) =>
          x.item.toLowerCase().includes(val.item.toLowerCase())
        );
      } else {
        // empty search array
        responseSearch.value = [];

        // clear entry
        claimInputClear();
      }
    };

    // null reset
    const handleClear = (val) => {
      resetClaim.value = val.item;
      editClaim.value = val.item;
    };

    // get option
    const handlePick = (val) => {
      //set
      selectedItem.value = val.item;

      //set
      if (selectedItem.value) {
        // get the price out
        responseSearch.value.forEach((x) => {
          if (x.item === selectedItem.value) {
            // found
            selectedPrice.value = x.price;
          }
        });

        // set quantity & amount
        qty.value = 1;
        amt.value = 0;
      } else {
        // if null (very unlikely)
        // clear entry
        claimInputClear();
      }
    };

    // watch updates
    watchEffect(() => {
      if (diagnosisList.value.length) {
        context.emit("update", {
          claimsDisplayCard: claimsDisplayCard.value,
          claimsSubmitCard: claimsSubmitCard.value,
          claimsList: claimsList.value,
          diagnosisList: diagnosisList.value,
          submitPulse: submitPulse.value,
        });
      }
    });

    // handle delete/edit requests
    const reviewClaim = (val) => {
      if (val) {
        if (val.action == "edit") {
          handleEdit(val.claim);
        } else if (val.action == "delete") {
          handleDelete(val.claim);
        }
      }
    };

    // watch for changes
    watch(props, (newVal) => {
      if (newVal.claimItem) {
        reviewClaim(newVal.claimItem);
        context.emit("clear", {
          id: "claim",
          value: null,
        });
      }
    });

    // watch more changes
    watch(props, (newVal) => {
      if (newVal.editDiagnosis) {
        handleReviewDiagnosis();
      }
    });

    // watch even more changes
    watch(props, (newVal) => {
      if (newVal.next) {
        handleNext(newVal.next);
        context.emit("clear", {
          id: "next",
          value: null,
        });
      }
    });

    return {
      handlePricing,
      handleClearInput,
      handleDiagnosis,
      handleDelete,
      handleEdit,
      handleClaims,
      handleReviewDiagnosis,
      handleNext,
      handleSearch,
      handleClear,
      handlePick,
      handleRefresh,
      totalSent,
      selectedItem,
      selectedPrice,
      resetDiagnosis,
      editClaim,
      editId,
      resetClaim,
      filterData,
      error,
      addPulse,
      diagnosis,
      diagnosisList,
      diagnosisCard,
      claimsDisplayCard,
      claimsList,
      claimsCard,
      claim,
      qty,
      amt,
    };
  },
};
</script>
    
  <style>
</style>
    
    