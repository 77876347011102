<template>
  <!-- Loading -->
  <div class="progress" v-if="isLoading">
    <div class="indeterminate"></div>
  </div>

  <!-- Display claims -->
  <template v-else>
    <div class="card yellow lighten-5">
      <div class="card-content white">
        <table class="highlight responsive-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name<br /><small>(Enrollee ID)</small></th>
              <th>Diagnosis</th>
              <th>Sent</th>
              <th>Vetted</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Claims Summary -->
            <template
              v-for="(encounter, index) in encounters"
              :key="encounter.id"
            >
              <tr class="clickable" @click.prevent="handleShow(encounter)">
                <template v-if="encounter.status == 'd'">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <s>{{ encounter.name }}</s
                    ><br />
                    <s>({{ encounter.enrollee_number }})</s>
                  </td>
                  <td>
                    <s>{{ encounter.diagnosis }}</s>
                  </td>
                  <td>
                    <s>
                      ₦{{
                        Number(encounter.sent)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                    </s>
                  </td>
                  <td>
                    <s>₦{{ totalApproved(encounter.claims) }}</s>
                  </td>
                  <td>
                    <i
                      class="material-icons grey-text text-lighten-2 pointable"
                      title="removed"
                      >do_not_disturb
                    </i>
                  </td>
                </template>

                <template v-else>
                  <td>{{ index + 1 }}</td>
                  <td>
                    <i
                      class="material-icons red-text pointable"
                      title="compliance issue"
                      v-if="encounter.compliance_flag == 'y'"
                      >flag
                    </i>
                    {{ encounter.name }}<br />
                    ({{ encounter.enrollee_number }})
                  </td>
                  <td>{{ encounter.diagnosis }}</td>
                  <td>₦{{ totalSent(encounter.claims) }}</td>
                  <td>₦{{ totalApproved(encounter.claims) }}</td>
                  <td @click.prevent="handleFlag(encounter)">
                    <i
                      class="material-icons clickable"
                      :class="{
                        'grey-text text-lighten-2': encounter.hod_flag == 'n',
                        'red-text': encounter.hod_flag == 'y',
                      }"
                      :title="getTitle(encounter)"
                      >flag
                    </i>
                  </td>
                </template>
              </tr>

              <!-- Claims details -->
              <template v-if="currEncounter == encounter">
                <tr v-for="claim in encounter.claims" :key="claim.id">
                  <td><br /></td>
                  <td><br /></td>
                  <td>
                    <span v-if="claim.status == 'd' || encounter.status == 'd'"
                      ><s>{{ claim.item }}</s></span
                    >
                    <span v-else>{{ claim.item }}</span>
                  </td>
                  <td class="orange-text text-darken-4">
                    <span v-if="claim.status == 'd' || encounter.status == 'd'"
                      ><s
                        >₦{{
                          Number(claim.sent_price)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                        <span
                          ><small
                            ><b>({{ claim.sent_qty }})</b></small
                          ></span
                        ></s
                      ></span
                    >
                    <span v-else
                      >₦{{
                        Number(claim.sent_price)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                      <span
                        ><small
                          ><b>({{ claim.sent_qty }})</b></small
                        ></span
                      ></span
                    >
                  </td>
                  <td class="green-text text-darken-4">
                    <span v-if="claim.status == 'd' || encounter.status == 'd'"
                      ><s
                        >₦{{
                          Number(claim.approved_price)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }}
                        <span
                          ><small
                            ><b>({{ claim.approved_qty }})</b></small
                          ></span
                        ></s
                      ></span
                    >
                    <span v-else
                      >₦{{
                        Number(claim.approved_price)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}
                      <span
                        ><small
                          ><b>({{ claim.approved_qty }})</b></small
                        ></span
                      ></span
                    >
                  </td>
                  <td>
                    <br />
                  </td>
                </tr>
              </template>
            </template>

            <!-- Final total -->
            <tr>
              <td colspan="2"><br /></td>
              <td>Total</td>
              <td>
                <b>₦{{ grossTotalSent(encounters) }}</b>
              </td>
              <td class="green-text text-darken-2">
                <h5>₦{{ grossTotalApproved(encounters) }}</h5>
              </td>
              <td colspan="1"><br /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Submit -->
    <div class="card-content">
      <br />
      <button
        class="waves-effect waves-light btn-large black right"
        :class="{
          disabled: isTalking || isSubmitting || !encounters.length,
        }"
        @click="handleSubmit('return')"
        v-if="encounters.filter((x) => x.hod_flag == 'y').length"
      >
        <i class="medium material-icons red-text right">chevron_left</i>
        {{ isSubmitting ? "Returning Claims" : "Request (Flagged) Re-vet" }}
      </button>
      <button
        class="waves-effect waves-light btn-large teal right"
        :class="{
          disabled: isTalking || isSubmitting || !encounters.length,
        }"
        @click="handleSubmit('approve')"
        v-else
      >
        <span v-if="cluster.scheme.who_pays === 'hmo'">
          <i class="medium material-icons right">check_circle</i
          >{{ isSubmitting ? "Processing Review" : "Pass To Compliance" }}
        </span>
        <span v-else>
          <i class="medium material-icons right">check_circle</i
          >{{ isSubmitting ? "Processing Review" : "Complete Vetting" }}
        </span>
      </button>
    </div>
  </template>
</template>
    
<script>
import { ref, onMounted, computed } from "vue";
import getItemsOfItems from "@/composables/getItemsOfItems";
import putItem from "@/composables/putItem";
import {
  grossTotalSentFX,
  grossTotalApprovedFX,
  totalSentFX,
  totalApprovedFX,
} from "@/composables/fxLibrary/claimsFx.js";

export default {
  name: "SingleClaimComponent",
  props: ["cluster"],
  emits: ["reset"],
  setup(props, context) {
    const response = ref(null);
    const currEncounter = ref(false);
    const error = ref(null);
    const isTalking = ref(0);
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(true);
    const currUrl = ref(null);
    const encounterId = ref(0);

    // get title
    const getTitle = (val) => {
      if (val.hod_flag == "n") {
        return "Flag claim";
      } else {
        return "Un-flag";
      }
    };

    // total calculations (gross sent)
    const grossTotalSent = (encounters) => {
      const { fx: grossTotalSent } = grossTotalSentFX(encounters);
      return grossTotalSent.value;
    };

    // total calculations (gross approved)
    const grossTotalApproved = (encounters, db) => {
      const { fx: grossTotalApproved } = grossTotalApprovedFX(encounters, db);
      return grossTotalApproved.value;
    };

    // total calculations (sent)
    const totalSent = (claims) => {
      const { fx: totalSent } = totalSentFX(claims);
      return totalSent.value;
    };

    // total calculations (approved)
    const totalApproved = (claims) => {
      const { fx: totalApproved } = totalApprovedFX(claims);
      return totalApproved.value;
    };

    // get function
    const getItemsFromApi = (table, id) => {
      (async () => {
        const { res, err } = await getItemsOfItems(table, id);

        if (res.value) {
          response.value = res.value.data;
        } else {
          error.value = err.value;
        }

        // page is ready, loading is complete
        isReady.value = true;
        isLoading.value = false;
      })();
    };

    // starter
    onMounted(() => {
      // set current Url
      currUrl.value = "encounter/cluster";
      // get items from function
      getItemsFromApi(currUrl.value, props.cluster.id);
    });

    // show claims
    const handleShow = (val) => {
      if (currEncounter.value == val) {
        currEncounter.value = null;
      } else {
        currEncounter.value = val;
      }
    };

    // flag
    const handleFlag = async (val) => {
      // talk care of multiple clicks
      isTalking.value++;

      // set new flag
      let newFlag = "y";
      let newComment = "HOD flagged this Encounter for re-vetting.";
      let newStatus = "p";
      if (val.hod_flag == newFlag) {
        newFlag = "n";
        newComment = null;
        newStatus = "v";
      }

      // update server
      let newVal = {
        hod_flag: newFlag,
        hod_comment: newComment,
        status: newStatus,
      };

      // update first (twitter style)
      response.value.forEach((x, y) => {
        if (x.id == val.id) {
          return (response.value[y] = {
            ...x,
            hod_flag: newFlag,
            hod_comment: newComment,
            status: newStatus,
          });
        }
      });

      // put update
      const { res, err } = await putItem("encounter", val.id, newVal);

      if (res.value) {
        // send reset value to parent
        context.emit("reset", {
          clusterId: props.cluster.id,
          encounterId: val.id,
          newFlag: newFlag,
          newComment: newComment,
          newStatus: newStatus,
        });
        // clear show claims
        currEncounter.value = null;
        // talk care of multiple clicks
        isTalking.value--;
      } else {
        error.value = err.value;
      }
    };

    // review
    const handleSubmit = async (val) => {
      // start
      isSubmitting.value = true;

      // set update
      let newVal = null;

      if (val == "approve") {
        // add paymentId on server
        newVal = {
          status: "v", // leave as usual (for safeguard)
        };
      } else if (val == "return") {
        newVal = {
          status: "p", // change to "p (pending)"
        };
      }

      // put update
      const { res, err } = await putItem(
        "cluster/hod/review",
        props.cluster.id,
        newVal
      );

      if (res.value) {
        // send reset value to parent
        context.emit("reset", "reload");
      } else {
        error.value = err.value;
      }

      // end submit
      isSubmitting.value = false;
    };

    // output results
    const encounters = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value;
      }
    });

    return {
      handleShow,
      handleFlag,
      handleSubmit,
      grossTotalSent,
      grossTotalApproved,
      totalApproved,
      totalSent,
      getTitle,
      isReady,
      isTalking,
      isLoading,
      isSubmitting,
      currEncounter,
      error,
      encounterId,
      encounters,
    };
  },
};
</script>
    
    <style scoped>
.custom-hr {
  width: 50%; /* Adjust the width as needed */
  border: none;
  border-top: 2px solid #ccc; /* Adjust the color and style as needed */
  margin: 20px 20px; /* Adjust margin as needed */
}

.progress {
  margin-top: -15px; /* Adjust the value as needed to move the progress bar upwards */
}

.styled-text {
  background-color: #fafad2; /* Light Golden Yellow */
  /* border: 2px solid #000; Black border */
  padding: 2px; /* Adequate padding */
}

.capsule {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #f0f0f0; /* Adjust the background color as needed */
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: #000000 !important;
}

.pointable {
  cursor: default;
  opacity: 1; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth opacity change */
}

.pointable:hover {
  opacity: 0.8; /* Slightly reduced opacity on hover */
}

.info-item:hover .material-icons.grey-text,
.info-item:hover .material-icons.grey-text::before {
  color: rgb(0, 0, 0) !important;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

.reset-item:hover .material-icons.grey-text,
.reset-item:hover .material-icons.grey-text::before {
  color: rgb(23, 124, 40) !important;
}

.cancel-item:hover .material-icons.grey-text,
.cancel-item:hover .material-icons.grey-text::before {
  color: rgb(214, 16, 16) !important;
}
</style>