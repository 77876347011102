<template>
  <!-- Use general search from parent component -->
  <!-- Active Principals -->
  <!-- On-Hold Principals -->
  <div
    class="card"
    :class="{ 'grey lighten-5': action, white: !action }"
    v-for="enrollee in enrollees"
    :key="enrollee.id"
  >
    <!-- Principal -->
    <div
      class="card-content"
      :class="{
        white: displayType == 'a',
        'yellow lighten-5': displayType == 'h',
        'orange lighten-5': currId == enrollee.id && action == 'profile',
        'blue lighten-5':
          currId == enrollee.id &&
          (action == 'spouse' || action == 'dependant'),
      }"
    >
      <div class="row">
    
        <div class="col s2">
          <img
            :src="enrollee.profile_img"
            width="96"
            class="circle responsive-img left"
            v-if="enrollee.profile_img"
          />
          <img
            :src="`https://ui-avatars.com/api/?name=${enrollee.name}&color=FFFFFF&size=128`"
            width="96"
            class="circle responsive-img left"
            v-else
          />
        </div>

        <div class="col s9">
          <h6>{{ enrollee.enrollee_number }}</h6>
          <h5>
            {{ enrollee.name }}
            <sup
              ><small
                ><span class="black-text">{{
                  showAgeSex(enrollee.dob, enrollee.sex)
                }}</span></small
              ></sup
            >
          </h5>

          <!-- Show Dependants (Family only) -->
          <!-- && !(currId == enrollee.id) -->
          <template v-if="enrollee.type == 'f'">
            <span @click="handleShow(enrollee.id)">
              <small>
                <span
                  class="chip-more orange-text"
                  v-if="
                    show == enrollee.id &&
                    (enrollee.dependants.length || enrollee.spouse)
                  "
                  ><i class="tiny material-icons">close</i> Close</span
                >
                <span
                  :class="{
                    'chip-more blue-text':
                      enrollee.dependants.length || enrollee.spouse,
                  }"
                  v-else
                  ><span v-if="enrollee.spouse">Spouse, </span
                  ><b>{{ enrollee.dependants.length }}</b> Dependant(s)</span
                >
              </small>
            </span>
          </template>
        </div>

        <div class="col s1">
          <span class="right">
            <a
              href="#"
              class="secondary-content teal-text text-darken-1 dropdown-trigger"
              :data-target="`dropmenu_${enrollee.id}`"
              ><i class="small material-icons" title="menu">more_vert</i></a
            >

            <!-- DropMenu -->
            <ul :id="`dropmenu_${enrollee.id}`" class="dropdown-content">
              <span v-if="!menuMore">
                <li
                  v-if="currId == enrollee.id"
                  @click.prevent="
                    handleMenu({ type: 'close', value: enrollee })
                  "
                >
                  <a href="#">Close Details</a>
                </li>
                <li
                  v-else
                  @click.prevent="handleMenu({ type: 'open', value: enrollee })"
                >
                  <a href="#">View Details</a>
                </li>
                <li class="divider" tabindex="-1"></li>
                <li
                  v-if="enrollee.type == 'f' && authLevel >= 1"
                  @click.prevent="
                    handleMenu({ type: 'addSpouse', value: enrollee })
                  "
                >
                  <a href="#">Add Spouse</a>
                </li>
                <li class="divider" tabindex="-1"></li>
                <li
                  v-if="enrollee.type == 'f' && authLevel >= 1"
                  @click.prevent="
                    handleMenu({ type: 'addDependant', value: enrollee })
                  "
                >
                  <a href="#">Add Dependant</a>
                </li>
                <li class="divider" tabindex="-1"></li>
                <li
                  @click.prevent="
                    handleMenu({ type: 'profile', value: enrollee })
                  "
                  v-if="authLevel >= 5"
                >
                  <a href="#">Update Record</a>
                </li>
              </span>

              <span v-else>
                <li
                  @click.prevent="
                    handleMenu({ type: 'provider', value: enrollee })
                  "
                  v-if="authLevel >= 5"
                >
                  <a href="#">Review Provider</a>
                </li>
                <li class="divider" tabindex="-1"></li>
                <li
                  @click.prevent="handleMenu({ type: 'plan', value: enrollee })"
                  v-if="authLevel >= 5"
                >
                  <a href="#">Review Plan</a>
                </li>
                <li class="divider" tabindex="-1"></li>
                <li
                  @click.prevent="
                    handleMenu({ type: 'status', value: enrollee })
                  "
                  v-if="enrollee.status == 'd' && authLevel >= 5"
                >
                  <a href="#">Restore Principal</a>
                </li>
                <li
                  @click.prevent="
                    handleMenu({ type: 'status', value: enrollee })
                  "
                  v-if="enrollee.status != 'd' && authLevel >= 5"
                >
                  <a href="#">De-activate</a>
                </li>
              </span>

              <li class="divider" tabindex="-1"></li>

              <li
                @click.prevent="handleMenuMore()"
                v-if="!menuMore && authLevel >= 5"
              >
                <span class="teal-text text-darken-2">More &#8250;&#8250;</span>
              </li>
              <li
                @click.prevent="handleMenuMore()"
                v-if="menuMore && authLevel >= 5"
              >
                <span class="teal-text text-darken-2">&#8249;&#8249; Back</span>
              </li>
            </ul>
          </span>
        </div>
      </div>

      <!-- Scheme Details -->
      <template v-if="showAll == enrollee.id">
        <div class="card-content spacer10">
          <div class="row amber lighten-5">
            <div class="col s4 spacer10">
              <label class="active teal-text">(Plan Type)</label>
              <h6><b>LifeGreen</b></h6>
            </div>
            <div class="col s4 spacer10">
              <label class="active teal-text">(Category)</label>
              <h6 v-if="enrollee.type == 'f'"><b>Family</b></h6>
              <h6 v-else><b>Individual</b></h6>
            </div>
            <div class="col s4 spacer10">
              <label class="active teal-text">(Dependants)</label>
              <h6 v-if="enrollee.type == 'f'">
                <b>{{ enrollee.dependants.length }}</b>
              </h6>
              <h6 v-else><b>N/A</b></h6>
            </div>
            <div class="col s4 spacer10">
              <label class="active teal-text">(Principal Email)</label>
              <h6>
                {{ enrollee.user?.email }}
              </h6>
            </div>
            <div class="col s4 spacer10">
              <label class="active teal-text">(Principal Phone)</label>
              <h6>
                {{ enrollee.phone }}
              </h6>
            </div>
            <div class="col s4 spacer10">
              <label class="active teal-text">(Principal Address)</label>
              <h6>{{ enrollee.address }}</h6>
            </div>
            <div class="col s12 spacer10">
              <label class="active teal-text">(Principal Registered)</label>
              <h6>{{ enrollee.created_at }}</h6>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- Edit Profile -->
    <EnrolleeEditDiv
      :enrollee="enrollee"
      @reset="handleReset"
      v-if="action == 'profile' && currId == enrollee.id"
    />

    <!-- Review Provider -->
    <EnrolleeProviderDiv
      :user="enrollee"
      @reset="handleReset"
      v-if="action == 'provider' && currId == enrollee.id"
    />

    <!-- Review Plan -->
    <EnrolleePlanDiv
      :enrollee="enrollee"
      @reset="handleReset"
      v-if="action == 'plan' && currId == enrollee.id"
    />

    <!-- Review Status -->
    <EnrolleeStatusDiv
      :cat="cat"
      :enrollee="enrollee"
      @reset="handleReset"
      v-if="action == 'status' && currId == enrollee.id"
    />

    <!-- Add Spouse -->
    <AddSpouseDiv
      :enrollee="enrollee"
      @reset="handleReset"
      v-if="action == 'spouse' && currId == enrollee.id"
    />

    <!-- Add Dependant -->
    <AddDependantDiv
      :enrollee="enrollee"
      @reset="handleReset"
      v-if="action == 'dependant' && currId == enrollee.id"
    />

    <!--
    View Spouse
    -->
    <SpouseDiv
      :authLevel="authLevel"
      :enrollee="enrollee"
      @reset="handleReset"
      @close="handleCloseDependants"
      :close="closeSpouse"
      v-if="show == enrollee.id || showAll == enrollee.id"
    />
    <!---->

    <!--
    View Dependant
    -->
    <DependantDiv
      :authLevel="authLevel"
      :enrollee="enrollee"
      @reset="handleReset"
      @close="handleCloseSpouse"
      :close="closeDependants"
      v-if="show == enrollee.id || showAll == enrollee.id"
    />
    <!---->
  </div>
</template>
  
    
<script>
import { ref } from "vue";
import { ageSex, changeToMoney } from "@/composables/fxLibrary/coreFx";
import EnrolleeStatusDiv from "@/components/client/onboard/EnrolleeStatusEditOnboardComponent.vue";
import EnrolleeProviderDiv from "@/components/client/onboard/EnrolleeProviderOnboardComponent.vue";
import EnrolleePlanDiv from "@/components/client/onboard/EnrolleePlanEditOnboardComponent.vue";
import EnrolleeEditDiv from "@/components/client/onboard/EnrolleeEditOnboardComponent.vue";
import AddSpouseDiv from "@/components/client/onboard/EnrolleeAddSpouseOnboardComponent.vue";
import AddDependantDiv from "@/components/client/onboard/EnrolleeAddDependantOnboardComponent.vue";
import SpouseDiv from "@/components/client/extras/PrincipalSpouseClientComponent.vue";
import DependantDiv from "@/components/client/extras/PrincipalDependantClientComponent.vue";

export default {
  name: "PrincipalActiveHoldClientComponent",
  components: {
    EnrolleeStatusDiv,
    EnrolleeProviderDiv,
    EnrolleePlanDiv,
    EnrolleeEditDiv,
    AddSpouseDiv,
    AddDependantDiv,
    SpouseDiv,
    DependantDiv,
  },
  props: ["cat", "authLevel", "enrollees", "displayType"],
  emits: ["reset"],
  setup(props, context) {
    const isReady = ref(false);
    const isSubmitting = ref(false);
    const isLoading = ref(false);
    const error = ref(null);
    const show = ref(null); // spouse && dependants only
    const showAll = ref(null); // detail spouse && dependants
    const menuMore = ref(false);
    const action = ref(null);
    const currId = ref(null);
    const closeSpouse = ref(false);
    const closeDependants = ref(false);

    // ageSex
    const showAgeSex = (dob, sex) => {
      const { fx } = ageSex(dob, sex);
      return fx.value;
    };

    // to naira
    const changeToNaira = (premiums, type) => {
      // sort value
      let val;
      premiums.forEach((x) => {
        if (x.type == type && !val) {
          val = x.amount;
        }
      });
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // to naira
    const changeToNairaTotal = (val) => {
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // shore more in Menu
    const handleMenuMore = () => {
      menuMore.value = !menuMore.value;
    };

    // clear all
    const clearAll = () => {
      // clear
      show.value = null;
      showAll.value = null;
      action.value = null;
      currId.value = null;
    };

    // handle show
    const handleShow = (val) => {
      // same or not
      let checker = false;
      if (val == show.value) {
        checker = true;
      }

      // clear first
      clearAll();

      // reset
      if (!checker) {
        show.value = val;
      }
    };

    // handle menu
    const handleMenu = (val) => {
      if (val.type == "close") {
        // clear
        clearAll();
      }

      if (val.type == "open") {
        // clear
        clearAll();
        // show && showAll
        showAll.value = val.value.id;
        show.value = val.value.id;
        currId.value = val.value.id;
      }

      if (val.type == "addSpouse") {
        // clear
        clearAll();
        // reset
        action.value = "spouse";
        currId.value = val.value.id;
        show.value = val.value.id;
      }

      if (val.type == "addDependant") {
        // clear
        clearAll();
        // reset
        action.value = "dependant";
        currId.value = val.value.id;
        show.value = val.value.id;
      }

      if (val.type == "profile") {
        // clear
        clearAll();
        // reset
        action.value = "profile";
        currId.value = val.value.id;
      }

      if (val.type == "provider") {
        // clear
        clearAll();
        // reset
        action.value = "provider";
        currId.value = val.value.id;
      }

      if (val.type == "plan") {
        // clear
        clearAll();
        // reset
        action.value = "plan";
        currId.value = val.value.id;
      }

      if (val.type == "status") {
        // clear
        clearAll();
        // reset
        action.value = "status";
        currId.value = val.value.id;
      }
    };

    // handle spouse
    const handleCloseSpouse = (val) => {
      // close
      if (val) {
        closeSpouse.value = true;
      }
      // null caller
      closeDependants.value = false;
    };

    // handle dependants
    const handleCloseDependants = (val) => {
      // close
      if (val) {
        closeDependants.value = true;
      }
      // null caller
      closeSpouse.value = false;
    };

    // handle
    const handleReset = (val) => {
      // send back
      context.emit("reset", val);
      // clear first
      clearAll();
    };

    return {
      handleReset,
      handleCloseSpouse,
      handleCloseDependants,
      handleMenuMore,
      handleMenu,
      handleShow,
      changeToNaira,
      changeToNairaTotal,
      closeSpouse,
      closeDependants,
      showAgeSex,
      isReady,
      isSubmitting,
      isLoading,
      error,
      menuMore,
      show,
      showAll,
      action,
      currId,
    };
  },
};
</script>
    
  
<style scoped>
.progress {
  margin-top: 0px; /* Adjust the value as needed to move the progress bar upwards */
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.chip-more:hover {
  font-size: 150%;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>