import { ref } from 'vue';

// convert to Month & Year
const toMonthYear = (val) => {
  const fx = ref(null);
  // function
  let parts = val.split("_");
  let month = parseInt(parts[0]);
  let year = parseInt(parts[1]);
  let date = new Date(year, month - 1); // months are zero-indexed in JavaScript
  let monthName = date.toLocaleString("default", { month: "short" });
  fx.value = monthName + " " + year;

  return { fx };
};

// capitalize first letters
const capitalizeFirstLetter = (str) => {
  const fx = ref(null);
  // function
  fx.value = str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });

  return { fx };
};


// format dob and sex
const ageSex = (dob, sex) => {
  const fx = ref(null);
  // function
  let birthDateObj = new Date(dob);
  let today = new Date();
  let age = today.getFullYear() - birthDateObj.getFullYear();

  if (
    today.getMonth() < birthDateObj.getMonth() ||
    (today.getMonth() === birthDateObj.getMonth() &&
      today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  if (dob && sex) {
    fx.value = `(${sex.toUpperCase()}/${age}yrs)`;
  } else if (dob) {
    fx.value = `(${age}yrs)`;
  } else if (sex) {
    fx.value = `(${sex.toUpperCase()})`;
  }

  return { fx };
};

// to naira
const changeToMoney = (val) => {
  const fx = ref(null);
  // function
  if (!isNaN(Number(val))) {
    fx.value = Number(val)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    fx.value = "0.00";
  }

  return { fx };
};

// sort premium
const billable = (val) => {
  const fx = ref(null);
  // function
  let sorted;
  let latestPremium = 0;
  let timer = new Date(0);
  let scope = -1;
  sorted = val.forEach((x) => {
    x.plan.premiums.forEach((z) => {
      if (z.type == x.type) { // check plan type match
        if (z.scope > scope) { // check higher scope // 0 is general default // higher scope wins (clied id == scope)
          latestPremium = z.amount;
          timer = new Date(z.created_at)
          scope = z.scope
        } else if ((z.scope == scope) && (new Date(z.created_at) > timer)) { // change if only newer created_at for same scope
          latestPremium = z.amount;
          timer = new Date(z.created_at)
          scope = z.scope
        } else {
          // do nothing here
        }
      }
    });
    x.billable = latestPremium;
    latestPremium = 0;
    timer = new Date(0);
    scope = -1;
  });

  // set final result
  fx.value = sorted;

  return { fx };
};

// cleanup arrays
const removeDuplicates = (arr, key, versionKey) => {
  const fx = ref(null);
  // function
  const map = new Map();
  arr.forEach(item => {
    const val = item[key].toLowerCase();
    if (!map.has(val) || new Date(item[versionKey]) > new Date(map.get(val)[versionKey])) {
      map.set(val, item);
    }
  });

  fx.value = Array.from(map.values());

  return { fx };
};

function removeDuplicateFromLastPage(arrayA, arrayB, key) {
  const fx = ref(null);
  // function
  // Create a set from arrayB for efficient lookups
  const uniqueByKey = new Set(arrayB.map(item => item[key]));
  // Filter arrayA, keeping only elements where the key value doesn't exist in the set
  fx.value = arrayA.filter(item => !uniqueByKey.has(item[key]));

  return { fx };
}

function getDifferenceInDays(dateOne, dateTwo) {
  const fx = ref(null);
  // function
  // Parse the given date string
  const firstDate = new Date(dateOne);

  // Get the current date
  const secondDate = new Date(dateTwo);

  // Calculate the difference in time (in milliseconds)
  const timeDifference = secondDate - firstDate;

  // Convert time difference from milliseconds to days
  fx.value = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return { fx };
}


function removeKeys(val, keysToRemove) {
  const fx = ref(null);
  // function
  fx.value = {
    ...Object.fromEntries(
      Object.entries(val).filter(
        ([key]) => !keysToRemove.includes(key)
      )
    )
  }

  return { fx };
}


export {
  toMonthYear,
  capitalizeFirstLetter,
  ageSex,
  changeToMoney,
  billable,
  removeDuplicates,
  removeDuplicateFromLastPage,
  getDifferenceInDays,
  removeKeys,
}