<template>
  <div class="row">
    <div class="col s12">
      <LoadingDiv v-if="!isReady" />

      <div class="section" v-else>
        <!-- Bar -->
        <span v-if="showSearch">
          <FilterInvoice @search="handleSearch" />
        </span>

        <!-- Invoices -->
        <div class="card teal lighten-5">
          <!-- Start -->
          <div v-for="(invoice, index) in invoices" :key="invoice.id">
            <!-- Invoices -->
            <div
              class="card-panel"
              :class="{
                'amber lighten-5': invoice.status == 'p',
                'teal lighten-5': invoice.status == 'c',
                'purple lighten-5': invoice.status == 'z',
                'grey lighten-3': invoice.status == 'd',
              }"
              v-if="currInvoice == invoice || !currInvoice"
            >
              <div class="row">
                <div class="col s1">
                  <h5>{{ index + 1 }}.</h5>
                </div>
                <div class="col s10">
                  <p>
                    <span
                      v-if="
                        invoice.status == 'p' && invoice.start < currServerDate
                      "
                      >Delayed Invoice<i class="tiny material-icons"
                        >schedule</i
                      ></span
                    >
                    <span
                      v-if="
                        invoice.status == 'p' && invoice.start > currServerDate
                      "
                      >Pending Invoice</span
                    >
                    <span
                      class="orange-text text-darken-4"
                      v-if="invoice.status == 'z'"
                      >Unpaid (Grace Period)</span
                    >
                    <span v-if="invoice.status == 'c'"
                      >Completed Payment<i class="tiny material-icons teal-text"
                        >check_circle</i
                      ></span
                    >
                    <span class="red-text" v-if="invoice.status == 'd'"
                      >Deleted Invoice</span
                    >
                  </p>
                  <h6>
                    {{ invoice.client.name }}
                    <span class="chip orange">{{
                      invoice.client?.cat.toUpperCase()
                    }}</span>
                  </h6>
                  <h5>
                    ₦{{
                      changeToNaira(invoice.input_total, invoice.output_total)
                    }}
                    <span class=""
                      ><small>
                        {{
                          new Date(invoice.start).toLocaleString(undefined, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })
                        }}
                      </small>

                      -

                      <small>
                        {{
                          new Date(invoice.end).toLocaleString(undefined, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })
                        }}
                      </small>
                    </span>
                  </h5>
                </div>
                <div class="col s1">
                  <span class="right" v-if="!currInvoice">
                    <a
                      class="btn-floating btn-medium waves-effect waves-light right"
                      :class="{
                        red: invoice.start < currServerDate && (invoice.status == 'p' || invoice.status == 'z'),
                        'light-blue lighten-2': !(
                          currServerDate && (invoice.status == 'p' || invoice.status == 'z')
                        ),
                      }"
                      ><i
                        class="material-icons"
                        @click="handleView(invoice)"
                        title="view details"
                        >chevron_right</i
                      ></a
                    >
                  </span>
                  <span class="right" v-else>
                    <a
                      class="btn-floating btn-medium waves-effect waves-light light-blue right"
                      ><i
                        class="material-icons"
                        @click="handleClear()"
                        title="go back"
                        >keyboard_return</i
                      ></a
                    >
                  </span>
                </div>
              </div>
            </div>

            <div
              class="card-content white"
              v-if="
                currInvoice == invoice &&
                (invoice.status == 'p' || invoice.status == 'z')
              "
            >
              <button
                class="waves-effect waves-light btn-large black"
                :class="{ disabled: isSubmitting }"
                v-if="invoice.status == 'p'"
                @click.prevent="handleSubmit('later')"
              >
                <i class="medium material-icons yellow-text left">schedule</i>
                Pay Later
              </button>
              <button
                class="waves-effect waves-light btn-large teal"
                :class="{ disabled: isSubmitting }"
                @click.prevent="handleSubmit('paid')"
              >
                <i class="medium material-icons right">check_circle</i>Approve
                As Paid
              </button>
            </div>
          </div>
        </div>

        <!-- Invoice details -->
        <InvoiceDiv :currInvoice="currInvoice" v-if="currInvoice" />

        <!-- Pages -->
        <template v-else>
          <div class="section center" v-if="!invoices.length">
            <h6>No Invoices <em>found</em>...</h6>
          </div>

          <PaginationDiv :payLoad="allPages" @newPage="handlePage" v-else />
        </template>
      </div>
    </div>
  </div>
</template>
          
<script>
import { computed, onMounted, ref } from "vue";
import { changeToMoney } from "@/composables/fxLibrary/coreFx";
import getItems from "@/composables/getItems";
import postItem from "@/composables/postItem";
import FilterInvoice from "@/components/invoice/FilterInvoiceDiv.vue";
import InvoiceDiv from "@/components/invoice/InvoiceDiv.vue";
import PaginationDiv from "@/components/pager/PaginationDiv.vue";
import LoadingDiv from "@/components/LoadingDiv.vue";

export default {
  name: "InvoicesFinanceComponent",
  components: { LoadingDiv, PaginationDiv, InvoiceDiv, FilterInvoice },
  setup() {
    const response = ref(null);
    const isReady = ref(false);
    const isLoading = ref(false);
    const isSubmitting = ref(false);
    const error = ref(null);
    const currInvoice = ref(false);
    const currUrl = ref(null);
    const currHook = ref(null);
    const currServerDate = ref(null);
    const showSearch = ref(true);
    const searchItem = ref(null);
    const searchTitle = ref(null);

    // get function
    const getItemsFromApi = (table) => {
      (async () => {
        const { res, err } = await getItems(table);

        if (res.value) {
          response.value = res.value.data;
          currServerDate.value = res.value.server;
        } else {
          error.value = err.value;
        }

        // page is ready
        isReady.value = true;
        // end
        isLoading.value = false;
      })();
    };

    // to naira
    const changeToNaira = (i, o) => {
      // sort value
      let val = i - o;
      // conclude
      const { fx } = changeToMoney(val);
      return fx.value;
    };

    // starter
    onMounted(() => {
      // loader
      isReady.value = false;
      // start from the top
      getInvoices();
    });

    // invoices
    const getInvoices = () => {
      // set current Url
      currUrl.value = "group/invoices/group";
      // set current Hook
      currHook.value = "?";
      // get items from function
      getItemsFromApi(currUrl.value);
      // clear
      handleClear();
    };

    // handle search (emitted from component)
    const handleSearch = (val) => {
      // start loading
      isLoading.value = true;
      // set current Url
      currUrl.value = val.currUrl;
      // set current Hook
      currHook.value = val.currHook;
      // set search title
      searchTitle.value = val.searchTitle;
      // get items from function
      getItemsFromApi(currUrl.value);
      // clear
      handleClear();
    };

    // switch page
    const handlePage = (page) => {
      // loader
      isReady.value = false;
      // get new page items from function
      getItemsFromApi(`${currUrl.value}${currHook.value}page=${page}`);
      // clear
      handleClear();
    };

    // enrollee ction
    const handleClear = () => {
      currInvoice.value = null;
      showSearch.value = true;
    };

    // enrollee ction
    const handleView = (val) => {
      currInvoice.value = val;
      showSearch.value = false;
    };

    const handleSubmit = async (val) => {
      // start
      isSubmitting.value = true;

      let newStatus = null;

      if (val == "paid") {
        newStatus = "c";
      } else if (val == "later") {
        newStatus = "z";
      }

      if (newStatus) {
        let newVal = {
          group_id: currInvoice.value.id,
          new_status: newStatus,
        };

        // post
        const { res, err } = await postItem(
          "operator/finance/premium/payment/confirmation",
          newVal
        );

        if (res.value) {
          // start again
          getInvoices();
        } else {
          error.value = err.value;
        }

        // clear
        isSubmitting.value = false;
        // clear
        handleClear();
      }
    };

    // output results
    const invoices = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value.data;
      }
    });

    // output pages
    const allPages = computed(() => {
      if (!response.value) {
        return [];
      } else {
        return response.value; // get all
      }
    });

    return {
      searchTitle,
      handleSearch,
      handleSubmit,
      handlePage,
      handleClear,
      handleView,
      changeToNaira,
      currServerDate,
      currInvoice,
      showSearch,
      searchItem,
      isReady,
      isLoading,
      isSubmitting,
      error,
      invoices,
      allPages,
    };
  },
};
</script>
          
<style scoped>
.progress {
  margin-top: -15px;
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pointer {
  cursor: default;
}

.edit-item:hover .material-icons.grey-text,
.edit-item:hover .material-icons.grey-text::before {
  color: rgb(21, 21, 206) !important;
}

/* open */
.chip-more {
  cursor: pointer;
  background-color: #eaecee !important;
}

.chip-more:hover {
  color: white !important;
  background-color: black !important;
}

/* close */
.chip-less {
  cursor: pointer;
  color: white !important;
  background-color: black !important;
}

.chip-less:hover {
  color: black !important;
  background-color: #eaecee !important;
}

/* base class */
.tab-custom {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
}

.tab-custom:hover {
  color: #000000 !important;
}

.tab-custom-active {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 -1px;
  padding: 10px 15px;
  color: white;
  font-weight: 600;
  text-align: center;
  background-color: black;
}

/* display */
.tab-custom-section {
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}
</style>
  